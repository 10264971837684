import MetricsList from "../MetricsList";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import MetricAssignOverlay from "../../DashboardCriteria/MetricAssignOverlay";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import * as jose from "jose";
import roles from "../../../../roles";

const Metrics = (props) => {
  const [isOpened, updatedOpened] = useState(false);
  const [metrics, updateMetrics] = useState([]);
  const [metricsLoader, updateMetricsLoader] = useState(false);
  const [userdata, updateUserData] = useState({});
  const [criteriaOptions, updateCriteriaOptions] = useState([]);
  const [tasks, setTasks] = useState({
    totalTasks: 0,
    toDo: 0,
    inProgress: 0,
    done: 0,
    issues: 0,
    submitted: 0,
  });

  const { match } = props;
  const { params } = match;
  const { projectId, criteriaNo } = params;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const renderStatusCards = () => (
    <ul className={styles["metrics-status-card"]}>
      <li className="bg-[#F6F5FF]">
        <h2>NOT STARTED</h2>
        <h1>
          {tasks.toDo}{" "}
          <span>
            {tasks.toDo && Math.round((tasks.toDo / tasks.totalTasks) * 100)}%
          </span>
        </h1>
      </li>

      <li className="bg-[#F1F8FE]">
        <h2>IN DRAFT</h2>
        <h1>
          {tasks.inProgress}{" "}
          <span>
            {tasks.inProgress &&
              Math.round((tasks.inProgress / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#FFF7EE]">
        <h2>SUBMITTED</h2>
        <h1>
          {tasks.submitted}{" "}
          <span>
            {tasks.submitted &&
              Math.round((tasks.submitted / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#FBE3E3]">
        <h2>REJECTED</h2>
        <h1>
          {tasks.issues}{" "}
          <span>
            {tasks.issues &&
              Math.round((tasks.issues / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#E0FFD9]">
        <h2>APPROVED</h2>
        <h1>
          {tasks.done}{" "}
          <span>
            {tasks.done && Math.round((tasks.done / tasks.totalTasks) * 100)}%
          </span>
        </h1>
      </li>
    </ul>
  );

  const progressStyle = {
    width: `${60}%`,
  };

  const closeBtn = () => {
    updatedOpened(false);
  };

  const openBtn = () => {
    updatedOpened(true);
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const fetchMetrics = async () => {
    updateMetricsLoader(true);
    updateMetrics([]);

    const reqBody = {
      project_id: projectId,
      criteria_no: criteriaNo,
    };

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const fetchedResponse = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC,
        options
      );

      if (fetchedResponse.ok) {
        const data = await fetchedResponse.json();

        const taskCardData = {
          totalTasks: 0,
          toDo: 0,
          inProgress: 0,
          done: 0,
          issues: 0,
          submitted: 0,
        };

        const updatedData = data.map((each) => {
          taskCardData.totalTasks = taskCardData.totalTasks + 1;

          let percent = 0;

          switch (each["status"]) {
            case 1:
              taskCardData.toDo = taskCardData.toDo + 1;
              percent = 0;
              break;

            case 2:
              taskCardData.inProgress = taskCardData.inProgress + 1;
              percent = 25;
              break;

            case 3:
              taskCardData.submitted = taskCardData.submitted + 1;
              percent = 50;
              break;

            case 4:
              taskCardData.issues = taskCardData.issues + 1;
              percent = 75;
              break;

            case 5:
              taskCardData.done = taskCardData.done + 1;
              percent = 100;
              break;

            default:
              break;
          }

          return {
            metricsId: each.id,
            criteriaNo: each.criteria_no,
            metricNo: each.metric_no,
            metricStatement: each.metric_statement,
            startDate: each.start_date,
            endDate: each.end_date,
            authors: each.authors,
            approvers: each.approvers,
            status: each.status,
            progress: percent,
          };
        });

        const sortedData = updatedData.sort(
          (a, b) => a.metricsId - b.metricsId
        );
        updateMetrics(sortedData);
        setTasks(taskCardData);
      } else {
        updateMetrics([]);
      }
    } catch (err) {
      updateMetrics([]);
      console.log(err);
    }

    updateMetricsLoader(false);
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>No Metrics Found To View</p>
    </div>
  );

  const renderMetricView = () => {
    const content = metricsLoader
      ? "loading"
      : metrics.length === 0
      ? "empty"
      : "content";

    switch (content) {
      case "loading":
        return (
          <div className="absolute top-80 flex justify-center w-full">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case "content":
        return (
          <table className={styles["metrics-list"]}>
            <thead>
              <tr>
                <th className="rounded-tl-xl" colSpan={1}>
                  Metric
                </th>
                <th colSpan={2}>Description</th>
                <th colSpan={1} className={styles["metric-table-name"]}>
                  % Completed
                </th>
                <th colSpan={1} className={styles["metric-table-name"]}>
                  Author
                </th>
                <th colSpan={1} className={styles["metric-table-name"]}>
                  Approver
                </th>
                <th className={styles["metric-table-date"]} colSpan={1}>
                  Start Date
                </th>
                <th
                  className={`${styles["metric-table-date"]} rounded-t-xl`}
                  colSpan={1}
                >
                  End Date
                </th>
              </tr>
            </thead>
            <tbody>
              {metrics.map((each) => (
                <MetricsList
                  key={each.metricsId}
                  item={each}
                  projectId={projectId}
                />
              ))}
            </tbody>
          </table>
        );

      case "empty":
        return renderEmptyView();

      default:
        return null;
    }
  };

  const getCriteriaOptions = async () => {
    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_CRITERIA_ALL +
          `?projectId=${projectId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateCriteriaOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderMetricName = () => {
    const name = criteriaOptions.filter(
      (each) => `${each?.["criteria_no"]}` === `${metrics?.[0]?.["criteriaNo"]}`
    );

    return `${name?.[0]?.["criteria_no"] ?? ""}. ${
      name?.[0]?.["criteria_statement"] ?? ""
    }`;
  };

  useEffect(() => {
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateUserData(claim);
    }
    getCriteriaOptions();
    fetchMetrics();
  }, []);

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div onClick={preventBubbling} className={styles["overlay-card"]}>
          <MetricAssignOverlay
            closeBtn={closeBtn}
            projectId={projectId}
            fetchMetrics={fetchMetrics}
            criteriaOptions={criteriaOptions}
          />
        </div>
      </div>
      <div className={styles["dashboard-container"]}>
        <div className="flex justify-between items-center w-full pr-14">
          <div className="flex flex-col w-3/12">
            <h1 className={styles["metric-value"]}>{renderMetricName()}</h1>
            <div className={styles["progress-bar-container"]}>
              <div className={styles["progress-bar"]} style={progressStyle}>
                &nbsp;
              </div>
            </div>
          </div>

          {/* <div className={styles["profile-dp-container"]}>
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />

            <span className={styles["profile-count"]}>+ 15 people</span>
          </div> */}

          <div className="flex items-center ml-6">
            <div className={styles["search-input-container"]}>
              <img src="/assets/search.svg" alt="search" />
              <input
                placeholder="Search"
                type="search"
                className={styles["search-input"]}
              />
            </div>
            {(userdata?.user_type === roles.principal ||
              userdata?.user_type === roles.hod) && (
              <button
                type="button"
                onClick={openBtn}
                className={styles["dotted-plus"]}
              >
                +
              </button>
            )}
          </div>
        </div>

        {renderStatusCards()}

        {renderMetricView()}
      </div>
    </>
  );
};

export default withRouter(Metrics);

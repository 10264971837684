import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const SponsoredResearchConsultancyWork = (props) => {
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [rowsCount3, setRowsCount3] = useState(1);
  const [filtData, setFiltData] = useState({});

  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }
    if (each === 3) {
      setRowsCount3(rowsCount3 + 1);
    }
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }

    if (each === 3) {
      rowsCount3 > 0 && setRowsCount3(rowsCount3 - 1);
    }
  };

  const TotalAmount = (rows, year) => {
    try {
      let total1 = 0;
      for (let count = 1; count <= rows; count++) {
        const amount = parseInt(
          document.getElementById(`${year}-${count}-amount`)?.value || 0
        );
        total1 += amount;
      }
      return (
        <>
          <td>{total1}</td>
        </>
      );
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const TotalAmountLast = () => {
    try {
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      for (let count = 1; count <= rowsCount1; count++) {
        const amount = parseInt(
          document.getElementById(`CAYm1-${count}-amount`)?.value || 0
        );
        total1 += amount;
      }

      for (let count = 1; count <= rowsCount2; count++) {
        const amount = parseInt(
          document.getElementById(`CAYm2-${count}-amount`)?.value || 0
        );
        total2 += amount;
      }

      for (let count = 1; count <= rowsCount3; count++) {
        const amount = parseInt(
          document.getElementById(`CAYm3-${count}-amount`)?.value || 0
        );
        total3 += amount;
      }
      return (
        <>
          <td>{total1 + total2 + total3}</td>
        </>
      );
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        reqBody.push({
          sponsoredResearchConsultancyWorkId: document.getElementById(
            `CAYm1-${count}-pi-name`
          ).name,
          piName: document.getElementById(`CAYm1-${count}-pi-name`).value,
          coPiName: document.getElementById(`CAYm1-${count}-co-pi-name`).value,
          departmentName: document.getElementById(
            `CAYm1-${count}-department-name`
          ).value,
          projectTitle: document.getElementById(`CAYm1-${count}-project-title`)
            .value,
          fundingAgency: document.getElementById(
            `CAYm1-${count}-funding-agency`
          ).value,
          duration: document.getElementById(`CAYm1-${count}-duration`).value,
          amount: document.getElementById(`CAYm1-${count}-amount`).value,
          year: "CAYm1",
        });
      }

      for (let count = 1; count <= rowsCount2; count++) {
        reqBody.push({
          sponsoredResearchConsultancyWorkId: document.getElementById(
            `CAYm2-${count}-pi-name`
          ).name,
          piName: document.getElementById(`CAYm2-${count}-pi-name`).value,
          coPiName: document.getElementById(`CAYm2-${count}-co-pi-name`).value,
          departmentName: document.getElementById(
            `CAYm2-${count}-department-name`
          ).value,
          projectTitle: document.getElementById(`CAYm2-${count}-project-title`)
            .value,
          fundingAgency: document.getElementById(
            `CAYm2-${count}-funding-agency`
          ).value,
          duration: document.getElementById(`CAYm2-${count}-duration`).value,
          amount: document.getElementById(`CAYm2-${count}-amount`).value,
          year: "CAYm2",
        });
      }

      for (let count = 1; count <= rowsCount3; count++) {
        reqBody.push({
          sponsoredResearchConsultancyWorkId: document.getElementById(
            `CAYm3-${count}-pi-name`
          ).name,
          piName: document.getElementById(`CAYm3-${count}-pi-name`).value,
          coPiName: document.getElementById(`CAYm3-${count}-co-pi-name`).value,
          departmentName: document.getElementById(
            `CAYm3-${count}-department-name`
          ).value,
          projectTitle: document.getElementById(`CAYm3-${count}-project-title`)
            .value,
          fundingAgency: document.getElementById(
            `CAYm3-${count}-funding-agency`
          ).value,
          duration: document.getElementById(`CAYm3-${count}-duration`).value,
          amount: document.getElementById(`CAYm3-${count}-amount`).value,
          year: "CAYm3",
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, year) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>{count}</td>
          <td className={styles["input-col"]}>
            <input
              name={
                filtData?.[year]?.[count - 1]?.[
                  "sponsored_research_consultancy_work_id"
                ] ?? ""
              }
              defaultValue={filtData?.[year]?.[count - 1]?.["pi_name"] ?? ""}
              id={`${year}-${count}-pi-name`}
              required
            />
          </td>

          <td className={styles["input-col"]}>
            <input
              defaultValue={filtData?.[year]?.[count - 1]?.["co_pi_name"] ?? ""}
              id={`${year}-${count}-co-pi-name`}
            />
          </td>

          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-department-name`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["department_name"] ?? ""
              }
              required
            />
          </td>

          <td>
            <input
              id={`${year}-${count}-project-title`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["project_title"] ?? ""
              }
              required
            />
          </td>
          <td>
            <input
              id={`${year}-${count}-funding-agency`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["funding_agency"] ?? ""
              }
              required
            />
          </td>
          <td>
            <input
              id={`${year}-${count}-duration`}
              defaultValue={filtData?.[year]?.[count - 1]?.["duration"] ?? ""}
              required
              type="number"
              min={0}
            />
          </td>
          <td>
            <input
              id={`${year}-${count}-amount`}
              defaultValue={filtData?.[year]?.[count - 1]?.["amount"] ?? ""}
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  const formatData = () => {
    try {
      const updatedData = Object.groupBy(
        metricInfo?.[template],
        ({ year }) => year
      );

      const m1Count = updatedData?.["CAYm1"]?.length ?? 1;
      const m2Count = updatedData?.["CAYm2"]?.length ?? 1;
      const m3Count = updatedData?.["CAYm3"]?.length ?? 1;

      setFiltData(updatedData);
      setRowsCount1(m1Count);
      setRowsCount2(m2Count);
      setRowsCount3(m3Count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formatData();
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["sponsor-research-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>PI Name</th>
            <th>
              Co-PI names <br /> if any
            </th>
            <th>
              Name of the <br /> Dept., where <br />
              project is
              <br /> sanctioned
            </th>
            <th>
              Project <br /> title*
            </th>
            <th>
              Name of the <br /> Funding agency
            </th>
            <th>
              Duration of <br /> the project
            </th>
            <th>Amount (Lacs)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>CAYM1</td>
          </tr>
          {renderStructure(rowsCount1, "CAYm1")}
          <tr>
            <td colSpan={7}>Amount received (Rs.)</td>
            {TotalAmount(rowsCount1, "CAYm1")}
          </tr>
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["sponsor-research-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>PI Name</th>
            <th>
              Co-PI names <br /> if any
            </th>
            <th>
              Name of the <br /> Dept., where <br />
              project is
              <br /> sanctioned
            </th>
            <th>
              Project <br /> title*
            </th>
            <th>
              Name of the <br /> Funding agency
            </th>
            <th>
              Duration of <br /> the project
            </th>
            <th>Amount (Lacs)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>CAYM2</td>
          </tr>
          {renderStructure(rowsCount2, "CAYm2")}
          <tr>
            <td colSpan={7}>Amount received (Rs.)</td>
            {TotalAmount(rowsCount2, "CAYm2")}
          </tr>
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["sponsor-research-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>PI Name</th>
            <th>
              Co-PI names <br /> if any
            </th>
            <th>
              Name of the <br /> Dept., where <br />
              project is
              <br /> sanctioned
            </th>
            <th>
              Project <br /> title*
            </th>
            <th>
              Name of the <br /> Funding agency
            </th>
            <th>
              Duration of <br /> the project
            </th>
            <th>Amount (Lacs)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={8}>CAYM3</td>
          </tr>
          {renderStructure(rowsCount3, "CAYm3")}
          <tr>
            <td colSpan={7}>Amount received (Rs.)</td>
            {TotalAmount(rowsCount3, "CAYm3")}
          </tr>
          <tr>
            <td colSpan={7}>
              Total amount (Lacs) received for the past 3 years
            </td>
            {TotalAmountLast()}
          </tr>
        </tbody>
      </table>

      <span className="flex justify-end self-end ">
        <button
          onClick={() => decreaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount1 + rowsCount2 + rowsCount3 > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default SponsoredResearchConsultancyWork;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const StudentsAdmittedLateralEntry = (props) => {
  const [rowsCount, setRowsCount] = useState(1);

  const { template, metricsId, metricInfo, uploadedStatus } = props;

  const increaseCount = () => {
    setRowsCount(rowsCount + 1);
  };

  const decreaseCount = () => {
    rowsCount > 0 && setRowsCount(rowsCount - 1);
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount; count++) {
        reqBody.push({
          studentLateralEntryId: document.getElementById(
            `example_case-${count}`
          ).name,
          exampleCase: document.getElementById(`example_case-${count}`).value,
          studentsAdmitted1stYear: document.getElementById(
            `students_admitted_1st_year-${count}`
          ).value,
          leftoverSeats1stYear: document.getElementById(
            `leftover_seats_1st_year-${count}`
          ).value,
          studentsAdmitted2ndYear: document.getElementById(
            `students_admitted_2nd_year-${count}`
          ).value,
          studentsSFRCalculation: document.getElementById(
            `students_SFR_calculation-${count}`
          ).value,
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount) => {
    let result = [];

    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>
            <input
              id={`example_case-${count}`}
              name={
                metricInfo?.[template]?.[count - 1]?.[
                  "student_lateral_entry_id"
                ]
              }
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["example_case"]
              }
            />
          </td>
          <td>
            <input
              id={`students_admitted_1st_year-${count}`}
              type="number"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.[
                  "students_admitted_1st_year"
                ]
              }
              required
            />
          </td>

          <td>
            <input
              id={`leftover_seats_1st_year-${count}`}
              type="number"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["leftover_seats_1st_year"]
              }
              required
            />
          </td>

          <td>
            <input
              id={`students_admitted_2nd_year-${count}`}
              type="number"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.[
                  "students_admitted_2nd_year"
                ]
              }
              required
            />
          </td>

          <td>
            <input
              id={`students_SFR_calculation-${count}`}
              type="number"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.[
                  "students_sfr_calculation"
                ]
              }
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  useEffect(() => {
    const rows =
      metricInfo?.[template].length > 0
        ? metricInfo?.[template].length
        : rowsCount;

    setRowsCount(rows);
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["students-admitted-table"]}>
        <thead>
          <tr>
            <th>Example case</th>
            <th>
              No. of students  admitted in 1st  years
            </th>
            <th>
              Leftover seats/Unfilled  seats in 1st year
            </th>
            <th>
              No. of actually students  admitted in 2nd year, L= a+
              b; a=Lateral entry  admission (maximum 10% of SA) 
              b=Leftover seats admitted 
              in 2nd year
            </th>
            <th>
              No. of students in the program to be considered for SFR
              calculation (ST)= (SA + L) limited to 110 % of SA
            </th>
          </tr>
        </thead>
        <tbody>{renderStructure(rowsCount)}</tbody>
      </table>

      <span className="flex justify-end self-end">
        <button
          onClick={decreaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={increaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default StudentsAdmittedLateralEntry;

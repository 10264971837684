import styles from "./index.module.css";
import { useState, useEffect } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const MarksDistEnrolRatioTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [marksDistData, setMarksDistData] = useState({
    gte90: "",
    gte80: "",
    gte70: "",
    gte60: "",
    gte50: "",
    gte40: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const { gte40, gte50, gte60, gte70, gte80, gte90 } = marksDistData;

      const reqBody = {
        marksDistributionEnrolmentRatioId:
          metricInfo?.[template]?.[0]?.[
            "marks_distribution_enrolment_ratio_id"
          ] ?? "",
        gte90,
        gte80,
        gte70,
        gte60,
        gte50,
        gte40,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateMarks = (event) => {
    setMarksDistData({
      ...marksDistData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const data = {
      gte40: metricInfo?.[template]?.[0]?.["gte40"] ?? "",
      gte50: metricInfo?.[template]?.[0]?.["gte50"] ?? "",
      gte60: metricInfo?.[template]?.[0]?.["gte60"] ?? "",

      gte70: metricInfo?.[template]?.[0]?.["gte70"] ?? "",

      gte80: metricInfo?.[template]?.[0]?.["gte80"] ?? "",

      gte90: metricInfo?.[template]?.[0]?.["gte90"] ?? "",
    };

    setMarksDistData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["marks-enroll-table"]}>
        <thead>
          <tr>
            <th>
              Item (Students enrolled in the First Year on average over 3
              academic years <br /> (CAY, CAYm1 and CAYm2))
            </th>
            <th className={styles["head-center"]}>Marks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {` >= 90% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={marksDistData.gte90}
                onChange={updateMarks}
                name="gte90"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              {` >= 80% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={marksDistData.gte80}
                onChange={updateMarks}
                name="gte80"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              {` >= 70% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={marksDistData.gte70}
                onChange={updateMarks}
                name="gte70"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              {` >= 60% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={marksDistData.gte60}
                onChange={updateMarks}
                name="gte60"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              {` >= 50% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={marksDistData.gte50}
                onChange={updateMarks}
                name="gte50"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              {` >= 40% students enrolled in the First Year on average over 3
                   academic years (CAY, `}
              <br />
              {`CAYm1 and CAYm2)`}
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={marksDistData.gte40}
                onChange={updateMarks}
                name="gte40"
                required
              />
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default MarksDistEnrolRatioTable;

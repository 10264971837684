import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const EventsProgramList = (props) => {
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [rowsCount3, setRowsCount3] = useState(1);
  const [filtData, setFiltData] = useState({});

  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }
    if (each === 3) {
      setRowsCount3(rowsCount3 + 1);
    }
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }

    if (each === 3) {
      rowsCount3 > 0 && setRowsCount3(rowsCount3 - 1);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        reqBody.push({
          eventsProgramsId: document.getElementById(`CAYm1-${count}-club-name`)
            .name,
          clubName: document.getElementById(`CAYm1-${count}-club-name`).value,
          eventName: document.getElementById(`CAYm1-${count}-event-name`).value,
          level: document.getElementById(`CAYm1-${count}-level`).value,
          eventDate: document.getElementById(`CAYm1-${count}-event-date`).value,
          year: "CAYm1",
        });
      }

      for (let count = 1; count <= rowsCount2; count++) {
        reqBody.push({
          eventsProgramsId: document.getElementById(`CAYm2-${count}-club-name`)
            .name,
          clubName: document.getElementById(`CAYm2-${count}-club-name`).value,
          eventName: document.getElementById(`CAYm2-${count}-event-name`).value,
          level: document.getElementById(`CAYm2-${count}-level`).value,
          eventDate: document.getElementById(`CAYm2-${count}-event-date`).value,
          year: "CAYm2",
        });
      }

      for (let count = 1; count <= rowsCount3; count++) {
        reqBody.push({
          eventsProgramsId: document.getElementById(`CAYm3-${count}-club-name`)
            .name,
          clubName: document.getElementById(`CAYm3-${count}-club-name`).value,
          eventName: document.getElementById(`CAYm3-${count}-event-name`).value,
          level: document.getElementById(`CAYm3-${count}-level`).value,
          eventDate: document.getElementById(`CAYm3-${count}-event-date`).value,
          year: "CAYm3",
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, year) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>{count}</td>
          <td className={styles["input-col"]}>
            <input
              name={filtData?.[year]?.[count - 1]?.["events_programs_id"] ?? ""}
              defaultValue={filtData?.[year]?.[count - 1]?.["club_name"] ?? ""}
              id={`${year}-${count}-club-name`}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-event-name`}
              defaultValue={filtData?.[year]?.[count - 1]?.["event_name"] ?? ""}
              required
            />
          </td>
          <td>
            <select
              data-name={filtData?.[year]?.[count - 1]?.["level"] ?? ""}
              id={`${year}-${count}-level`}
              required
              className={`${styles["filter-select"]} focus:ring-0 text-blue-600`}
            >
              <option
                selected={
                  (filtData?.[year]?.[count - 1]?.["level"] ?? "") === ""
                }
                disabled
                hidden
                value=""
              >
                SELECT
              </option>
              <option
                selected={
                  filtData?.[year]?.[count - 1]?.["level"] === "National"
                }
                value="National"
              >
                National
              </option>
              <option
                selected={
                  filtData?.[year]?.[count - 1]?.["level"] === "International"
                }
                value="International"
              >
                International
              </option>
            </select>
          </td>
          <td>
            <input
              id={`${year}-${count}-event-date`}
              defaultValue={filtData?.[year]?.[count - 1]?.["event_date"] ?? ""}
              type="date"
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  const formatData = () => {
    try {
      const updatedData = Object.groupBy(
        metricInfo?.[template],
        ({ year }) => year
      );

      const m1Count = updatedData?.["CAYm1"]?.length ?? 1;
      const m2Count = updatedData?.["CAYm2"]?.length ?? 1;
      const m3Count = updatedData?.["CAYm3"]?.length ?? 1;

      setFiltData(updatedData);
      setRowsCount1(m1Count);
      setRowsCount2(m2Count);
      setRowsCount3(m3Count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formatData();
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["event-program-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Professional Societies/Bodies/Chapters, Clubs</th>
            <th>Name of the Event</th>
            <th>National / International level</th>
            <th>Date of Event</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={5}>CAYM1</td>
          </tr>
          {renderStructure(rowsCount1, "CAYm1")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["event-program-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Professional Societies/Bodies/Chapters, Clubs</th>
            <th>Name of the Event</th>
            <th>National / International level</th>
            <th>Date of Event</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={5}>CAYM2</td>
          </tr>
          {renderStructure(rowsCount2, "CAYm2")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["event-program-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Professional Societies/Bodies/Chapters, Clubs</th>
            <th>Name of the Event</th>
            <th>National / International level</th>
            <th>Date of Event</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={5}>CAYM3</td>
          </tr>
          {renderStructure(rowsCount3, "CAYm3")}
        </tbody>
      </table>

      <span className="flex justify-end self-end ">
        <button
          onClick={() => decreaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount1 + rowsCount2 + rowsCount3 > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default EventsProgramList;

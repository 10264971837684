import { Link } from "react-router-dom";
import styles from "./index.module.css";

const CriteriaList = (props) => {
  const { item, projectId } = props;

  const {
    criteriaNo,
    criteriaStatement,
    weightage,
    totalTasks,
    tasksDone,
    inProgress,
    submitted,
  } = item;

  const progressStyle = {
    width: `${(tasksDone / totalTasks) * 100}%`,
  };

  return (
    <li>
      <h1>
        {criteriaNo}. {criteriaStatement}
      </h1>
      <p className="flex justify-between">
        {Math.round((tasksDone / totalTasks) * 100)}% metrics completed (
        {weightage} Marks)
      </p>

      <div className={styles["progress-bar-container"]}>
        <div className={styles["progress-bar"]} style={progressStyle}>
          &nbsp;
        </div>
      </div>

      <div className="flex items-center mt-8">
        <p className={styles["metric-progress-desc"]}>
          <span className={styles["metric-progress-percent"]}>
            {Math.round((inProgress / totalTasks) * 100)}%
          </span>
          In Draft
        </p>
        <p className={styles["metric-progress-desc"]}>
          <span className={styles["metric-progress-percent"]}>
            {Math.round((submitted / totalTasks) * 100)}%
          </span>
          Submitted
        </p>
      </div>
      <Link
        to={`/connect/dashboard/criteria/metrics/${projectId}/${criteriaNo}`}
        className={styles["metrics-btn"]}
      >
        <button className={styles["metrics-btn"]}>View all metrics</button>
      </Link>
    </li>
  );
};

export default CriteriaList;

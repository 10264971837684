import styles from "./index.module.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const TotalTasksPie = (props) => {
  const { tasks } = props;
  const { totalTasks, toDo, inProgress, done } = tasks;

  const options = {
    chart: {
      type: "pie",
      width: 300,
      height: 150,
    },
    title: {
      margin: 0,
      text: `TOTAL TASKS (${totalTasks})`,
      verticalAlign: "top",
      align: "left",
      style: {
        color: "#606981",
        fontSize: "12px",
        fontWeight: "900",
        fontFamily: "Lato",
      },
    },

    colors: ["#8D77F3", "#F6416C", "#FCA551"],

    legend: {
      floating: true,
      x: -30,
      y: 15,
      symbolRadius: 0,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginTop: 14,
      itemStyle: {
        fontSize: "13px",
        color: "#8F949F",
      },
    },

    accessibility: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        center: [40, 45],
        allowPointSelect: true,
        size: 100,
        cursor: "pointer",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },

    series: [
      {
        type: "pie",
        name: "",
        innerSize: "60%",
        data: [
          [`${toDo} - To Do`, toDo],
          [`${inProgress} - In Progress`, inProgress],
          [`${done} - Done`, done],
        ],
      },
    ],
  };

  return (
    <div className={styles["tasks-pie-chart"]}>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </div>
  );
};

export default TotalTasksPie;

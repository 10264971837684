import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyQualificationTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;
  const [facultyQualifyData, setFacultyQualifyData] = useState({
    phdCay: "",
    phdCaym1: "",
    phdCaym2: "",
    mtechCay: "",
    mtechCaym1: "",
    mtechCaym2: "",
    requiredCay: "",
    requiredCaym1: "",
    requiredCaym2: "",
  });

  const updateFacultyQualifyData = (event) => {
    setFacultyQualifyData({
      ...facultyQualifyData,
      [event.target.name]: event.target.value,
    });
  };

  const calculateFQI = (phd, mtech, required) => {
    const fqi =
      2.5 * ((parseInt(phd) * 10 + parseInt(mtech) * 4) / parseInt(required));
    return fqi.toFixed(2);
  };

  const renderAvg = () => {
    const fqi1 = calculateFQI(
      facultyQualifyData.phdCay,
      facultyQualifyData.mtechCay,
      facultyQualifyData.requiredCay
    );
    const fqi2 = calculateFQI(
      facultyQualifyData.phdCaym1,
      facultyQualifyData.mtechCaym1,
      facultyQualifyData.requiredCaym1
    );
    const fqi3 = calculateFQI(
      facultyQualifyData.phdCaym2,
      facultyQualifyData.mtechCaym2,
      facultyQualifyData.requiredCaym2
    );

    return (
      (parseFloat(fqi1) + parseFloat(fqi2) + parseFloat(fqi3)) /
      3
    ).toFixed(2);
  };

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        phdCay,
        phdCaym1,
        phdCaym2,
        mtechCay,
        mtechCaym1,
        mtechCaym2,
        requiredCay,
        requiredCaym1,
        requiredCaym2,
      } = facultyQualifyData;

      const reqBody = {
        facultyQualificationId:
          metricInfo?.[template]?.[0]?.["fac_qualification_id"] ?? "",
        phdCay,
        phdCaym1,
        phdCaym2,
        mtechCay,
        mtechCaym1,
        mtechCaym2,
        requiredCay,
        requiredCaym1,
        requiredCaym2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  useEffect(() => {
    const data = {
      phdCay: metricInfo?.[template]?.[0]?.["phd_cay"] ?? "",

      phdCaym1: metricInfo?.[template]?.[0]?.["phd_caym1"] ?? "",

      phdCaym2: metricInfo?.[template]?.[0]?.["phd_caym2"] ?? "",

      mtechCay: metricInfo?.[template]?.[0]?.["mtech_cay"] ?? "",

      mtechCaym1: metricInfo?.[template]?.[0]?.["mtech_caym1"] ?? "",
      mtechCaym2: metricInfo?.[template]?.[0]?.["mtech_caym2"] ?? "",
      requiredCay: metricInfo?.[template]?.[0]?.["required_cay"] ?? "",

      requiredCaym1: metricInfo?.[template]?.[0]?.["required_caym1"] ?? "",
      requiredCaym2: metricInfo?.[template]?.[0]?.["required_caym2"] ?? "",
    };

    setFacultyQualifyData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["faculty-qualify-table"]}>
        <thead>
          <tr>
            <th>Year</th>
            <th>X</th>
            <th>Y</th>
            <th>RF</th>
            <th>FQI=2.5*[(10X+4Y)/RF]</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>CAY</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.phdCay}
                onChange={updateFacultyQualifyData}
                name="phdCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.mtechCay}
                onChange={updateFacultyQualifyData}
                name="mtechCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.requiredCay}
                onChange={updateFacultyQualifyData}
                name="requiredCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              {calculateFQI(
                facultyQualifyData.phdCay,
                facultyQualifyData.mtechCay,
                facultyQualifyData.requiredCay
              )}
            </td>
          </tr>

          <tr>
            <td>CAYm1</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.phdCaym1}
                onChange={updateFacultyQualifyData}
                name="phdCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.mtechCaym1}
                onChange={updateFacultyQualifyData}
                name="mtechCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.requiredCaym1}
                onChange={updateFacultyQualifyData}
                name="requiredCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              {calculateFQI(
                facultyQualifyData.phdCaym1,
                facultyQualifyData.mtechCaym1,
                facultyQualifyData.requiredCaym1
              )}
            </td>
          </tr>

          <tr>
            <td>CAYm2</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.phdCaym2}
                onChange={updateFacultyQualifyData}
                name="phdCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.mtechCaym2}
                onChange={updateFacultyQualifyData}
                name="mtechCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyQualifyData.requiredCaym2}
                onChange={updateFacultyQualifyData}
                name="requiredCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              {calculateFQI(
                facultyQualifyData.phdCaym2,
                facultyQualifyData.mtechCaym2,
                facultyQualifyData.requiredCaym2
              )}
            </td>
          </tr>

          <tr>
            <td className="text-right" colSpan={4}>
              Average Assessment
            </td>
            <td className={styles["input-col"]}>{renderAvg()}</td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default FacultyQualificationTable;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyQualificationImprovement = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [academicData, setAcademicData] = useState({
    facultyCountCaym1: "",
    facultyCountCaym2: "",
    facultyCountCaym3: "",
    journalPublicationsCaym1: "",
    journalPublicationsCaym2: "",
    journalPublicationsCaym3: "",
    conferencePublicationsCaym1: "",
    conferencePublicationsCaym2: "",
    conferencePublicationsCaym3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        facultyCountCaym1,
        facultyCountCaym2,
        facultyCountCaym3,
        journalPublicationsCaym1,
        journalPublicationsCaym2,
        journalPublicationsCaym3,
        conferencePublicationsCaym1,
        conferencePublicationsCaym2,
        conferencePublicationsCaym3,
      } = academicData;

      const reqBody = {
        facultyQualificationImprovementId:
          metricInfo?.[template]?.[0]?.[
            "faculty_qualification_improvement_id"
          ] ?? "",
        facultyCountCaym1,
        facultyCountCaym2,
        facultyCountCaym3,
        journalPublicationsCaym1,
        journalPublicationsCaym2,
        journalPublicationsCaym3,
        conferencePublicationsCaym1,
        conferencePublicationsCaym2,
        conferencePublicationsCaym3,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateAcademicData = (event) => {
    setAcademicData({
      ...academicData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const data = {
      facultyCountCaym1:
        metricInfo?.[template]?.[0]?.["faculty_count_caym1"] ?? "",
      facultyCountCaym2:
        metricInfo?.[template]?.[0]?.["faculty_count_caym2"] ?? "",
      facultyCountCaym3:
        metricInfo?.[template]?.[0]?.["faculty_count_caym3"] ?? "",
      journalPublicationsCaym1:
        metricInfo?.[template]?.[0]?.["journal_publications_caym1"] ?? "",
      journalPublicationsCaym2:
        metricInfo?.[template]?.[0]?.["journal_publications_caym2"] ?? "",
      journalPublicationsCaym3:
        metricInfo?.[template]?.[0]?.["journal_publications_caym3"] ?? "",

      conferencePublicationsCaym1:
        metricInfo?.[template]?.[0]?.["conferences_publications_caym1"] ?? "",
      conferencePublicationsCaym2:
        metricInfo?.[template]?.[0]?.["conferences_publications_caym2"] ?? "",
      conferencePublicationsCaym3:
        metricInfo?.[template]?.[0]?.["conferences_publications_caym3"] ?? "",
    };

    setAcademicData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col ">
      <table className={styles["faculty-qualify-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
            <th>CAYm3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              No. of faculty members <br /> with Ph.D. degree
              <br />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.facultyCountCaym1}
                onChange={updateAcademicData}
                name="facultyCountCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.facultyCountCaym2}
                onChange={updateAcademicData}
                name="facultyCountCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.facultyCountCaym3}
                onChange={updateAcademicData}
                name="facultyCountCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              No. of publications in peer
              <br /> reviewed journals
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.journalPublicationsCaym1}
                onChange={updateAcademicData}
                name="journalPublicationsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.journalPublicationsCaym2}
                onChange={updateAcademicData}
                name="journalPublicationsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.journalPublicationsCaym3}
                onChange={updateAcademicData}
                name="journalPublicationsCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              No. of publications in <br /> conferences
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.conferencePublicationsCaym1}
                onChange={updateAcademicData}
                name="conferencePublicationsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.conferencePublicationsCaym2}
                onChange={updateAcademicData}
                name="conferencePublicationsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.conferencePublicationsCaym3}
                onChange={updateAcademicData}
                name="conferencePublicationsCaym3"
                required
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" className={styles["save-btn"]}>
        Save
      </button>
    </form>
  );
};

export default FacultyQualificationImprovement;

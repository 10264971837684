import styles from "./index.module.css";

const StatementList = (props) => {
  const { item, count, template, templateNames, statementChanged } = props;

  const statementValChanged = (event) => {
    statementChanged(count, event.target.value);
  };

  return (
    <li className="flex items-center w-full text-[#606981] bg-[#ffffff] font-medium p-0 pl-3 mb-3">
      <span className="flex flex-col w-full">
        <p
          className={`${styles["co-statement-text"]} mb-4 mt-4 flex items-center text-[#8F949F] w-full mr-1`}
        >
          {template === templateNames.missionStatement ? "Mission" : "PEO"}{" "}
          Statement {count}:
        </p>

        <input
          required
          onChange={statementValChanged}
          value={item["statement"]}
          type="text"
          className={`${styles["filter-select-desc"]} bg-[#fff] border border-0 border-b w-full p-0 m-0 flex break-words`}
        />
      </span>
    </li>
  );
};

export default StatementList;

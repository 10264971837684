import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import styles from "./index.module.css";
import { useEffect, useState } from "react";

const CurriculumStructureTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;
  const [rowsCount, setRowsCount] = useState(1);

  const submitted = async (event) => {
    event.preventDefault();
    const reqBody = [];

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      for (let count = 1; count <= rowsCount; count++) {
        reqBody.push({
          courseCode: document.getElementById(`${count}-code`).value,
          courseTitle: document.getElementById(`${count}-title`).value,
          lecture: document.getElementById(`${count}-l`).value,
          tutorial: document.getElementById(`${count}-t`).value,
          practical: document.getElementById(`${count}-p`).value,
          selfLearning: document.getElementById(`${count}-sl`).value,
          totalHours: document.getElementById(`${count}-hours`).value,
          credits: document.getElementById(`${count}-credits`).value,
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = () => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={count}>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["course_code"] ?? ""
              }
              id={`${count}-code`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["course_title"] ?? ""
              }
              id={`${count}-title`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["lecture_hours"] ?? ""
              }
              id={`${count}-l`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["tutorial_hours"] ?? ""
              }
              id={`${count}-t`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["practical_hours"] ?? ""
              }
              id={`${count}-p`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["self_learning_hours"] ??
                ""
              }
              id={`${count}-sl`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["total_hours"] ?? ""
              }
              id={`${count}-hours`}
            />
          </td>
          <td>
            <input
              required
              type="text"
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["credits"] ?? ""
              }
              id={`${count}-credits`}
            />
          </td>
        </tr>
      );
    }

    return result;
  };

  const increaseCount = () => {
    setRowsCount(rowsCount + 1);
  };

  const decreaseCount = () => {
    rowsCount > 0 && setRowsCount(rowsCount - 1);
  };

  useEffect(() => {
    try {
      metricInfo[template].length > 0 &&
        setRowsCount(metricInfo?.[template].length ?? 1);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["structure-table"]}>
        <tr>
          <th rowSpan={3}>Course Code</th>
          <th rowSpan={3}>Course Titles</th>
          <th colSpan={6}>Teaching & Learning Scheme</th>
        </tr>

        <tr>
          <th colSpan={2}>
            Classroom <br />
            Instruction <br />
            (CI)
            <br /> (hrs per sem)
          </th>
          <th>
            Lab <br />
            Instruction <br />
            (LI)
            <br /> (hrs per sem)
          </th>
          <th>
            Team Work (TW) <br /> +
            <br /> Self Learning (TW)
          </th>
          <th rowSpan={2}>Total Hours</th>
          <th rowSpan={2}>
            Total Credits(C)
            <br />
            *<br />
            (Total Hours/30)
          </th>
        </tr>

        <tr>
          <th>L</th>
          <th>T</th>
          <th>P</th>
          <th>SL</th>
        </tr>

        {renderStructure()}
      </table>
      <span className="self-end">
        <button
          onClick={decreaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={increaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>
      {rowsCount > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default CurriculumStructureTable;

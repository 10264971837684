import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const SuccessRateTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [successRateData, setSuccessRateData] = useState({
    remainingStudentsLyg: "",
    remainingStudentsLygM1: "",
    remainingStudentsLygM2: "",
    graduatedStudentsLyg: "",
    graduatedStudentsLygM1: "",
    graduatedStudentsLygM2: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        remainingStudentsLyg,
        remainingStudentsLygM1,
        remainingStudentsLygM2,
        graduatedStudentsLyg,
        graduatedStudentsLygM1,
        graduatedStudentsLygM2,
      } = successRateData;

      const reqBody = {
        successRateId: metricInfo?.[template]?.[0]?.["success_rate_id"] ?? "",
        remainingStudentsLyg,
        remainingStudentsLygM1,
        remainingStudentsLygM2,
        graduatedStudentsLyg,
        graduatedStudentsLygM1,
        graduatedStudentsLygM2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateSuccessRateData = (event) => {
    setSuccessRateData({
      ...successRateData,
      [event.target.name]: event.target.value,
    });
  };

  const renderSuccessRate = () => {
    let result = [];

    try {
      const sr1 =
        (parseInt(successRateData?.graduatedStudentsLyg) /
          parseInt(successRateData?.remainingStudentsLyg)) *
        100;

      const sr2 =
        (parseInt(successRateData?.graduatedStudentsLygM1) /
          parseInt(successRateData?.remainingStudentsLygM1)) *
        100;

      const sr3 =
        (parseInt(successRateData?.graduatedStudentsLygM2) /
          parseInt(successRateData?.remainingStudentsLygM2)) *
        100;

      result.push(
        <>
          <td className={styles["input-col"]}>{sr1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{sr2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{sr3.toFixed(2)}</td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderAvg = () => {
    let result = "";

    try {
      const sr1 =
        (parseInt(successRateData?.graduatedStudentsLyg) /
          parseInt(successRateData?.remainingStudentsLyg)) *
        100;

      const sr2 =
        (parseInt(successRateData?.graduatedStudentsLygM1) /
          parseInt(successRateData?.remainingStudentsLygM1)) *
        100;

      const sr3 =
        (parseInt(successRateData?.graduatedStudentsLygM2) /
          parseInt(successRateData?.remainingStudentsLygM2)) *
        100;

      result = ((sr1 + sr2 + sr3) / 3).toFixed(2);
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    const data = {
      remainingStudentsLyg:
        metricInfo?.[template]?.[0]?.["remaining_students_lyg"] ?? "",

      remainingStudentsLygM1:
        metricInfo?.[template]?.[0]?.["remaining_students_lygm1"] ?? "",

      remainingStudentsLygM2:
        metricInfo?.[template]?.[0]?.["remaining_students_lygm2"] ?? "",

      graduatedStudentsLyg:
        metricInfo?.[template]?.[0]?.["graduated_students_lyg"] ?? "",

      graduatedStudentsLygM1:
        metricInfo?.[template]?.[0]?.["graduated_students_lygm1"] ?? "",
      graduatedStudentsLygM2:
        metricInfo?.[template]?.[0]?.["graduated_students_lygm2"] ?? "",
    };

    setSuccessRateData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["success-rate-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>LYG</th>
            <th>LYGm1</th>
            <th>LYGm2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              A*= (No. of students admitted in the 1st year of <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that batch and
              those actually admitted in the <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2nd year via
              lateral entry, plus the number of <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; students admitted
              through multiple entry (if <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any) and separate
              division if applicable, minus <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the number of
              students who exited through <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; multiple entry
              (if any)).
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.remainingStudentsLyg}
                onChange={updateSuccessRateData}
                name="remainingStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.remainingStudentsLygM1}
                onChange={updateSuccessRateData}
                name="remainingStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.remainingStudentsLygM2}
                onChange={updateSuccessRateData}
                name="remainingStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              B=&nbsp; No. of students who graduated from the program in the
              stipulated course duration
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.graduatedStudentsLyg}
                onChange={updateSuccessRateData}
                name="graduatedStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.graduatedStudentsLygM1}
                onChange={updateSuccessRateData}
                name="graduatedStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={successRateData.graduatedStudentsLygM2}
                onChange={updateSuccessRateData}
                name="graduatedStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Success Rate (SR)= (B/A) * 100</td>

            {renderSuccessRate()}
          </tr>

          <tr>
            <td>Average SR of three batches ((SR_1+ SR_2+ SR_3)/3)</td>
            <td className={styles["input-col"]} colSpan={3}>
              {renderAvg()}
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default SuccessRateTable;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const StdFacRatio1stYear = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [stdFacRatioData, setStdFacRatioData] = useState({
    sanctionedIntakeFacRatioCay: "",
    sanctionedIntakeFacRatioCayM1: "",
    sanctionedIntakeFacRatioCayM2: "",
    requiredFacultyRatioCay: "",
    requiredFacultyRatioCayM1: "",
    requiredFacultyRatioCayM2: "",
    basicScienceFacultyCay: "",
    basicScienceFacultyCayM1: "",
    basicScienceFacultyCayM2: "",
    engineeringScienceFacultyCay: "",
    engineeringScienceFacultyCayM1: "",
    engineeringScienceFacultyCayM2: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        sanctionedIntakeFacRatioCay,
        sanctionedIntakeFacRatioCayM1,
        sanctionedIntakeFacRatioCayM2,
        requiredFacultyRatioCay,
        requiredFacultyRatioCayM1,
        requiredFacultyRatioCayM2,
        basicScienceFacultyCay,
        basicScienceFacultyCayM1,
        basicScienceFacultyCayM2,
        engineeringScienceFacultyCay,
        engineeringScienceFacultyCayM1,
        engineeringScienceFacultyCayM2,
      } = stdFacRatioData;

      const reqBody = {
        stdFacRatio1stYearId:
          metricInfo?.[template]?.[0]?.["std_fac_ratio_1st_year_id"] ?? "",
        sanctionedIntakeFacRatioCay,
        sanctionedIntakeFacRatioCayM1,
        sanctionedIntakeFacRatioCayM2,
        requiredFacultyRatioCay,
        requiredFacultyRatioCayM1,
        requiredFacultyRatioCayM2,
        basicScienceFacultyCay,
        basicScienceFacultyCayM1,
        basicScienceFacultyCayM2,
        engineeringScienceFacultyCay,
        engineeringScienceFacultyCayM1,
        engineeringScienceFacultyCayM2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateStdFacRatioData = (event) => {
    setStdFacRatioData({
      ...stdFacRatioData,
      [event.target.name]: event.target.value,
    });
  };

  const renderPercentageCay = () => {
    try {
      const percentage =
        (parseInt(stdFacRatioData?.basicScienceFacultyCay || 0) * 0.8 +
          parseInt(stdFacRatioData?.engineeringScienceFacultyCay || 0) * 0.2) /
        parseInt(stdFacRatioData?.requiredFacultyRatioCay || 0);
      return (percentage * 100).toFixed(2);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const renderPercentageCayM1 = () => {
    try {
      const percentage =
        (parseInt(stdFacRatioData?.basicScienceFacultyCayM1 || 0) * 0.8 +
          parseInt(stdFacRatioData?.engineeringScienceFacultyCayM1 || 0) *
            0.2) /
        parseInt(stdFacRatioData?.requiredFacultyRatioCayM1 || 0);
      return (percentage * 100).toFixed(2);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const renderPercentageCayM2 = () => {
    try {
      const percentage =
        (parseInt(stdFacRatioData?.basicScienceFacultyCayM2 || 0) * 0.8 +
          parseInt(stdFacRatioData?.engineeringScienceFacultyCayM2 || 0) *
            0.2) /
        parseInt(stdFacRatioData?.requiredFacultyRatioCayM2 || 0);
      return (percentage * 100).toFixed(2);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const renderAvgNumbers = () => {
    try {
      const percentage1 = renderPercentageCay();

      const percentage2 = renderPercentageCayM1();

      const percentage3 = renderPercentageCayM2();

      const percentageAvg =
        (parseInt(percentage1) ||
          0 + parseInt(percentage2) ||
          0 + parseInt(percentage3) ||
          0) / 3;

      return percentageAvg.toFixed(2);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    const data = {
      sanctionedIntakeFacRatioCay:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_cay"] ?? "",

      sanctionedIntakeFacRatioCayM1:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_caym1"] ?? "",

      sanctionedIntakeFacRatioCayM2:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_caym2"] ?? "",

      requiredFacultyRatioCay:
        metricInfo?.[template]?.[0]?.["required_faculty_cay"] ?? "",

      requiredFacultyRatioCayM1:
        metricInfo?.[template]?.[0]?.["required_faculty_caym1"] ?? "",

      requiredFacultyRatioCayM2:
        metricInfo?.[template]?.[0]?.["required_faculty_caym2"] ?? "",

      basicScienceFacultyCay:
        metricInfo?.[template]?.[0]?.["basic_science_faculty_cay"] ?? "",

      basicScienceFacultyCayM1:
        metricInfo?.[template]?.[0]?.["basic_science_faculty_caym1"] ?? "",

      basicScienceFacultyCayM2:
        metricInfo?.[template]?.[0]?.["basic_science_faculty_caym2"] ?? "",

      engineeringScienceFacultyCay:
        metricInfo?.[template]?.[0]?.["engineering_science_faculty_cay"] ?? "",

      engineeringScienceFacultyCayM1:
        metricInfo?.[template]?.[0]?.["engineering_science_faculty_caym1"] ??
        "",

      engineeringScienceFacultyCayM2:
        metricInfo?.[template]?.[0]?.["engineering_science_faculty_caym2"] ??
        "",
    };

    setStdFacRatioData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["std-fac-ratio-table"]}>
        <thead>
          <tr>
            <th>Year</th>
            <th>Sanctioned intake of all UG programs(S4)</th>
            <th>No. of required faculty (RF4=S4/20)</th>
            <th>
              No. of faculty members in Basic Science Courses & Humanities and
              Social Sciences including Management courses (NS1)
            </th>
            <th>No. of faculty members in Engineering Science Courses (NS2)</th>
            <th>
              Percentage= No. of faculty members ((NS1*0.8) +(NS2*0.2))/(No. of
              required faculty (RF4)); Percentage=((NS1*0.8) +(NS2*0.2))/RF
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CAY</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.sanctionedIntakeFacRatioCay}
                onChange={updateStdFacRatioData}
                name="sanctionedIntakeFacRatioCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.requiredFacultyRatioCay}
                onChange={updateStdFacRatioData}
                name="requiredFacultyRatioCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.basicScienceFacultyCay}
                onChange={updateStdFacRatioData}
                name="basicScienceFacultyCay"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.engineeringScienceFacultyCay}
                onChange={updateStdFacRatioData}
                name="engineeringScienceFacultyCay"
                required
              />
            </td>

            <td className={styles["input-col"]}>{renderPercentageCay()}</td>
          </tr>

          <tr>
            <td>CAYm1</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.sanctionedIntakeFacRatioCayM1}
                onChange={updateStdFacRatioData}
                name="sanctionedIntakeFacRatioCayM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.requiredFacultyRatioCayM1}
                onChange={updateStdFacRatioData}
                name="requiredFacultyRatioCayM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.basicScienceFacultyCayM1}
                onChange={updateStdFacRatioData}
                name="basicScienceFacultyCayM1"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.engineeringScienceFacultyCayM1}
                onChange={updateStdFacRatioData}
                name="engineeringScienceFacultyCayM1"
                required
              />
            </td>

            <td className={styles["input-col"]}>{renderPercentageCayM1()}</td>
          </tr>

          <tr>
            <td>CAYm2</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.sanctionedIntakeFacRatioCayM2}
                onChange={updateStdFacRatioData}
                name="sanctionedIntakeFacRatioCayM2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.requiredFacultyRatioCayM2}
                onChange={updateStdFacRatioData}
                name="requiredFacultyRatioCayM2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.basicScienceFacultyCayM2}
                onChange={updateStdFacRatioData}
                name="basicScienceFacultyCayM2"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={stdFacRatioData.engineeringScienceFacultyCayM2}
                onChange={updateStdFacRatioData}
                name="engineeringScienceFacultyCayM2"
                required
              />
            </td>

            <td className={styles["input-col"]}>{renderPercentageCayM2()}</td>
          </tr>

          <tr>
            <td colSpan={5}>Average Percentage</td>
            <td className={styles["input-col"]}>{renderAvgNumbers()}</td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default StdFacRatio1stYear;

import { useEffect, useState } from "react";
import styles from "./index.module.css";
import StatementList from "./StatementList";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const Statement = (props) => {
  const [statements, updateStatements] = useState([]);
  const { uploadedStatus, metricsId, template, templateNames, metricInfo } =
    props;

  const renderMissionStatements = () => {
    return statements.map((each, idx) => (
      <StatementList
        key={idx + 1}
        count={idx + 1}
        item={each}
        template={template}
        templateNames={templateNames}
        statementChanged={statementChanged}
      />
    ));
  };

  const statementChanged = (index, val) => {
    const updatedData = statements.map((each) => {
      if (index === each.statementNo) {
        return { ...each, statement: val };
      }

      return each;
    });

    updateStatements(updatedData);
  };

  const saveStatement = async (event) => {
    event.preventDefault();
    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const statementKey =
        template === templateNames.missionStatement
          ? "missionStatement"
          : "peoStatement";

      const statementNoKey =
        template === templateNames.missionStatement ? "missionNo" : "peoNo";

      let reqBody = [];

      statements.forEach((each, idx) => {
        reqBody.push({
          statementId: each["id"],
          [`${statementKey}`]: each["statement"],
          [`${statementNoKey}`]: idx + 1,
        });
      });

      const options = {
        method: metricInfo.length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const statementBtn = (event) => {
    if (event.target.name === "+") {
      updateStatements([
        ...statements,
        {
          id: "",
          statement: "",
          statementNo: statements.length + 1,
        },
      ]);
    } else {
      const removedVal = statements.slice(0, statements.length - 1);
      updateStatements(removedVal);
    }
  };

  useEffect(() => {
    const updatedData = metricInfo.map((each) => ({
      id: each.id,
      statement:
        template === templateNames.missionStatement
          ? each["mission_statement"]
          : each["peo_statement"],
      statementNo:
        template === templateNames.missionStatement
          ? each["mission_no"]
          : each["peo_no"],
    }));

    if (updatedData.length === 0) {
      updateStatements([
        {
          id: "",
          statement: "",
          statementNo: 1,
        },
      ]);
    } else {
      updateStatements(updatedData);
    }
  }, []);

  return (
    <form onSubmit={saveStatement} className="flex flex-col">
      <ul className="list-none">{renderMissionStatements()}</ul>

      <span className="flex items-center self-end">
        {statements.length > 1 && (
          <button
            name="-"
            onClick={statementBtn}
            type="button"
            className={styles["mission-statement-btn"]}
          >
            -
          </button>
        )}

        <button
          name="+"
          onClick={statementBtn}
          type="button"
          className={styles["mission-statement-btn"]}
        >
          +
        </button>
      </span>

      {statements.length > 0 && (
        <button type="submit" className={styles["save-btn"]}>
          Save
        </button>
      )}
    </form>
  );
};

export default Statement;

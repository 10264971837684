import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import styles from "./index.module.css";

const PoPsoStatements = (props) => {
  const { uploadedStatus, branch, statusChangeBtn, template } = props;

  const [poData, updatePoData] = useState([]);
  const [psoData, updatePsoData] = useState([]);

  const getPoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(apiClient.urls.fatcat.PO_DATA, options);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updatePoData(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }

    uploadedStatus("success");
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${branch}`,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updatePsoData(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderPosDesc = () => {
    try {
      let ans = [];

      for (let each of poData) {
        ans.push(
          <div
            key={each["po"]}
            className={`${styles["existing-pso-box"]} text-ellipsis overflow-hidden`}
          >
            <p className={styles["pso-btn"]}>{each["po"]}</p>
            <p className="text-ellipsis overflow-hidden">{each["poDesc"]}</p>
          </div>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderPsosDesc = () => {
    try {
      let ans = [];

      for (let each of psoData) {
        ans.push(
          <div
            key={each["po"]}
            className={`${styles["existing-pso-box"]} text-ellipsis overflow-hidden`}
          >
            <p className={styles["pso-btn"]}>{each["po"]}</p>
            <p className="text-ellipsis overflow-hidden">{each["poDesc"]}</p>
          </div>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    branch !== undefined && template === "pso-statement" && getPsoData();
  }, [branch]);

  useEffect(() => {
    template === "po-statement" && getPoData();
  }, []);

  return (
    <div className="flex flex-col ml-2">
      {template === "po-statement" && renderPosDesc()}
      {template === "pso-statement" && renderPsosDesc()}

      {template === "pso-statement" && (
        <button
          onClick={() => statusChangeBtn({ target: { name: 2 } })}
          className={`${styles["save-btn"]} mt-2 mb-5`}
          type="button"
        >
          Save
        </button>
      )}
    </div>
  );
};

export default PoPsoStatements;

import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Component } from "react";
import { Oval } from "react-loader-spinner";

class CoAttainmentTable extends Component {
  state = {
    data: null,
    content: "loading",
    internalData: [],
    externalData: [],
    courseData: {},
  };

  abortController = null;

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      batch,
      branch,
      sem,
      courseCode,
      sec,
      internals,
      externals,
      activeCourse,
    } = this.props;

    if (
      prevProps.batch !== batch ||
      prevProps.branch !== branch ||
      prevProps.sem !== sem ||
      prevProps.courseCode !== courseCode ||
      prevProps.sec !== sec ||
      prevProps.internals !== internals ||
      prevProps.externals !== externals ||
      prevProps.activeCourse !== activeCourse
    ) {
      this.getData();
      this.setState({
        internalData: internals,
        externalData: externals,
        courseData: activeCourse,
      });
    }
  }

  componentDidMount() {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        const { internals, externals, activeCourse } = this.props;
        this.getData();
        this.setState({
          internalData: internals,
          externalData: externals,
          courseData: activeCourse,
        });
      }
    }
  }

  getData = async () => {
    try {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();
      this.setState({ content: "loading" });

      const { courseCode, sec, secOptions } = this.props;
      const joinedSec = secOptions.join();

      const sectionsVal = sec === "" ? joinedSec : sec;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS_CO_ATT +
            `?courseId=${courseCode}&sectionList=${sectionsVal}`,
          { Authorization: token },
          {
            signal: this.abortController.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ data: response["data"]["result"], content: true });
        } else {
          this.setState({ data: null, content: false });
        }
      } else {
        this.setState({ data: null, content: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderCoAtt = () => {
    const { data, internalData, externalData, courseData } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data?.["daAttDetails"] ?? {}).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2">
              {courseData?.["courseCode"]}.{[index + 1]}
            </th>

            {internalData.length > 0 && (
              <td
                className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
              >
                {data?.["daAttDetails"]?.[`CO${index + 1}`]?.["Internal"]?.[
                  "attPct"
                ] ?? ""}
              </td>
            )}

            {externalData.length > 0 && (
              <td
                className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
              >
                {data?.["daAttDetails"]?.[`CO${index + 1}`]?.["External"]?.[
                  "attPct"
                ] ?? ""}
              </td>
            )}

            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["daAttDetails"]?.[`CO${index + 1}`]?.["Internal"]?.[
                "wAttPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["daAttDetails"]?.[`CO${index + 1}`]?.["External"]?.[
                "wAttPct"
              ] ?? ""}
            </td>

            <th
              className={`${styles["table-td"]} text-center border border-blue-500 p-2`}
            >
              {data?.["daAttDetails"]?.[`CO${index + 1}`]?.["totDaAttPct"] ??
                ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderOverAllAtt = () => {
    const { data, courseData } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data?.["daAttDetails"] ?? {}).length;
        index++
      ) {
        ans.push(
          <tr
            key={index}
            className="border border-blue-500 text-[#040C58] font-semibold"
          >
            <th className="border border-blue-500 p-2">
              {courseData?.["courseCode"]}.{[index + 1]}
            </th>

            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["DA"]?.[
                "totDaAttPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["DA"]?.[
                "wTotDaAttPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["IDA"]?.[
                "totIdaAttPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["IDA"]?.[
                "wTotIdaAttPct"
              ] ?? ""}
            </td>

            <th className={`${styles["table-td"]} p-2 border border-blue-500`}>
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["totAttPct"] ??
                ""}
            </th>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["targetAtt"] ??
                ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.["attLvl"] ??
                ""}
            </td>

            <th
              className={`${styles["table-td"]} ${
                data?.["overallAttDetails"]?.[`CO${index + 1}`]?.[
                  "attAchievedBool"
                ] === "Y"
                  ? "text-green-500"
                  : "text-red-500"
              } border border-blue-500 p-2`}
            >
              {data?.["overallAttDetails"]?.[`CO${index + 1}`]?.[
                "attAchievedBool"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  oAscrollTbClickedLeft = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  oAscrollTbClicked = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Data Found For The Selected Filters To View Attainments & Analysis
      </p>
    </div>
  );

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  renderCiewSeew = () => {
    const { courseData } = this.state;

    const ans = [];
    try {
      ans.push(
        <>
          <th className="border border-blue-500 p-2">
            {courseData?.["cieW"] ?? ""}% of <br />
            CIE
          </th>
          <th className="border border-blue-500 p-2">
            {courseData?.["seeW"] ?? ""}% of <br />
            SEE
          </th>
        </>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDAw = () => {
    const { courseData } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {courseData?.["directCoW"] ?? ""}% of DA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderIAw = () => {
    const { courseData } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {courseData?.["indirectCoW"] ?? ""}% of IA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderScreen = () => {
    try {
      const { content, internalData, externalData } = this.state;

      switch (content) {
        case true:
          return (
            <>
              <div className={styles["bg-containers"]}>
                <p className={styles["art-matrix-title"]}>
                  Attainment Of Course Outcomes (Cos) Through Direct Assessment
                  (DA) Tools
                </p>

                <div className={styles.table}>
                  <div
                    id="scrollTb"
                    className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                  >
                    <table className="mb-2 border-solid border-blue-600 border-1">
                      <tbody>
                        <tr
                          className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                        >
                          <th className="border border-blue-500 p-2">
                            Course Outcome
                          </th>
                          {internalData.length > 0 && (
                            <th className="border border-blue-500 p-2">
                              Internal
                            </th>
                          )}

                          {externalData.length > 0 && (
                            <th className="border border-blue-500 p-2">
                              External
                            </th>
                          )}

                          {this.renderCiewSeew()}

                          <th className="border border-blue-500 p-2">
                            Total Attainment <br /> Through DA (100%)
                          </th>
                        </tr>

                        {this.renderCoAtt()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.toggleButtons("scrollTb") && (
                  <span className="flex justify-end">
                    <span
                      onClick={this.scrollTbClickedLeft}
                      className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                    <span
                      onClick={this.scrollTbClicked}
                      className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                  </span>
                )}
              </div>

              <div className={styles["bg-containers"]}>
                <p className={styles["art-matrix-title"]}>
                  Overall Attainment Of Course Outcomes (Co) Through Direct And
                  Indirect Assessment Tools:
                </p>

                <div className={styles.table}>
                  <div
                    id="oAscrollTb"
                    className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                  >
                    <table className="text-center border border-blue-500">
                      <tbody>
                        <tr
                          className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                        >
                          <th className="border border-blue-500 p-2">
                            Course Outcome
                          </th>
                          <th className="border border-blue-500 p-2">
                            CO Attainment through Direct Assessment(DA)
                          </th>
                          {this.renderDAw()}

                          <th className="border border-blue-500 p-2">
                            CO Attainment through Indirect Assessment(IA)
                          </th>
                          {this.renderIAw()}

                          <th className="border border-blue-500 p-2">
                            Total CO Attainment
                          </th>
                          <th className="border border-blue-500 p-2">Target</th>
                          <th className="border border-blue-500 p-2">
                            Attainment Level
                          </th>
                          <th className="border border-blue-500 p-2">
                            Attainment Achieved
                          </th>
                        </tr>

                        {this.renderOverAllAtt()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.toggleButtons("oAscrollTb") && (
                  <span className="flex justify-end">
                    <span
                      onClick={this.oAscrollTbClickedLeft}
                      className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                    <span
                      onClick={this.oAscrollTbClicked}
                      className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                  </span>
                )}
              </div>
            </>
          );
        case false:
          return this.renderEmptyView();
        case "loading":
          return (
            <div className="flex justify-center items-center mt-20 mb-10">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return this.renderScreen();
  }
}

export default CoAttainmentTable;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const AcademicPerformanceImprovement = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [academicData, setAcademicData] = useState({
    api1stYearCaym1: "",
    api1stYearCaym2: "",
    api1stYearCaym3: "",
    api2ndYearCaym1: "",
    api2ndYearCaym2: "",
    api2ndYearCaym3: "",
    api3rdYearCaym1: "",
    api3rdYearCaym2: "",
    api3rdYearCaym3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        api1stYearCaym1,
        api1stYearCaym2,
        api1stYearCaym3,
        api2ndYearCaym1,
        api2ndYearCaym2,
        api2ndYearCaym3,
        api3rdYearCaym1,
        api3rdYearCaym2,
        api3rdYearCaym3,
      } = academicData;

      const reqBody = {
        academicPerformanceImprovementId:
          metricInfo?.[template]?.[0]?.[
            "academic_performance_improvement_id"
          ] ?? "",
        api1stYearCaym1,
        api1stYearCaym2,
        api1stYearCaym3,
        api2ndYearCaym1,
        api2ndYearCaym2,
        api2ndYearCaym3,
        api3rdYearCaym1,
        api3rdYearCaym2,
        api3rdYearCaym3,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateAcademicData = (event) => {
    setAcademicData({
      ...academicData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const data = {
      api1stYearCaym1: metricInfo?.[template]?.[0]?.["api_1st_caym1"] ?? "",
      api1stYearCaym2: metricInfo?.[template]?.[0]?.["api_1st_caym2"] ?? "",
      api1stYearCaym3: metricInfo?.[template]?.[0]?.["api_1st_caym3"] ?? "",
      api2ndYearCaym1: metricInfo?.[template]?.[0]?.["api_2nd_caym1"] ?? "",
      api2ndYearCaym2: metricInfo?.[template]?.[0]?.["api_2nd_caym2"] ?? "",
      api2ndYearCaym3: metricInfo?.[template]?.[0]?.["api_2nd_caym3"] ?? "",
      api3rdYearCaym1: metricInfo?.[template]?.[0]?.["api_3rd_caym1"] ?? "",
      api3rdYearCaym2: metricInfo?.[template]?.[0]?.["api_3rd_caym2"] ?? "",
      api3rdYearCaym3: metricInfo?.[template]?.[0]?.["api_3rd_caym3"] ?? "",
    };

    setAcademicData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col ">
      <table className={styles["academic-improvement-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
            <th>CAYm3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Academic Performance Index (API) of the First Year Students in the
              Program
              <br />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api1stYearCaym1}
                onChange={updateAcademicData}
                name="api1stYearCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api1stYearCaym2}
                onChange={updateAcademicData}
                name="api1stYearCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api1stYearCaym3}
                onChange={updateAcademicData}
                name="api1stYearCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              Academic Performance Index (API) of the Second Year Students in
              the Program
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api2ndYearCaym1}
                onChange={updateAcademicData}
                name="api2ndYearCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api2ndYearCaym2}
                onChange={updateAcademicData}
                name="api2ndYearCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api2ndYearCaym3}
                onChange={updateAcademicData}
                name="api2ndYearCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              Academic Performance Index (API) of the Third Year Students in the
              Program
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api3rdYearCaym1}
                onChange={updateAcademicData}
                name="api3rdYearCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api3rdYearCaym2}
                onChange={updateAcademicData}
                name="api3rdYearCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                value={academicData.api3rdYearCaym3}
                onChange={updateAcademicData}
                name="api3rdYearCaym3"
                required
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" className={styles["save-btn"]}>
        Save
      </button>
    </form>
  );
};

export default AcademicPerformanceImprovement;

import React, { Component } from "react";
import styles from "./index.module.css";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import { FaFileExport } from "react-icons/fa";
import * as jose from "jose";
import roles from "../../../../roles";
import Input from "../Templates/Input";
import Statement from "../Templates/Statement";
import MissionPeoMatrix from "../Templates/MissionPeoMatrix";
import CurriculumStructureTable from "../Templates/CurriculumStructureTable";
import CurriculumComponentsTable from "../Templates/CurriculumComponentsTable";
import PoPsoStatements from "../Templates/PoPsoStatements";
import PoPsoCoursesMapping from "../Templates/PoPsoCoursesMapping";
import CourseOutComeTable from "../Templates/CourseOutcomeTable";
import ProgramArtMatrixTable from "../Templates/ProgramArtMatrixTable";
import CourseArticulationMatrix from "../Templates/CourseArticulationMatrix";
import AttainmentsDATable from "../Templates/AttainmentsDATable";
import AttainmentsIDATable from "../Templates/AttainmentsIDATable";
import AttainmentsOverallTable from "../Templates/AttainmentsOverallTable";
import AcademicPerformanceTable from "../Templates/AcademicPerformanceTable";
import PlacementEntrepreneurshipTable from "../Templates/PlacementEntrepreneurshipTable";
import SuccessRateTable from "../Templates/SuccessRateTable";
import StudentEnrolmentRatioTable from "../Templates/StudentEnrolmentRatioTable";
import MarksDistEnrolRatioTable from "../Templates/MarksDistEnrolRatioTable";
import ProfessionalSocietiesClubsList from "../Templates/ProfessionalSocietiesClubsList";
import EventsProgramList from "../Templates/EventsProgramsList";
import StudentProfessionalEventsList from "../Templates/StudentsProfessionalEventsList";
import PublicationsJournalsNewsletters from "../Templates/PublicationJournalsNewsletters";
import StudentPublicationsList from "../Templates/StudentPublicationsList";
import FacultyQualificationTable from "../Templates/FacultyQualificationTable";
import FacultyCadreProportion from "../Templates/FacultyCadreProportion";
import ProfessorPracticeTeachingPracticalLoadsTable from "../Templates/ProfessorPracticeTeachingPracticalLoadsTable";
import FacultyRetention from "../Templates/FacultyRetention";
import FacultyMembershipTable from "../Templates/FacultyMembershipTable";
import FacultyResourcePersonsSttps from "../Templates/FacultyResourcePersonsSttps";
import DepartmentOrganizedSttps from "../Templates/DepartmentOrganizedSttp";
import FacultyStudentInnovativeProjects from "../Templates/FacultyStudentInnovativeProjects";
import FacultyParticipatedPersonsSttps from "../Templates/FacultyParticipatedPersonsSttps";
import FacultyContributionSwayam from "../Templates/FacultyContributionSwayam";
import FacultyCertificationSwayam from "../Templates/FacultyCertificationSwayam";
import FacultyTrainingCollaborationDetails from "../Templates/FacultyTrainingCollaborationDetails";
import FacultyPublicationDetails from "../Templates/FacultyPublicationDetails";
import PhdStudentDetails from "../Templates/phdStudentDetails";
import SponsoredResearchConsultancyWork from "../Templates/SponsoredResearchConsultancyWork";
import SeedMoneyResearchGrant from "../Templates/SeedMoneyResearchGrant";
import LabsTechnicalManPower from "../Templates/LabsTechnicalManPower";
import LabsAdditionalFacilities from "../Templates/LabsAdditionalFacilities";
import LabsSafetyMeasures from "../Templates/LabsSafetyMeasures";
import LabsList from "../Templates/LabsList";
import FacultyQualificationImprovement from "../Templates/FacultyQualificationImprovement";
import AcademicPerformanceImprovement from "../Templates/AcademicPerformanceImprovement";
import StdFacRatio1stYear from "../Templates/StdFacRatio1stYear";
import BudgetExpenditureSummaryInstituteLvl from "../Templates/BudgetExpenditureSummaryInstituteLvl";
import BudgetExpenditureItemsInstituteLvl from "../Templates/BudgetExpenditureItemsInstituteLvl";
import BudgetExpenditureSummaryProgramLvl from "../Templates/BudgetExpenditureSummaryProgramLvl";
import BudgetExpenditureItemsProgramLvl from "../Templates/BudgetExpenditureItemsProgramLvl";
import StudentsAdmittedLateralEntry from "../Templates/StudentsAdmittedLateralEntry";
import StudentFacultyRatio from "../Templates/StudentFacultyRatio";

import htmlDocx from "html-docx-js/dist/html-docx";

const screenView = {
  loading: "loading",
  success: "success",
  dataUploaded: "uploaded",
  noData: "empty",
};

const templateNames = {
  input: "input",
  missionStatement: "mission-statement",
  peoStatement: "peo-statement",
  missionPeoMatrix: "mission-peo-matrix-table",
  curriculumStructureTable: "curriculum-structure-table",
  curriculumComponentsTable: "curriculum-components-table",
  poStatement: "po-statement",
  psoStatement: "pso-statement",
  poPsoCoursesListMappingTable: "po-pso-courses-list-mapping-table",
  courseOutcomeTable: "course-outcome-table",
  courseArtMatrixTable: "course-articulation-matrix-table",
  programArtMatrixTable: "program-articulation-matrix-table",
  poPsoAttainmentDaTable: "po-pso-attainment-da-table",
  poPsoAttainmentIdaTable: "po-pso-attainment-ida-table",
  poPsoAttainmentOverallTable: "po-pso-attainment-overall-table",
  academicPerformance1stYear: "academic-performance-1st-year",
  academicPerformance2ndYear: "academic-performance-2nd-year",
  academicPerformance3rdYear: "academic-performance-3rd-year",
  placementHigherStudiesEntrepreneurship:
    "placement-higher-studies-entrepreneurship",
  successRateStipulatedPeriod: "success-rate-stipulated-period",
  studentEnrolmentRatio: "student-enrolment-ratio",
  marksDistributionEnrolmentRatio: "marks-distribution-enrolment-ratio",
  professionalSocietiesClubsList: "professional-societies-clubs-list",
  eventsProgramsList: "events-programs-list",
  studentsProfessionalEventsList: "students-professional-events-list",
  publicationJournalsNewsletters: "publication-journals-newsletters",
  studentPublicationsList: "student-publications-list",
  facultyQualificationTable: "faculty-qualification-table",
  facultyCadreProportion: "faculty-cadre-proportion",
  professorPracticeTeachingPracticalLoadsTable:
    "professor-practice-teaching-practical-loads-table",
  facultyRetention: "faculty-retention-table",
  facultyMembershipTable: "faculty-membership-table",
  facultyResourcePersonsSttps: "faculty-resource-persons-sttps",
  departmentOrganizedSttps: "department-organized-sttps",
  facultyStudentInnovativeProjects: "faculty-student-innovative-projects",
  facultyParticipatedPersonsSttps: "faculty-participated-persons-sttps",
  facultyContributionSwayam: "faculty-contribution-swayam",
  facultyCertificationSwayam: "faculty-certification-swayam",
  facultyTrainingCollaborationDetails: "faculty-training-collaboration-details",
  facultyPublicationDetails: "faculty-publication-details",
  phdStudentDetails: "phd-student-details",
  sponsoredResearchConsultancyWork: "sponsored-research-consultancy-work",
  seedMoneyResearchGrant: "seed-money-research-grant",
  labsTechnicalManPower: "labs-technical-man-power",
  labsAdditionalFacilities: "labs-additional-facilities",
  labsSafetyMeasures: "labs-safety-measures",
  labsList: "labs-list",
  facultyQualificationImprovement: "faculty-qualification-improvement",
  academicPerformanceImprovement: "academic-performance-improvement",
  stdFacRatio1stYear: "std-fac-ratio-1st-year",
  budgetExpenditureSummaryInstituteLvl:
    "budget-expenditure-summary-institute-lvl",
  budgetExpenditureItemsInstituteLvl: "budget-expenditure-items-institute-lvl",
  budgetExpenditureSummaryProgramLvl: "budget-expenditure-summary-program-lvl",
  budgetExpenditureItemsProgramLvl: "budget-expenditure-items-program-lvl",
  studentsAdmittedLateralEntry: "students-admitted-lateral-entry",
  studentFacultyRatio: "student-faculty-ratio",
};

const metricStatus = {
  1: { content: "NOT STARTED", bg: "bg-[#F6F5FF]" },
  2: { content: "IN DRAFT", bg: "bg-[#F1F8FE]" },
  3: { content: "SUBMITTED", bg: "bg-[#FFF7EE]" },
  4: { content: "REJECTED", bg: "bg-[#FBE3E3]" },
  5: { content: "APPROVED", bg: "bg-[#E0FFD9]" },
};

class MetricsView extends Component {
  state = {
    assignedMetric: {},
    screen: screenView.loading,
    metricInfo: {},
    metricNum: [],
    subMetricNum: {},
    activeMainMetric: "",
    activeMetric: "",
    activeQuestion: {},
    statusCode: "",
    loader: false,
    user: {},
  };

  containerRef = React.createRef();

  htmlContentRef = React.createRef();

  scrollToItem = (id) => {
    const itemElement = document.getElementById(id);
    if (itemElement && this.containerRef.current) {
      const itemRect = itemElement.getBoundingClientRect();
      const containerRect = this.containerRef.current.getBoundingClientRect();

      const offset =
        itemRect.left -
        containerRect.left +
        itemElement.offsetWidth / 2 -
        containerRect.width / 2;

      this.containerRef.current.scrollTo({
        left: offset,
        behavior: "smooth",
      });
    }
  };

  componentDidMount() {
    this.fetchAssignedMetric();
    this.fetchMetricData();

    const token = Cookies.get("jwt_token");

    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      this.setState({ user: claim });
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;
    const { activeMainMetric } = this.state;

    if (metricsId !== prevProps.match.params.metricsId) {
      this.fetchAssignedMetric();
      this.fetchMetricData();
    }

    this.scrollToItem(activeMainMetric);
  }

  fetchAssignedMetric = async () => {
    const { match } = this.props;
    const { params } = match;
    const { projectId, metricsId } = params;

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    this.setState({
      assignedMetric: {},
      metricNum: [],
      subMetricNum: {},
      activeMainMetric: "",
      activeMetric: "",
      activeQuestion: {},
    });

    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_ASSIGNED +
          `/${projectId}`,
        options
      );

      if (response.ok) {
        const res = await response.json();
        const { data } = res;

        const sortedData = data.sort((a, b) => a.metrics_id - b.metrics_id);

        let sub = {};

        const mainMetrics = sortedData.map((each) => {
          const splitted = each.metric_no.split(".");

          if (`${each.metrics_id}` === `${metricsId}`) {
            this.setState({
              activeMainMetric: splitted.slice(0, 2).join("."),
              activeMetric: each.metric_no,
              activeQuestion: each,
            });
          }

          sub[`${splitted.slice(0, 2).join(".")}`] = (
            sub[`${splitted.slice(0, 2).join(".")}`] +
            "," +
            each.metric_no
          )
            .replace("undefined", "")
            .replace(/^,+|,+$/g, "");

          return splitted.slice(0, 2).join(".");
        });

        this.setState({
          assignedMetric: res,
          subMetricNum: sub,
          metricNum: [...new Set(mainMetrics)],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchMetricData = async () => {
    this.setState({
      screen: screenView.loading,
      metricInfo: {},
      statusCode: "",
    });

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA + `/${metricsId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();

        this.setState({ screen: screenView.success, metricInfo: data });
      } else {
        if (response.status === 303) {
          const data = await response.json();

          this.setState({
            metricInfo: data,
            screen: screenView.success,
            statusCode: "303",
          });
        } else {
          this.setState({ screen: screenView.failed });
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ screen: screenView.failed });
    }
  };

  mainMetricChanged = (event) => {
    const { assignedMetric, subMetricNum } = this.state;
    const { data } = assignedMetric;
    const { history } = this.props;

    const subMetric = subMetricNum[event.target.id].split(",")[0];

    const metricIdData = data.filter((each) => each.metric_no === subMetric);
    history.push(
      `/connect/dashboard/metrics-data/${metricIdData[0]["project_id"]}/${metricIdData[0]["metrics_id"]}`
    );
  };

  subMetricChanged = (event) => {
    const { assignedMetric } = this.state;
    const { data } = assignedMetric;
    const { history } = this.props;

    const metricIdData = data.filter(
      (each) => each.metric_no === event.target.id
    );

    history.push(
      `/connect/dashboard/metrics-data/${metricIdData[0]["project_id"]}/${metricIdData[0]["metrics_id"]}`
    );
  };

  renderMainMetric = () => {
    const { metricNum, activeMainMetric } = this.state;

    return metricNum.map((each) => (
      <li
        key={each}
        id={each}
        onClick={this.mainMetricChanged}
        className={
          each === activeMainMetric
            ? "bg-[#456BF1] text-[#fff]"
            : "text-[#606981]"
        }
      >
        {each}
      </li>
    ));
  };

  renderSubMetric = () => {
    const { subMetricNum, activeMetric, activeMainMetric } = this.state;

    const data = subMetricNum[activeMainMetric]?.split(",");

    return data?.map((each) => (
      <li
        key={each}
        onClick={this.subMetricChanged}
        id={each}
        className={
          each === activeMetric
            ? "underline underline-offset-8 text-[#040C58] font-extrabold decoration-2"
            : "text-[#464D90] font-bold"
        }
      >
        {each}
      </li>
    ));
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-20 mb-5">
      <p className={styles["empty-view"]}>Something went wrong.</p>
    </div>
  );

  renderNoDataView = () => (
    <div className="flex justify-center items-center p-10 mt-20 mb-5">
      <p className={styles["empty-view"]}>No Data Found</p>
    </div>
  );

  renderUploadedView = () => (
    <div className="flex flex-col justify-center items-center p-10 mt-20 mb-5">
      <img
        src="/assets/tick.svg"
        width={35}
        height={35}
        alt="done"
        className="mb-5"
      />
      <p className={styles["uploaded-view"]}>
        Metric data uploaded Successfully!
      </p>
      <button onClick={this.okBtn} type="button" className={styles["ok-btn"]}>
        Ok
      </button>
    </div>
  );

  uploadedStatus = (status = null) => {
    this.setState({
      screen: status === null ? screenView.dataUploaded : status,
    });
  };

  statusChangeBtn = async (event) => {
    this.setState({ loader: true });
    const val = event.target.name;

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    const options = {
      method: "PUT",
      headers,
      body: JSON.stringify({
        data: {
          status: val,
        },
      }),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA_STATUS_UPDATE +
          `/${metricsId}`,
        options
      );

      if (response.ok) {
        this.fetchAssignedMetric();
        this.fetchMetricData();
      } else {
        alert("Status update failed");
      }
    } catch (err) {
      alert("Status update failed");
      console.log(err);
    }

    this.setState({ loader: false });
  };

  okBtn = () => {
    this.fetchAssignedMetric();
    this.fetchMetricData();
  };

  renderView = (template) => {
    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    const { metricInfo, activeQuestion, statusCode } = this.state;

    switch (template) {
      case templateNames.input:
        return (
          <Input
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]?.[0]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.missionStatement:
        return (
          <Statement
            templateNames={templateNames}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.peoStatement:
        return (
          <Statement
            templateNames={templateNames}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.missionPeoMatrix:
        return (
          <MissionPeoMatrix
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.curriculumStructureTable:
        return (
          <CurriculumStructureTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.curriculumComponentsTable:
        return (
          <CurriculumComponentsTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.poStatement:
        return (
          <PoPsoStatements
            template={template}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.psoStatement:
        return (
          <PoPsoStatements
            template={template}
            branch={activeQuestion?.["branch"]}
            uploadedStatus={this.uploadedStatus}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.poPsoCoursesListMappingTable:
        return (
          <PoPsoCoursesMapping
            template={template}
            branch={activeQuestion?.["branch"]}
            academicYear={activeQuestion?.["academic_year"]}
            uploadedStatus={this.uploadedStatus}
            statusCode={statusCode}
            metricsId={metricsId}
            metricInfo={metricInfo}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.courseOutcomeTable:
        return (
          <CourseOutComeTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
            branch={activeQuestion?.["branch"]}
            academicYear={activeQuestion?.["academic_year"]}
            statusCode={statusCode}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.courseArtMatrixTable:
        return (
          <CourseArticulationMatrix
            template={template}
            branch={activeQuestion?.["branch"]}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
            academicYear={activeQuestion?.["academic_year"]}
            statusCode={statusCode}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.programArtMatrixTable:
        return (
          <ProgramArtMatrixTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            branch={activeQuestion?.["branch"]}
            academicYear={activeQuestion?.["academic_year"]}
            uploadedStatus={this.uploadedStatus}
            statusCode={statusCode}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.poPsoAttainmentDaTable:
        return (
          <AttainmentsDATable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            branch={activeQuestion?.["branch"]}
            academicYear={activeQuestion?.["academic_year"]}
            uploadedStatus={this.uploadedStatus}
            statusCode={statusCode}
            statusChangeBtn={this.statusChangeBtn}
          />
        );

      case templateNames.poPsoAttainmentIdaTable:
        return (
          <AttainmentsIDATable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            branch={activeQuestion?.["branch"]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.poPsoAttainmentOverallTable:
        return (
          <AttainmentsOverallTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            branch={activeQuestion?.["branch"]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.studentEnrolmentRatio:
        return (
          <StudentEnrolmentRatioTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.marksDistributionEnrolmentRatio:
        return (
          <MarksDistEnrolRatioTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.successRateStipulatedPeriod:
        return (
          <SuccessRateTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.academicPerformance1stYear:
        return (
          <AcademicPerformanceTable
            year={"1st"}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.academicPerformance2ndYear:
        return (
          <AcademicPerformanceTable
            year={"2nd"}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.academicPerformance3rdYear:
        return (
          <AcademicPerformanceTable
            year={"3rd"}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.placementHigherStudiesEntrepreneurship:
        return (
          <PlacementEntrepreneurshipTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.professionalSocietiesClubsList:
        return (
          <ProfessionalSocietiesClubsList
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.eventsProgramsList:
        return (
          <EventsProgramList
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.studentsProfessionalEventsList:
        return (
          <StudentProfessionalEventsList
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.publicationJournalsNewsletters:
        return (
          <PublicationsJournalsNewsletters
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.studentPublicationsList:
        return (
          <StudentPublicationsList
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.facultyQualificationTable:
        return (
          <FacultyQualificationTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyCadreProportion:
        return (
          <FacultyCadreProportion
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.professorPracticeTeachingPracticalLoadsTable:
        return (
          <ProfessorPracticeTeachingPracticalLoadsTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyRetention:
        return (
          <FacultyRetention
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyMembershipTable:
        return (
          <FacultyMembershipTable
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyResourcePersonsSttps:
        return (
          <FacultyResourcePersonsSttps
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.departmentOrganizedSttps:
        return (
          <DepartmentOrganizedSttps
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyStudentInnovativeProjects:
        return (
          <FacultyStudentInnovativeProjects
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyParticipatedPersonsSttps:
        return (
          <FacultyParticipatedPersonsSttps
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyContributionSwayam:
        return (
          <FacultyContributionSwayam
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyCertificationSwayam:
        return (
          <FacultyCertificationSwayam
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyTrainingCollaborationDetails:
        return (
          <FacultyTrainingCollaborationDetails
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyPublicationDetails:
        return (
          <FacultyPublicationDetails
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.phdStudentDetails:
        return (
          <PhdStudentDetails
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.sponsoredResearchConsultancyWork:
        return (
          <SponsoredResearchConsultancyWork
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.seedMoneyResearchGrant:
        return (
          <SeedMoneyResearchGrant
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.labsTechnicalManPower:
        return (
          <LabsTechnicalManPower
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.labsAdditionalFacilities:
        return (
          <LabsAdditionalFacilities
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.labsSafetyMeasures:
        return (
          <LabsSafetyMeasures
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.labsList:
        return (
          <LabsList
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.facultyQualificationImprovement:
        return (
          <FacultyQualificationImprovement
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.academicPerformanceImprovement:
        return (
          <AcademicPerformanceImprovement
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.stdFacRatio1stYear:
        return (
          <StdFacRatio1stYear
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.budgetExpenditureSummaryInstituteLvl:
        return (
          <BudgetExpenditureSummaryInstituteLvl
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.budgetExpenditureItemsInstituteLvl:
        return (
          <BudgetExpenditureItemsInstituteLvl
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.budgetExpenditureSummaryProgramLvl:
        return (
          <BudgetExpenditureSummaryProgramLvl
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.budgetExpenditureItemsProgramLvl:
        return (
          <BudgetExpenditureItemsProgramLvl
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.studentsAdmittedLateralEntry:
        return (
          <StudentsAdmittedLateralEntry
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      case templateNames.studentFacultyRatio:
        return (
          <StudentFacultyRatio
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );
      default:
        return "";
    }
  };

  btnLoader = () => (
    <div className="flex justify-center w-full">
      <Oval
        height={25}
        width={25}
        color="#fff"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  renderStatusBtns = () => {
    const { loader, user, activeQuestion } = this.state;

    const approversList = activeQuestion?.["approvers"]?.split(",");
    const isApprover = approversList?.includes(user?.["sub"]);

    const authorsList = activeQuestion?.["authors"]?.split(",");
    const isAuthor = authorsList?.includes(user?.["sub"]);

    return (
      <span className="flex items-center self-end mb-2">
        {(user?.["user_type"] === roles.principal ||
          user?.["user_type"] === roles.hod ||
          isApprover) &&
          activeQuestion?.status === 3 && (
            <>
              <button
                name={4}
                disabled={loader}
                onClick={this.statusChangeBtn}
                type="button"
                className={`${styles["reject-btn"]} ${
                  loader && "cursor-not-allowed"
                }`}
              >
                {loader ? this.btnLoader() : "Reject"}
              </button>

              <button
                name={5}
                disabled={loader}
                onClick={this.statusChangeBtn}
                type="button"
                className={`${styles["approve-btn"]} ${
                  loader && "cursor-not-allowed"
                }`}
              >
                {loader ? this.btnLoader() : "Approve"}
              </button>
            </>
          )}

        {(user?.["user_type"] === roles.principal ||
          user?.["user_type"] === roles.hod ||
          isAuthor) &&
          activeQuestion?.status === 2 && (
            <button
              name={3}
              disabled={loader}
              onClick={this.statusChangeBtn}
              type="button"
              className={`${styles["submit-btn"]} ${
                loader && "cursor-not-allowed"
              }`}
            >
              {loader ? this.btnLoader() : "Submit"}
            </button>
          )}
      </span>
    );
  };

  renderMetricScreen = () => {
    const { screen, metricInfo } = this.state;

    switch (screen) {
      case screenView.loading:
        return (
          <div className="absolute top-52 flex justify-center w-full">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case screenView.success:
        return (
          <span className="flex flex-col">
            {metricInfo?.templateNames?.map((each) => (
              <span key={each}>{this.renderView(each)}</span>
            ))}
            {this.renderStatusBtns()}
          </span>
        );

      case screenView.failed:
        return this.renderEmptyView();

      case screenView.dataUploaded:
        return this.renderUploadedView();

      case screenView.noData:
        return this.renderNoDataView();
      default:
        break;
    }
  };

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  scrollTbClickedLeft = () => {
    const container = document.getElementById("metric-ul");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  scrollTbClicked = () => {
    const container = document.getElementById("metric-ul");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  handleDocxDownload = () => {
    const { metricInfo, activeMetric, activeQuestion } = this.state;

    const htmlContent = this.htmlContentRef.current.innerHTML;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const editorElements = doc.querySelectorAll(
      ".sun-editor, .sun-editor-toolbar"
    );

    editorElements.forEach((element) => {
      element.remove(); // Remove the element from the DOM
    });

    const labels = doc.querySelectorAll("label");
    labels.forEach((label) => label.remove());

    const selectElements = doc.querySelectorAll("select");

    selectElements.forEach((selectElement) => {
      const nameAttribute = selectElement.getAttribute("data-name");

      let pElement = document.createElement("p");

      if (nameAttribute) {
        const selectedOption = Array.from(selectElement.options).find(
          (option) => option.value === nameAttribute
        );

        if (selectedOption) {
          pElement.textContent = `${selectedOption.value}`;
        } else {
          pElement.textContent = ``;
        }
      } else {
        pElement.textContent = "";
      }

      selectElement.replaceWith(pElement);
    });

    const imagesToRemove = doc.querySelectorAll(
      'img[src="/assets/floatarrow.svg"]'
    );
    imagesToRemove.forEach((img) => img.remove());

    const cleanHtml = doc.body.innerHTML;

    let contentWithValues = cleanHtml.replace(
      /<input[^>]+value="([^"]+)"\s*\/?>/g,
      (match, value) => {
        return `<p>${value}</p>`;
      }
    );

    let contentWithoutButtons = contentWithValues.replace(
      /<button[^>]*>[\s\S]*?<\/button>/g,
      ""
    );

    let inlineStyledContent = `
      <html>
        <head>
          <style>
           table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f4f4f4; }
          </style>
        </head>
        <body>
        <p style="font-weight: bold; font-size:16px;">
                  ${activeMetric}) 
                  ${activeQuestion?.metric_statement}
                </p>
        ${metricInfo?.["input"]?.[0]?.["data"] ?? ""}
        ${contentWithoutButtons}</body>
      </html>
    `;

    const docxFile = htmlDocx.asBlob(inlineStyledContent, {
      orientation: "landscape",
      margins: { top: 720 },
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(docxFile);
    link.download = `${activeQuestion?.["name"]} ${activeMetric}-document.docx`;
    link.click();
  };

  render() {
    const { activeMetric, activeQuestion, screen } = this.state;

    return (
      <div className={styles["dashboard-container"]}>
        {screen !== screenView.loading && (
          <>
            <div className="flex items-center ml-9">
              <span
                onClick={this.scrollTbClickedLeft}
                className={`${styles["floatarr"]} absolute left-5 mb-1 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
              >
                <img
                  src="/assets/floatarrow.svg"
                  alt="arrow"
                  width={10}
                  height={10}
                />
              </span>

              <ul
                ref={this.containerRef}
                id="metric-ul"
                className={styles["metric-ul"]}
              >
                {this.renderMainMetric()}
              </ul>

              <span
                onClick={this.scrollTbClicked}
                className="absolute right-3 mb-1 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
              >
                <img
                  src="/assets/floatarrow.svg"
                  alt="arrow"
                  width={10}
                  height={10}
                />
              </span>
            </div>

            <ul className={styles["sub-metric-ul"]}>
              {this.renderSubMetric()}
            </ul>

            <div className="ml-9 flex justify-between pr-8 items-center mb-3 w-full">
              <span className="flex items-center w-10/12">
                <p className={styles["metric-qstn"]}>
                  {activeMetric}
                  {screen !== screenView.loading &&
                    activeQuestion?.metric_statement &&
                    ")"}{" "}
                  {activeQuestion?.metric_statement}
                </p>
                {screen !== screenView.loading && activeQuestion?.weightage && (
                  <p className={styles["qstn-weightage"]}>
                    {activeQuestion?.weightage} Marks
                  </p>
                )}
              </span>

              {screen !== screenView.loading && (
                <p
                  className={`${styles["metric-status"]} ${
                    metricStatus?.[activeQuestion?.status]?.bg
                  }`}
                >
                  {metricStatus?.[activeQuestion?.status]?.content}
                </p>
              )}

              {screen === screenView.success && (
                <button
                  className={styles["export-btn"]}
                  onClick={this.handleDocxDownload}
                >
                  <FaFileExport />
                </button>
              )}
            </div>
          </>
        )}
        <span ref={this.htmlContentRef} className="ml-6">
          {this.renderMetricScreen()}
        </span>
      </div>
    );
  }
}

export default withRouter(MetricsView);

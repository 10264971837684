import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const PhdStudentDetails = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [phdStudentData, setPhdStudentData] = useState({
    enrolledStudentsM1: "",
    enrolledStudentsM2: "",
    enrolledStudentsM3: "",
    graduatedStudentsCayM1: "",
    graduatedStudentsCayM2: "",
    graduatedStudentsCayM3: "",
  });

  const updatePhdStudentData = (event) => {
    setPhdStudentData({
      ...phdStudentData,
      [event.target.name]: event.target.value,
    });
  };

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        enrolledStudentsM1,
        enrolledStudentsM2,
        enrolledStudentsM3,
        graduatedStudentsCayM1,
        graduatedStudentsCayM2,
        graduatedStudentsCayM3,
      } = phdStudentData;

      const reqBody = {
        phdStudentDetailsId:
          metricInfo?.[template]?.[0]?.["phd_student_details_id"] ?? "",
        enrolledStudentsCaym1: enrolledStudentsM1,
        enrolledStudentsCaym2: enrolledStudentsM2,
        enrolledStudentsCaym3: enrolledStudentsM3,
        graduatedStudentsCaym1: graduatedStudentsCayM1,
        graduatedStudentsCaym2: graduatedStudentsCayM2,
        graduatedStudentsCaym3: graduatedStudentsCayM3,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  useEffect(() => {
    const data = {
      enrolledStudentsM1:
        metricInfo?.[template]?.[0]?.["enrolled_students_caym1"] ?? "",
      enrolledStudentsM2:
        metricInfo?.[template]?.[0]?.["enrolled_students_caym2"] ?? "",
      enrolledStudentsM3:
        metricInfo?.[template]?.[0]?.["enrolled_students_caym3"] ?? "",
      graduatedStudentsCayM1:
        metricInfo?.[template]?.[0]?.["graduated_students_caym1"] ?? "",
      graduatedStudentsCayM2:
        metricInfo?.[template]?.[0]?.["graduated_students_caym2"] ?? "",
      graduatedStudentsCayM3:
        metricInfo?.[template]?.[0]?.["graduated_students_caym3"] ?? "",
    };

    setPhdStudentData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["phd-student-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAY</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No. of students enrolled for Ph.D. in the Department</td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.enrolledStudentsM1}
                onChange={updatePhdStudentData}
                name="enrolledStudentsM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.enrolledStudentsM2}
                onChange={updatePhdStudentData}
                name="enrolledStudentsM2"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.enrolledStudentsM3}
                onChange={updatePhdStudentData}
                name="enrolledStudentsM3"
              />
            </td>
          </tr>

          <tr>
            <td>No. of Ph.D. students graduated in the Department</td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.graduatedStudentsCayM1}
                onChange={updatePhdStudentData}
                name="graduatedStudentsCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.graduatedStudentsCayM2}
                onChange={updatePhdStudentData}
                name="graduatedStudentsCayM2"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={phdStudentData?.graduatedStudentsCayM3}
                onChange={updatePhdStudentData}
                name="graduatedStudentsCayM3"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default PhdStudentDetails;

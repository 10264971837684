import MetricAssignOverlay from "../MetricAssignOverlay";
import CriteriaList from "../CriteriaList";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import roles from "../../../../roles";
import * as jose from "jose";

const Criteria = (props) => {
  const [isOpened, updatedOpened] = useState(false);
  const [criteria, updateCriteria] = useState([]);
  const [criteriaLoader, updateCriteriaLoader] = useState(false);
  const [userdata, updateUserData] = useState({});
  const [criteriaOptions, updateCriteriaOptions] = useState([]);
  const [tasks, setTasks] = useState({
    totalTasks: 0,
    toDo: 0,
    inProgress: 0,
    done: 0,
    issues: 0,
    submitted: 0,
    weightage: 0,
    projectName: "",
  });

  const { match } = props;
  const { params } = match;
  const { projectId } = params;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const renderStatusCards = () => (
    <ul className={styles["metrics-status-card"]}>
      <li className="bg-[#F6F5FF]">
        <h2>NOT STARTED</h2>
        <h1>
          {tasks.toDo}{" "}
          <span>
            {tasks.toDo && Math.round((tasks.toDo / tasks.totalTasks) * 100)}%
          </span>
        </h1>
      </li>

      <li className="bg-[#F1F8FE]">
        <h2>IN DRAFT</h2>
        <h1>
          {tasks.inProgress}{" "}
          <span>
            {tasks.inProgress &&
              Math.round((tasks.inProgress / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#FFF7EE]">
        <h2>SUBMITTED</h2>
        <h1>
          {tasks.submitted}{" "}
          <span>
            {tasks.submitted &&
              Math.round((tasks.submitted / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#FBE3E3]">
        <h2>REJECTED</h2>
        <h1>
          {tasks.issues}{" "}
          <span>
            {tasks.issues &&
              Math.round((tasks.issues / tasks.totalTasks) * 100)}
            %
          </span>
        </h1>
      </li>

      <li className="bg-[#E0FFD9]">
        <h2>APPROVED</h2>
        <h1>
          {tasks.done}{" "}
          <span>
            {tasks.done && Math.round((tasks.done / tasks.totalTasks) * 100)}%
          </span>
        </h1>
      </li>
    </ul>
  );

  const closeBtn = () => {
    updatedOpened(false);
  };

  const openBtn = () => {
    updatedOpened(true);
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const fetchCriteria = async () => {
    updateCriteriaLoader(true);
    updateCriteria([]);
    const options = {
      method: "GET",
      headers,
    };

    try {
      const fetchedResponse = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_CRITERIA + `/${projectId}`,
        options
      );

      if (fetchedResponse.ok) {
        const data = await fetchedResponse.json();

        const taskCardData = {
          totalTasks: 0,
          toDo: 0,
          inProgress: 0,
          done: 0,
          issues: 0,
          submitted: 0,
          weightage: 0,
          projectName: "",
        };

        const updatedData = data.map((each) => {
          taskCardData.totalTasks =
            taskCardData.totalTasks + each["total_tasks"];
          taskCardData.toDo = taskCardData.toDo + each["to_do_tasks"];
          taskCardData.inProgress =
            taskCardData.inProgress + each["in_progress_tasks"];
          taskCardData.done = taskCardData.done + each["tasks_done"];
          taskCardData.issues = taskCardData.issues + each["issues_tasks"];
          taskCardData.submitted =
            taskCardData.submitted + each["submitted_tasks"];
          taskCardData.weightage = taskCardData.weightage + each["weightage"];
          taskCardData.projectName = each["project_name"];

          return {
            id: each.id,
            criteriaNo: each.criteria_no,
            criteriaStatement: each.criteria_statement,
            weightage: each.weightage,
            projectId: each.project_id,
            inProgress: each["in_progress_tasks"],
            submitted: each["submitted_tasks"],
            totalTasks: each["total_tasks"],
            tasksDone: each["tasks_done"],
          };
        });

        updateCriteria(updatedData);

        setTasks(taskCardData);
      } else {
        updateCriteria([]);
      }
    } catch (err) {
      updateCriteria([]);
      console.log(err);
    }

    updateCriteriaLoader(false);
  };

  const getCriteriaOptions = async () => {
    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_CRITERIA_ALL +
          `?projectId=${projectId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateCriteriaOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Criteria Found To View <br /> Metrics should be assigned to view the
        criteria
      </p>
    </div>
  );

  const renderCriteriaView = () => {
    const content = criteriaLoader
      ? "loading"
      : criteria.length === 0
      ? "empty"
      : "content";

    switch (content) {
      case "loading":
        return (
          <div className="absolute top-80 left-2/4">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case "content":
        return (
          <ul className={styles["metrics-list"]}>
            {criteria.map((each) => (
              <CriteriaList
                key={each.criteriaNo}
                item={each}
                projectId={projectId}
              />
            ))}
          </ul>
        );

      case "empty":
        return renderEmptyView();

      default:
        return null;
    }
  };

  const progressStyle = {
    width: `${(tasks.done / tasks.totalTasks) * 100}%`,
  };

  useEffect(() => {
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateUserData(claim);
    }
    fetchCriteria();
    getCriteriaOptions();
  }, []);

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div onClick={preventBubbling} className={styles["overlay-card"]}>
          <MetricAssignOverlay
            projectId={projectId}
            closeBtn={closeBtn}
            fetchCriteria={fetchCriteria}
            criteriaOptions={criteriaOptions}
          />
        </div>
      </div>
      <div className={styles["dashboard-container"]}>
        <div className="flex justify-between items-center w-full pr-14">
          <div className="flex flex-col w-2/4">
            <h1 className={styles["metric-value"]}>
              {tasks.projectName && (
                <>
                  <div className={styles["metric-value-container"]}>
                    {tasks.projectName}
                  </div>
                  : {Math.round((tasks.done / tasks.totalTasks) * 100)}% done (
                  {tasks.weightage} Marks)
                </>
              )}
            </h1>
            <div className={styles["progress-bar-container"]}>
              {tasks.projectName && (
                <div className={styles["progress-bar"]} style={progressStyle}>
                  &nbsp;
                </div>
              )}
            </div>
          </div>

          {/* <div className={styles["profile-dp-container"]}>
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />

            <span className={styles["profile-count"]}>+ 15 people</span>
          </div> */}

          <div className="flex items-center">
            <div className={styles["search-input-container"]}>
              <img src="/assets/search.svg" alt="search" />
              <input
                placeholder="Search"
                type="search"
                className={styles["search-input"]}
              />
            </div>
            {(userdata?.user_type === roles.principal ||
              userdata?.user_type === roles.hod) && (
              <button
                onClick={openBtn}
                type="button"
                className={styles["dotted-plus"]}
              >
                +
              </button>
            )}
          </div>
        </div>

        {renderStatusCards()}

        {renderCriteriaView()}
      </div>
    </>
  );
};

export default withRouter(Criteria);

import styles from "./index.module.css";
import { useState, useEffect } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const categories = [
  { name: "Infrastructure Built-Up", label: "infrastructure" },
  { name: "Library", label: "library" },
  { name: "Laboratory equipment", label: "laboratory" },
  { name: "Teaching and non-teaching staff salary", label: "staff" },
  { name: "Outreach Programs", label: "outreachPrograms" },
  { name: "R&D", label: "rd" },
  {
    name: "Training, Placement and Industry linkage",
    label: "placement",
  },
  { name: "SDGs", label: "sdg" },
  { name: "Entrepreneurship", label: "entrepreneurship" },
  { name: "Others*, pl. specify", label: "others" },
];

const fieldGroups = {
  infrastructure: [
    "infrastructureBudgetCfy",
    "infrastructureExpensesCfy",
    "infrastructureBudgetCfyM1",
    "infrastructureExpensesCfyM1",
    "infrastructureBudgetCfyM2",
    "infrastructureExpensesCfyM2",
    "infrastructureBudgetCfyM3",
    "infrastructureExpensesCfyM3",
  ],
  library: [
    "libraryBudgetCfy",
    "libraryExpensesCfy",
    "libraryBudgetCfyM1",
    "libraryExpensesCfyM1",
    "libraryBudgetCfyM2",
    "libraryExpensesCfyM2",
    "libraryBudgetCfyM3",
    "libraryExpensesCfyM3",
  ],
  laboratory: [
    "labBudgetCfy",
    "labExpensesCfy",
    "labBudgetCfyM1",
    "labExpensesCfyM1",
    "labBudgetCfyM2",
    "labExpensesCfyM2",
    "labBudgetCfyM3",
    "labExpensesCfyM3",
  ],
  staff: [
    "staffBudgetCfy",
    "staffExpensesCfy",
    "staffBudgetCfyM1",
    "staffExpensesCfyM1",
    "staffBudgetCfyM2",
    "staffExpensesCfyM2",
    "staffBudgetCfyM3",
    "staffExpensesCfyM3",
  ],
  outreachPrograms: [
    "outreachProgramsBudgetCfy",
    "outreachProgramsExpensesCfy",
    "outreachProgramsBudgetCfyM1",
    "outreachProgramsExpensesCfyM1",
    "outreachProgramsBudgetCfyM2",
    "outreachProgramsExpensesCfyM2",
    "outreachProgramsBudgetCfyM3",
    "outreachProgramsExpensesCfyM3",
  ],
  rd: [
    "rdBudgetCfy",
    "rdExpensesCfy",
    "rdBudgetCfyM1",
    "rdExpensesCfyM1",
    "rdBudgetCfyM2",
    "rdExpensesCfyM2",
    "rdBudgetCfyM3",
    "rdExpensesCfyM3",
  ],
  placement: [
    "placementBudgetCfy",
    "placementExpensesCfy",
    "placementBudgetCfyM1",
    "placementExpensesCfyM1",
    "placementBudgetCfyM2",
    "placementExpensesCfyM2",
    "placementBudgetCfyM3",
    "placementExpensesCfyM3",
  ],
  sdg: [
    "sdgBudgetCfy",
    "sdgExpensesCfy",
    "sdgBudgetCfyM1",
    "sdgExpensesCfyM1",
    "sdgBudgetCfyM2",
    "sdgExpensesCfyM2",
    "sdgBudgetCfyM3",
    "sdgExpensesCfyM3",
  ],
  entrepreneurship: [
    "entrepreneurshipBudgetCfy",
    "entrepreneurshipExpensesCfy",
    "entrepreneurshipBudgetCfyM1",
    "entrepreneurshipExpensesCfyM1",
    "entrepreneurshipBudgetCfyM2",
    "entrepreneurshipExpensesCfyM2",
    "entrepreneurshipBudgetCfyM3",
    "entrepreneurshipExpensesCfyM3",
  ],
  others: [
    "othersBudgetCfy",
    "othersExpensesCfy",
    "othersBudgetCfyM1",
    "othersExpensesCfyM1",
    "othersBudgetCfyM2",
    "othersExpensesCfyM2",
    "othersBudgetCfyM3",
    "othersExpensesCfyM3",
  ],
};

const BudgetExpenditureItemsInstituteLvl = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [budgetExpenditure, setBudgetExpenditure] = useState({
    infrastructureBudgetCfy: "",
    infrastructureBudgetCfyM1: "",
    infrastructureBudgetCfyM2: "",
    infrastructureBudgetCfyM3: "",
    infrastructureExpensesCfy: "",
    infrastructureExpensesCfyM1: "",
    infrastructureExpensesCfyM2: "",
    infrastructureExpensesCfyM3: "",
    libraryBudgetCfy: "",
    libraryBudgetCfyM1: "",
    libraryBudgetCfyM2: "",
    libraryBudgetCfyM3: "",
    libraryExpensesCfy: "",
    libraryExpensesCfyM1: "",
    libraryExpensesCfyM2: "",
    libraryExpensesCfyM3: "",
    labBudgetCfy: "",
    labBudgetCfyM1: "",
    labBudgetCfyM2: "",
    labBudgetCfyM3: "",
    labExpensesCfy: "",
    labExpensesCfyM1: "",
    labExpensesCfyM2: "",
    labExpensesCfyM3: "",
    staffBudgetCfy: "",
    staffBudgetCfyM1: "",
    staffBudgetCfyM2: "",
    staffBudgetCfyM3: "",
    staffExpensesCfy: "",
    staffExpensesCfyM1: "",
    staffExpensesCfyM2: "",
    staffExpensesCfyM3: "",
    outreachProgramsBudgetCfy: "",
    outreachProgramsBudgetCfyM1: "",
    outreachProgramsBudgetCfyM2: "",
    outreachProgramsBudgetCfyM3: "",
    outreachProgramsExpensesCfy: "",
    outreachProgramsExpensesCfyM1: "",
    outreachProgramsExpensesCfyM2: "",
    outreachProgramsExpensesCfyM3: "",
    rdBudgetCfy: "",
    rdBudgetCfyM1: "",
    rdBudgetCfyM2: "",
    rdBudgetCfyM3: "",
    rdExpensesCfy: "",
    rdExpensesCfyM1: "",
    rdExpensesCfyM2: "",
    rdExpensesCfyM3: "",
    placementBudgetCfy: "",
    placementBudgetCfyM1: "",
    placementBudgetCfyM2: "",
    placementBudgetCfyM3: "",
    placementExpensesCfy: "",
    placementExpensesCfyM1: "",
    placementExpensesCfyM2: "",
    placementExpensesCfyM3: "",
    sdgBudgetCfy: "",
    sdgBudgetCfyM1: "",
    sdgBudgetCfyM2: "",
    sdgBudgetCfyM3: "",
    sdgExpensesCfy: "",
    sdgExpensesCfyM1: "",
    sdgExpensesCfyM2: "",
    sdgExpensesCfyM3: "",
    entrepreneurshipBudgetCfy: "",
    entrepreneurshipBudgetCfyM1: "",
    entrepreneurshipBudgetCfyM2: "",
    entrepreneurshipBudgetCfyM3: "",
    entrepreneurshipExpensesCfy: "",
    entrepreneurshipExpensesCfyM1: "",
    entrepreneurshipExpensesCfyM2: "",
    entrepreneurshipExpensesCfyM3: "",
    othersBudgetCfy: "",
    othersBudgetCfyM1: "",
    othersBudgetCfyM2: "",
    othersBudgetCfyM3: "",
    othersExpensesCfy: "",
    othersExpensesCfyM1: "",
    othersExpensesCfyM2: "",
    othersExpensesCfyM3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const reqBody = {
        budgetExpenditureItemsInstituteLvlId:
          metricInfo?.[template]?.[0]?.[
            "budget_expenditure_items_institute_lvl_id"
          ] ?? "",
        ...budgetExpenditure,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateBudgetExpenditureData = (event) => {
    setBudgetExpenditure({
      ...budgetExpenditure,
      [event.target.name]: event.target.value,
    });
  };

  const totalAmount = () => {
    const columnTotals = Object.keys(fieldGroups).reduce((totals, group) => {
      fieldGroups[group].forEach((field, index) => {
        totals[index] =
          (totals[index] || 0) + (Number(budgetExpenditure[field]) || 0);
      });
      return totals;
    }, []);

    return (
      <tr>
        <td>Total</td>
        {columnTotals.map((total, index) => (
          <td key={index} className={styles["total-col"]}>
            {total.toFixed(2)}
          </td>
        ))}
      </tr>
    );
  };

  const renderStructure = () => {
    return categories.map(({ name, label }) => (
      <tr>
        <td>{name}</td>
        {fieldGroups[label].map((fields) => (
          <td className={styles["input-col"]}>
            <input
              value={budgetExpenditure[fields]}
              onChange={updateBudgetExpenditureData}
              name={fields}
              min={0}
              step={0.01}
              type="number"
              required
            />
          </td>
        ))}
      </tr>
    ));
  };

  useEffect(() => {
    const data = {
      infrastructureBudgetCfy:
        metricInfo?.[template]?.[0]?.["infrastructure_budget_cfy"] ?? "",
      infrastructureBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["infrastructure_budget_cfym1"] ?? "",
      infrastructureBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["infrastructure_budget_cfym2"] ?? "",
      infrastructureBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["infrastructure_budget_cfym3"] ?? "",

      infrastructureExpensesCfy:
        metricInfo?.[template]?.[0]?.["infrastructure_expenses_cfy"] ?? "",
      infrastructureExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["infrastructure_expenses_cfym1"] ?? "",
      infrastructureExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["infrastructure_expenses_cfym2"] ?? "",
      infrastructureExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["infrastructure_expenses_cfym3"] ?? "",

      libraryBudgetCfy:
        metricInfo?.[template]?.[0]?.["library_budget_cfy"] ?? "",
      libraryBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["library_budget_cfym1"] ?? "",
      libraryBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["library_budget_cfym2"] ?? "",
      libraryBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["library_budget_cfym3"] ?? "",

      libraryExpensesCfy:
        metricInfo?.[template]?.[0]?.["library_expenses_cfy"] ?? "",
      libraryExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["library_expenses_cfym1"] ?? "",
      libraryExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["library_expenses_cfym2"] ?? "",
      libraryExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["library_expenses_cfym3"] ?? "",

      labBudgetCfy: metricInfo?.[template]?.[0]?.["lab_budget_cfy"] ?? "",
      labBudgetCfyM1: metricInfo?.[template]?.[0]?.["lab_budget_cfym1"] ?? "",
      labBudgetCfyM2: metricInfo?.[template]?.[0]?.["lab_budget_cfym2"] ?? "",
      labBudgetCfyM3: metricInfo?.[template]?.[0]?.["lab_budget_cfym3"] ?? "",

      labExpensesCfy: metricInfo?.[template]?.[0]?.["lab_expenses_cfy"] ?? "",
      labExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym1"] ?? "",
      labExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym2"] ?? "",
      labExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym3"] ?? "",

      staffBudgetCfy: metricInfo?.[template]?.[0]?.["staff_budget_cfy"] ?? "",
      staffBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["staff_budget_cfym1"] ?? "",
      staffBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["staff_budget_cfym2"] ?? "",
      staffBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["staff_budget_cfym3"] ?? "",

      staffExpensesCfy:
        metricInfo?.[template]?.[0]?.["staff_expenses_cfy"] ?? "",
      staffExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["staff_expenses_cfym1"] ?? "",
      staffExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["staff_expenses_cfym2"] ?? "",
      staffExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["staff_expenses_cfym3"] ?? "",

      outreachProgramsBudgetCfy:
        metricInfo?.[template]?.[0]?.["outreach_programs_budget_cfy"] ?? "",
      outreachProgramsBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["outreach_programs_budget_cfym1"] ?? "",
      outreachProgramsBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["outreach_programs_budget_cfym2"] ?? "",
      outreachProgramsBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["outreach_programs_budget_cfym3"] ?? "",

      outreachProgramsExpensesCfy:
        metricInfo?.[template]?.[0]?.["outreach_programs_expenses_cfy"] ?? "",
      outreachProgramsExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["outreach_programs_expenses_cfym1"] ?? "",
      outreachProgramsExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["outreach_programs_expenses_cfym2"] ?? "",
      outreachProgramsExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["outreach_programs_expenses_cfym3"] ?? "",

      rdBudgetCfy: metricInfo?.[template]?.[0]?.["rd_budget_cfy"] ?? "",
      rdBudgetCfyM1: metricInfo?.[template]?.[0]?.["rd_budget_cfym1"] ?? "",
      rdBudgetCfyM2: metricInfo?.[template]?.[0]?.["rd_budget_cfym2"] ?? "",
      rdBudgetCfyM3: metricInfo?.[template]?.[0]?.["rd_budget_cfym3"] ?? "",

      rdExpensesCfy: metricInfo?.[template]?.[0]?.["rd_expenses_cfy"] ?? "",
      rdExpensesCfyM1: metricInfo?.[template]?.[0]?.["rd_expenses_cfym1"] ?? "",
      rdExpensesCfyM2: metricInfo?.[template]?.[0]?.["rd_expenses_cfym2"] ?? "",
      rdExpensesCfyM3: metricInfo?.[template]?.[0]?.["rd_expenses_cfym3"] ?? "",

      placementBudgetCfy:
        metricInfo?.[template]?.[0]?.["placement_budget_cfy"] ?? "",
      placementBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["placement_budget_cfym1"] ?? "",
      placementBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["placement_budget_cfym2"] ?? "",
      placementBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["placement_budget_cfym3"] ?? "",

      placementExpensesCfy:
        metricInfo?.[template]?.[0]?.["placement_expenses_cfy"] ?? "",
      placementExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["placement_expenses_cfym1"] ?? "",
      placementExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["placement_expenses_cfym2"] ?? "",
      placementExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["placement_expenses_cfym3"] ?? "",

      sdgBudgetCfy: metricInfo?.[template]?.[0]?.["sdg_budget_cfy"] ?? "",
      sdgBudgetCfyM1: metricInfo?.[template]?.[0]?.["sdg_budget_cfym1"] ?? "",
      sdgBudgetCfyM2: metricInfo?.[template]?.[0]?.["sdg_budget_cfym2"] ?? "",
      sdgBudgetCfyM3: metricInfo?.[template]?.[0]?.["sdg_budget_cfym3"] ?? "",

      sdgExpensesCfy: metricInfo?.[template]?.[0]?.["sdg_expenses_cfy"] ?? "",
      sdgExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym1"] ?? "",
      sdgExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym2"] ?? "",
      sdgExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym3"] ?? "",

      entrepreneurshipBudgetCfy:
        metricInfo?.[template]?.[0]?.["entrepreneurship_budget_cfy"] ?? "",
      entrepreneurshipBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["entrepreneurship_budget_cfym1"] ?? "",
      entrepreneurshipBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["entrepreneurship_budget_cfym2"] ?? "",
      entrepreneurshipBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["entrepreneurship_budget_cfym3"] ?? "",

      entrepreneurshipExpensesCfy:
        metricInfo?.[template]?.[0]?.["entrepreneurship_expenses_cfy"] ?? "",
      entrepreneurshipExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["entrepreneurship_expenses_cfym1"] ?? "",
      entrepreneurshipExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["entrepreneurship_expenses_cfym2"] ?? "",
      entrepreneurshipExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["entrepreneurship_expenses_cfym3"] ?? "",

      othersBudgetCfy: metricInfo?.[template]?.[0]?.["others_budget_cfy"] ?? "",
      othersBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["others_budget_cfym1"] ?? "",
      othersBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["others_budget_cfym2"] ?? "",
      othersBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["others_budget_cfym3"] ?? "",

      othersExpensesCfy:
        metricInfo?.[template]?.[0]?.["others_expenses_cfy"] ?? "",
      othersExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["others_expenses_cfym1"] ?? "",
      othersExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["others_expenses_cfym2"] ?? "",
      othersExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["others_expenses_cfym3"] ?? "",
    };

    setBudgetExpenditure(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col ">
      <table className={styles["budget-expenditure-items-table"]}>
        <thead>
          <tr>
            <th>Items</th>
            <th>Budgeted in CFY</th>
            <th>Actual expenses in CFY</th>
            <th>Budgeted in CFYm1</th>
            <th>Actual expenses in CFYm1</th>
            <th>Budgeted in CFYm2</th>
            <th>Actual expenses in CFYm2</th>
            <th>Budgeted in CFYm3</th>
            <th>Actual expenses in CFYm3</th>
          </tr>
        </thead>
        <tbody>
          {renderStructure()}
          {totalAmount()}
        </tbody>
      </table>
      <button type="submit" className={styles["save-btn"]}>
        Save
      </button>
    </form>
  );
};

export default BudgetExpenditureItemsInstituteLvl;

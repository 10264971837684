import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyResourcePersonsSttps = (props) => {
  const [rowsCount1, setRowsCount1] = useState(1);
  const [rowsCount2, setRowsCount2] = useState(1);
  const [rowsCount3, setRowsCount3] = useState(1);
  const [filtData, setFiltData] = useState({});

  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const increaseCount = (each) => {
    if (each === 1) {
      setRowsCount1(rowsCount1 + 1);
    }
    if (each === 2) {
      setRowsCount2(rowsCount2 + 1);
    }
    if (each === 3) {
      setRowsCount3(rowsCount3 + 1);
    }
  };

  const decreaseCount = (each) => {
    if (each === 1) {
      rowsCount1 > 0 && setRowsCount1(rowsCount1 - 1);
    }

    if (each === 2) {
      rowsCount2 > 0 && setRowsCount2(rowsCount2 - 1);
    }

    if (each === 3) {
      rowsCount3 > 0 && setRowsCount3(rowsCount3 - 1);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount1; count++) {
        reqBody.push({
          facultyResourcePersonsSttpsId: document.getElementById(
            `CAYm1-${count}-faculty-name`
          ).name,
          facultyName: document.getElementById(`CAYm1-${count}-faculty-name`)
            .value,
          sttpName: document.getElementById(`CAYm1-${count}-sttp-name`).value,
          date: document.getElementById(`CAYm1-${count}-date`).value,
          location: document.getElementById(`CAYm1-${count}-location`).value,
          organizedBy: document.getElementById(`CAYm1-${count}-organized-by`)
            .value,
          year: "CAYm1",
        });
      }

      for (let count = 1; count <= rowsCount2; count++) {
        reqBody.push({
          facultyResourcePersonsSttpsId: document.getElementById(
            `CAYm2-${count}-faculty-name`
          ).name,
          facultyName: document.getElementById(`CAYm2-${count}-faculty-name`)
            .value,
          sttpName: document.getElementById(`CAYm2-${count}-sttp-name`).value,
          date: document.getElementById(`CAYm2-${count}-date`).value,
          location: document.getElementById(`CAYm2-${count}-location`).value,
          organizedBy: document.getElementById(`CAYm2-${count}-organized-by`)
            .value,
          year: "CAYm2",
        });
      }

      for (let count = 1; count <= rowsCount3; count++) {
        reqBody.push({
          facultyResourcePersonsSttpsId: document.getElementById(
            `CAYm3-${count}-faculty-name`
          ).name,
          facultyName: document.getElementById(`CAYm3-${count}-faculty-name`)
            .value,
          sttpName: document.getElementById(`CAYm3-${count}-sttp-name`).value,
          date: document.getElementById(`CAYm3-${count}-date`).value,
          location: document.getElementById(`CAYm3-${count}-location`).value,
          organizedBy: document.getElementById(`CAYm3-${count}-organized-by`)
            .value,
          year: "CAYm3",
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount, year) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>{count}</td>
          <td className={styles["input-col"]}>
            <input
              name={
                filtData?.[year]?.[count - 1]?.[
                  "faculty_resource_persons_sttps_id"
                ] ?? ""
              }
              defaultValue={
                filtData?.[year]?.[count - 1]?.["faculty_name"] ?? ""
              }
              id={`${year}-${count}-faculty-name`}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-sttp-name`}
              defaultValue={filtData?.[year]?.[count - 1]?.["sttp_name"] ?? ""}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${year}-${count}-date`}
              defaultValue={filtData?.[year]?.[count - 1]?.["date"] ?? ""}
              required
              type="date"
            />
          </td>
          <td>
            <input
              id={`${year}-${count}-location`}
              defaultValue={filtData?.[year]?.[count - 1]?.["location"] ?? ""}
              required
            />
          </td>
          <td>
            <input
              id={`${year}-${count}-organized-by`}
              defaultValue={
                filtData?.[year]?.[count - 1]?.["organized_by"] ?? ""
              }
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  const formatData = () => {
    try {
      const updatedData = Object.groupBy(
        metricInfo?.[template],
        ({ year }) => year
      );

      const m1Count = updatedData?.["CAYm1"]?.length ?? 1;
      const m2Count = updatedData?.["CAYm2"]?.length ?? 1;
      const m3Count = updatedData?.["CAYm3"]?.length ?? 1;

      setFiltData(updatedData);
      setRowsCount1(m1Count);
      setRowsCount2(m2Count);
      setRowsCount3(m3Count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formatData();
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["faculty-resource-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Faculty as Resource Person</th>
            <th>Name of the STTP/FDP</th>
            <th>Date</th>
            <th>Location</th>
            <th>Organized by</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={6}>CAYM1</td>
          </tr>
          {renderStructure(rowsCount1, "CAYm1")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(1)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["faculty-resource-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Faculty as Resource Person</th>
            <th>Name of the STTP/FDP</th>
            <th>Date</th>
            <th>Location</th>
            <th>Organized by</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={6}>CAYM2</td>
          </tr>
          {renderStructure(rowsCount2, "CAYm2")}
        </tbody>
      </table>

      <span className="flex justify-end self-end pb-6">
        <button
          onClick={() => decreaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(2)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["faculty-resource-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Faculty as Resource Person</th>
            <th>Name of the STTP/FDP</th>
            <th>Date</th>
            <th>Location</th>
            <th>Organized by</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={6}>CAYM3</td>
          </tr>
          {renderStructure(rowsCount3, "CAYm3")}
        </tbody>
      </table>

      <span className="flex justify-end self-end ">
        <button
          onClick={() => decreaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={() => increaseCount(3)}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount1 + rowsCount2 + rowsCount3 > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default FacultyResourcePersonsSttps;

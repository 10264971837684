import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const AcademicPerformanceTable = (props) => {
  const { year } = props;
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [academicData, setAcademicData] = useState({
    pointsMeanCayM1: "",
    pointsMeanCayM2: "",
    pointsMeanCayM3: "",
    successfulStudentsCayM1: "",
    successfulStudentsCayM2: "",
    successfulStudentsCayM3: "",
    appearedStudentsCayM1: "",
    appearedStudentsCayM2: "",
    appearedStudentsCayM3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        pointsMeanCayM1,
        pointsMeanCayM2,
        pointsMeanCayM3,
        successfulStudentsCayM1,
        successfulStudentsCayM2,
        successfulStudentsCayM3,
        appearedStudentsCayM1,
        appearedStudentsCayM2,
        appearedStudentsCayM3,
      } = academicData;

      const reqBody = {
        academicPerformanceId:
          metricInfo?.[template]?.[0]?.["academic_performance_id"] ?? "",
        pointsMeanCayM1,
        pointsMeanCayM2,
        pointsMeanCayM3,
        successfulStudentsCayM1,
        successfulStudentsCayM2,
        successfulStudentsCayM3,
        appearedStudentsCayM1,
        appearedStudentsCayM2,
        appearedStudentsCayM3,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateAcademicData = (event) => {
    setAcademicData({
      ...academicData,
      [event.target.name]: event.target.value,
    });
  };

  const renderApi = () => {
    let result = [];

    try {
      const ap1 =
        (parseInt(academicData?.successfulStudentsCayM1) /
          parseInt(academicData?.appearedStudentsCayM1)) *
        parseFloat(academicData?.pointsMeanCayM1);

      const ap2 =
        (parseInt(academicData?.successfulStudentsCayM2) /
          parseInt(academicData?.appearedStudentsCayM2)) *
        parseFloat(academicData?.pointsMeanCayM2);

      const ap3 =
        (parseInt(academicData?.successfulStudentsCayM3) /
          parseInt(academicData?.appearedStudentsCayM3)) *
        parseFloat(academicData?.pointsMeanCayM3);

      result.push(
        <>
          <td className={styles["input-col"]}>{ap1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{ap2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{ap3.toFixed(2)}</td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderAvg = () => {
    let result = "";

    try {
      const ap1 =
        (parseInt(academicData?.successfulStudentsCayM1) /
          parseInt(academicData?.appearedStudentsCayM1)) *
        parseFloat(academicData?.pointsMeanCayM1);

      const ap2 =
        (parseInt(academicData?.successfulStudentsCayM2) /
          parseInt(academicData?.appearedStudentsCayM2)) *
        parseFloat(academicData?.pointsMeanCayM2);

      const ap3 =
        (parseInt(academicData?.successfulStudentsCayM3) /
          parseInt(academicData?.appearedStudentsCayM3)) *
        parseFloat(academicData?.pointsMeanCayM3);

      result = ((ap1 + ap2 + ap3) / 3).toFixed(2);
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    const data = {
      pointsMeanCayM1: metricInfo?.[template]?.[0]?.["points_mean_caym1"] ?? "",
      pointsMeanCayM2: metricInfo?.[template]?.[0]?.["points_mean_caym2"] ?? "",
      pointsMeanCayM3: metricInfo?.[template]?.[0]?.["points_mean_caym3"] ?? "",
      successfulStudentsCayM1:
        metricInfo?.[template]?.[0]?.["successful_students_caym1"] ?? "",
      successfulStudentsCayM2:
        metricInfo?.[template]?.[0]?.["successful_students_caym2"] ?? "",
      successfulStudentsCayM3:
        metricInfo?.[template]?.[0]?.["successful_students_caym3"] ?? "",

      appearedStudentsCayM1:
        metricInfo?.[template]?.[0]?.["appeared_students_caym1"] ?? "",
      appearedStudentsCayM2:
        metricInfo?.[template]?.[0]?.["appeared_students_caym2"] ?? "",
      appearedStudentsCayM3:
        metricInfo?.[template]?.[0]?.["appeared_students_caym3"] ?? "",
    };

    setAcademicData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col ">
      <table className={styles["academic-performance-table"]}>
        <thead>
          <tr>
            <th>Academic Performance</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
            <th>CAYm3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              X=&nbsp;&nbsp; (Mean of {year} year grade point average of all
              successful
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; students on a
              10-point scale) or (Mean of the
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; percentage of
              marks of all successful students in {year} <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; year/10)
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                step={0.01}
                type="number"
                value={academicData.pointsMeanCayM1}
                onChange={updateAcademicData}
                name="pointsMeanCayM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                step={0.01}
                type="number"
                value={academicData.pointsMeanCayM2}
                onChange={updateAcademicData}
                name="pointsMeanCayM2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                step={0.01}
                type="number"
                value={academicData.pointsMeanCayM3}
                onChange={updateAcademicData}
                name="pointsMeanCayM3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Y=&nbsp; Total no. of successful students</td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.successfulStudentsCayM1}
                onChange={updateAcademicData}
                name="successfulStudentsCayM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.successfulStudentsCayM2}
                onChange={updateAcademicData}
                name="successfulStudentsCayM2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.successfulStudentsCayM3}
                onChange={updateAcademicData}
                name="successfulStudentsCayM3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Z=&nbsp; Total no. of students appeared in the examination</td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.appearedStudentsCayM1}
                onChange={updateAcademicData}
                name="appearedStudentsCayM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.appearedStudentsCayM2}
                onChange={updateAcademicData}
                name="appearedStudentsCayM2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                min={0}
                type="number"
                value={academicData.appearedStudentsCayM3}
                onChange={updateAcademicData}
                name="appearedStudentsCayM3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>API = X* (Y/Z)</td>
            {renderApi()}
          </tr>

          <tr>
            <td>Average API = (AP1 + AP2 + AP3)/3</td>
            <td className={styles["input-col"]} colSpan={3}>
              {renderAvg()}
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" className={styles["save-btn"]}>
        Save
      </button>
    </form>
  );
};

export default AcademicPerformanceTable;

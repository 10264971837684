import styles from "./index.module.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";

const StudentFacultyRatio = (props) => {
  const [rowsCount, setRowsCount] = useState(1);
  const [studentFacultyData, setStudentFacultyData] = useState({
    deptStudentsCay: "",
    deptStudentsCayM1: "",
    deptStudentsCayM2: "",
    alliedDeptStudentsCay: "",
    alliedDeptStudentsCayM1: "",
    alliedDeptStudentsCayM2: "",
    deptFacultyCay: "",
    deptFacultyCayM1: "",
    deptFacultyCayM2: "",
    alliedDeptFacultyCay: "",
    alliedDeptFacultyCayM1: "",
    alliedDeptFacultyCayM2: "",
    facTeachingLoadCay: "",
    facTeachingLoadCayM1: "",
    facTeachingLoadCayM2: "",
  });

  const updateStudentFacultyData = (event) => {
    setStudentFacultyData({
      ...studentFacultyData,
      [event.target.name]: event.target.value,
    });
  };

  const { template, metricsId, metricInfo, uploadedStatus } = props;

  const increaseCount = () => {
    setRowsCount(rowsCount + 1);
  };

  const decreaseCount = () => {
    rowsCount > 0 && setRowsCount(rowsCount - 1);
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const {
      deptStudentsCay,
      deptStudentsCayM1,
      deptStudentsCayM2,
      alliedDeptStudentsCay,
      alliedDeptStudentsCayM1,
      alliedDeptStudentsCayM2,
      deptFacultyCay,
      deptFacultyCayM1,
      deptFacultyCayM2,
      alliedDeptFacultyCay,
      alliedDeptFacultyCayM1,
      alliedDeptFacultyCayM2,
      facTeachingLoadCay,
      facTeachingLoadCayM1,
      facTeachingLoadCayM2,
    } = studentFacultyData;

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount; count++) {
        reqBody.push({
          stdFacultyRatioId: document.getElementById(`year-program-${count}`)
            .name,
          yearProgram: document.getElementById(`year-program-${count}`).value,
          cay: document.getElementById(`cay-${count}`).value,
          cayM1: document.getElementById(`cay-m1-${count}`).value,
          cayM2: document.getElementById(`cay-m2-${count}`).value,
          deptStudentsCay,
          deptStudentsCayM1,
          deptStudentsCayM2,
          alliedDeptStudentsCay,
          alliedDeptStudentsCayM1,
          alliedDeptStudentsCayM2,
          deptFacultyCay,
          deptFacultyCayM1,
          deptFacultyCayM2,
          alliedDeptFacultyCay,
          alliedDeptFacultyCayM1,
          alliedDeptFacultyCayM2,
          facTeachingLoadCay,
          facTeachingLoadCayM1,
          facTeachingLoadCayM2,
        });
      }
      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderStructure = (rowsCount) => {
    let result = [];

    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>
            <input
              id={`year-program-${count}`}
              name={
                metricInfo?.[template]?.[count - 1]?.["std_faculty_ratio_id"]
              }
              defaultValue={
                metricInfo?.[template]?.[count - 1]?.["year_program"]
              }
            />
          </td>
          <td>
            <input
              id={`cay-${count}`}
              type="number"
              defaultValue={metricInfo?.[template]?.[count - 1]?.["cay"]}
              required
            />
          </td>

          <td>
            <input
              id={`cay-m1-${count}`}
              type="number"
              defaultValue={metricInfo?.[template]?.[count - 1]?.["caym1"]}
              required
            />
          </td>

          <td>
            <input
              id={`cay-m2-${count}`}
              type="number"
              defaultValue={metricInfo?.[template]?.[count - 1]?.["caym2"]}
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  const renderTotalF = () => {
    let result = [];

    try {
      const total1 =
        parseInt(studentFacultyData?.deptFacultyCay) +
          parseInt(studentFacultyData?.alliedDeptFacultyCay) || 0;

      const total2 =
        parseInt(studentFacultyData?.deptFacultyCayM1) +
          parseInt(studentFacultyData?.alliedDeptFacultyCayM1) || 0;

      const total3 =
        parseInt(studentFacultyData?.deptFacultyCayM2) +
          parseInt(studentFacultyData?.alliedDeptFacultyCayM2) || 0;

      result.push(
        <tr>
          <td>
            F=Total no. of faculty members in the Department (DF) and allied
            Departments (AF)
          </td>
          <td className={styles["input-col"]}>{total1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{total2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{total3.toFixed(2)}</td>
        </tr>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderTotalS = () => {
    let result = [];

    try {
      const total1 =
        parseInt(studentFacultyData?.deptStudentsCay) +
          parseInt(studentFacultyData?.alliedDeptStudentsCay) || 0;

      const total2 =
        parseInt(studentFacultyData?.deptStudentsCayM1) +
          parseInt(studentFacultyData?.alliedDeptStudentsCayM1) || 0;

      const total3 =
        parseInt(studentFacultyData?.deptStudentsCayM2) +
          parseInt(studentFacultyData?.alliedDeptStudentsCayM2) || 0;

      result.push(
        <tr>
          <td>
            S=Total no. of students in the Department (DS) and allied
            departments (AS)
          </td>
          <td className={styles["input-col"]}>{total1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{total2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{total3.toFixed(2)}</td>
        </tr>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const AvgSRF = () => {
    let result = [];
    try {
      const srf1 =
        (parseInt(studentFacultyData?.deptStudentsCay) +
          parseInt(studentFacultyData?.alliedDeptStudentsCay)) /
          (parseInt(studentFacultyData?.deptFacultyCay) +
            parseInt(studentFacultyData?.alliedDeptFacultyCay) -
            parseInt(studentFacultyData?.facTeachingLoadCay)) || 0;
      const srf2 =
        (parseInt(studentFacultyData?.deptStudentsCayM1) +
          parseInt(studentFacultyData?.alliedDeptStudentsCayM1)) /
          (parseInt(studentFacultyData?.deptFacultyCayM1) +
            parseInt(studentFacultyData?.alliedDeptFacultyCayM1) -
            parseInt(studentFacultyData?.facTeachingLoadCayM1)) || 0;
      const srf3 =
        (parseInt(studentFacultyData?.deptStudentsCayM2) +
          parseInt(studentFacultyData?.alliedDeptStudentsCayM2)) /
          (parseInt(studentFacultyData?.deptFacultyCayM2) +
            parseInt(studentFacultyData.alliedDeptFacultyCayM2) -
            parseInt(studentFacultyData.facTeachingLoadCayM2)) || 0;

      const avg = (srf1 + srf2 + srf3) / 3 || 0;

      result.push(
        <>
          <tr>
            <td>Student Faculty Ratio (SFR)=S/(F-FF)</td>
            <td className={styles["input-col"]}>{srf1.toFixed(2)}</td>
            <td className={styles["input-col"]}>{srf2.toFixed(2)}</td>
            <td className={styles["input-col"]}>{srf3.toFixed(2)}</td>
          </tr>

          <tr>
            <td>Average SFR for 3 years</td>
            <td colSpan={3}>{avg.toFixed(2)}</td>
          </tr>
        </>
      );
    } catch (err) {
      console.log(err);
    }
    return result;
  };

  const renderStructuresStatic = () => {
    let result = [];
    result.push(
      <>
        <tr>
          <td>
            DS=Total no. of students in the Department (UG1, UG2, UG4, UG5, PG1)
          </td>
          <td>
            <input
              name="deptStudentsCay"
              type="number"
              required
              value={studentFacultyData?.deptStudentsCay}
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="deptStudentsCayM1"
              type="number"
              value={studentFacultyData?.deptStudentsCayM1}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="deptStudentsCayM2"
              type="number"
              value={studentFacultyData?.deptStudentsCayM2}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
        </tr>
        <tr>
          <td>AS=Total no. of students in allied departments (UG6)</td>
          <td>
            <input
              name="alliedDeptStudentsCay"
              type="number"
              required
              value={studentFacultyData?.alliedDeptStudentsCay}
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="alliedDeptStudentsCayM1"
              type="number"
              value={studentFacultyData?.alliedDeptStudentsCayM1}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="alliedDeptStudentsCayM2"
              type="number"
              value={studentFacultyData?.alliedDeptStudentsCayM2}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
        </tr>
        {renderTotalS()}
        <tr>
          <td>DF=No. of faculty members in the Department</td>
          <td>
            <input
              name="deptFacultyCay"
              type="number"
              required
              value={studentFacultyData?.deptFacultyCay}
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="deptFacultyCayM1"
              type="number"
              value={studentFacultyData?.deptFacultyCayM1}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="deptFacultyCayM2"
              type="number"
              value={studentFacultyData?.deptFacultyCayM2}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
        </tr>
        <tr>
          <td>AF=No. of faculty members in the allied Departments</td>
          <td>
            <input
              name="alliedDeptFacultyCay"
              type="number"
              required
              value={studentFacultyData?.alliedDeptFacultyCay}
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="alliedDeptFacultyCayM1"
              type="number"
              value={studentFacultyData?.alliedDeptFacultyCayM1}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="alliedDeptFacultyCayM2"
              type="number"
              value={studentFacultyData?.alliedDeptFacultyCayM2}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
        </tr>
        {renderTotalF()}
        <tr>
          <td>
            FF=The faculty members who have a 100% teaching load in the
            first-year courses
          </td>
          <td>
            <input
              name="facTeachingLoadCay"
              required
              type="number"
              value={studentFacultyData?.facTeachingLoadCay}
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="facTeachingLoadCayM1"
              type="number"
              value={studentFacultyData?.facTeachingLoadCayM1}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
          <td>
            <input
              name="facTeachingLoadCayM2"
              type="number"
              value={studentFacultyData?.facTeachingLoadCayM2}
              required
              onChange={updateStudentFacultyData}
              min={0}
            />
          </td>
        </tr>
        {AvgSRF()}
      </>
    );
    return result;
  };

  const formatData = () => {
    const data = {
      deptStudentsCay: metricInfo?.[template]?.[0]?.["dept_students_cay"] ?? "",
      deptStudentsCayM1:
        metricInfo?.[template]?.[0]?.["dept_students_caym1"] ?? "",
      deptStudentsCayM2:
        metricInfo?.[template]?.[0]?.["dept_students_caym2"] ?? "",
      alliedDeptStudentsCay:
        metricInfo?.[template]?.[0]?.["allied_dept_students_cay"] ?? "",
      alliedDeptStudentsCayM1:
        metricInfo?.[template]?.[0]?.["allied_dept_students_caym1"] ?? "",
      alliedDeptStudentsCayM2:
        metricInfo?.[template]?.[0]?.["allied_dept_students_caym2"] ?? "",
      deptFacultyCay: metricInfo?.[template]?.[0]?.["dept_faculty_cay"] ?? "",
      deptFacultyCayM1:
        metricInfo?.[template]?.[0]?.["dept_faculty_caym1"] ?? "",
      deptFacultyCayM2:
        metricInfo?.[template]?.[0]?.["dept_faculty_caym2"] ?? "",
      alliedDeptFacultyCay:
        metricInfo?.[template]?.[0]?.["allied_dept_faculty_cay"] ?? "",
      alliedDeptFacultyCayM1:
        metricInfo?.[template]?.[0]?.["allied_dept_faculty_caym1"] ?? "",
      alliedDeptFacultyCayM2:
        metricInfo?.[template]?.[0]?.["allied_dept_faculty_caym2"] ?? "",
      facTeachingLoadCay:
        metricInfo?.[template]?.[0]?.["fac_teaching_load_cay"] ?? "",
      facTeachingLoadCayM1:
        metricInfo?.[template]?.[0]?.["fac_teaching_load_caym1"] ?? "",
      facTeachingLoadCayM2:
        metricInfo?.[template]?.[0]?.["fac_teaching_load_caym2"] ?? "",
    };

    setStudentFacultyData(data);
  };

  useEffect(() => {
    formatData();

    const rows =
      metricInfo?.[template].length > 0
        ? metricInfo?.[template].length
        : rowsCount;

    setRowsCount(rows);
  }, []);

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["student-faculty-table"]}>
        <thead>
          <tr>
            <th>Student</th>
            <th>CAY</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
          </tr>
        </thead>
        <tbody>{renderStructure(rowsCount)}</tbody>
      </table>

      <span className="flex justify-end self-end">
        <button
          onClick={decreaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={increaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      <table className={styles["student-faculty-table"]}>
        <tbody>{renderStructuresStatic()}</tbody>
      </table>

      {rowsCount > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default StudentFacultyRatio;

import React, { Component } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";

class Input extends Component {
  state = { content: "", loading: true };

  componentDidMount() {
    const { metricInfo } = this.props;
    this.setState({
      content: metricInfo?.data ?? "",
      loading: false,
    });
  }

  handleEditorChange = (data) => {
    this.setState({ content: data });
  };

  handleImageUpload = (files, info, uploadHandler) => {
    try {
      const { metricsId } = this.props;

      const file = files[0];
      const formData = new FormData();
      formData.append("file", file, file.name);
      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA_IMG_UPLOAD +
          `/${metricsId}`,
        options
      )
        .then((response) => {
          return response.json();
        })
        .then((url) => {
          const res = {
            result: [
              {
                url: url,
                name: file.name,
                size: file.size,
              },
            ],
          };

          uploadHandler(res);
        })
        .catch((msg) => {
          uploadHandler(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  saved = async () => {
    this.setState({ loading: true });

    const { content } = this.state;
    const { metricsId, metricInfo, template, uploadedStatus } = this.props;

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const reqBody = { data: content };

    const options = {
      method: metricInfo === undefined ? "POST" : "PUT",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      console.log(err);
      alert("Failed to upload data");
    }
    this.setState({ loading: false });
  };

  render() {
    const { content, loading } = this.state;

    return loading ? (
      <div className="flex justify-center items-center w-full h-80">
        <Oval
          height={50}
          width={50}
          color="#3D65F4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3D65F4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    ) : (
      <div className="flex flex-col p-2 pr-0 z-0">
        <SunEditor
          setContents={content}
          onChange={this.handleEditorChange}
          onImageUploadBefore={this.handleImageUpload}
          height="500"
          placeholder="Start typing here..."
          setOptions={{
            buttonList: [
              [
                "undo",
                "redo",
                "bold",
                "italic",
                "underline",
                "strike",
                "subscript",
                "superscript",
                "fontSize",
                "fontColor",
                "hiliteColor",
                "font",
                "textStyle",
              ],
              ["align", "list", "outdent", "indent"],
              ["image", "link", "table"],
              ["fullScreen", "preview", "print"],
            ],
          }}
        />

        <button
          className={styles["save-btn"]}
          type="button"
          onClick={this.saved}
        >
          Save
        </button>
      </div>
    );
  }
}

export default Input;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const PlacementEntrepreneurshipTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [placementData, setPlacementData] = useState({
    finalYearStudentsLyg: "",
    finalYearStudentsLygM1: "",
    finalYearStudentsLygM2: "",
    placedStudentsLyg: "",
    placedStudentsLygM1: "",
    placedStudentsLygM2: "",
    higherStudiesStudentsLyg: "",
    higherStudiesStudentsLygM1: "",
    higherStudiesStudentsLygM2: "",
    entrepreneurshipStudentsLyg: "",
    entrepreneurshipStudentsLygM1: "",
    entrepreneurshipStudentsLygM2: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        finalYearStudentsLyg,
        finalYearStudentsLygM1,
        finalYearStudentsLygM2,
        placedStudentsLyg,
        placedStudentsLygM1,
        placedStudentsLygM2,
        higherStudiesStudentsLyg,
        higherStudiesStudentsLygM1,
        higherStudiesStudentsLygM2,
        entrepreneurshipStudentsLyg,
        entrepreneurshipStudentsLygM1,
        entrepreneurshipStudentsLygM2,
      } = placementData;

      const reqBody = {
        placementEntrepreneurshipId:
          metricInfo?.[template]?.[0]?.["placement_entrepreneurship_id"] ?? "",
        finalYearStudentsLyg,
        finalYearStudentsLygM1,
        finalYearStudentsLygM2,
        placedStudentsLyg,
        placedStudentsLygM1,
        placedStudentsLygM2,
        higherStudiesStudentsLyg,
        higherStudiesStudentsLygM1,
        higherStudiesStudentsLygM2,
        entrepreneurshipStudentsLyg,
        entrepreneurshipStudentsLygM1,
        entrepreneurshipStudentsLygM2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updatePlacementData = (event) => {
    setPlacementData({
      ...placementData,
      [event.target.name]: event.target.value,
    });
  };

  const renderTotal = () => {
    let result = [];

    try {
      const ap1 =
        parseInt(placementData?.placedStudentsLyg) +
        parseInt(placementData?.higherStudiesStudentsLyg) +
        parseInt(placementData?.entrepreneurshipStudentsLyg);

      const ap2 =
        parseInt(placementData?.placedStudentsLygM1) +
        parseInt(placementData?.higherStudiesStudentsLygM1) +
        parseInt(placementData?.entrepreneurshipStudentsLygM1);

      const ap3 =
        parseInt(placementData?.placedStudentsLygM2) +
        parseInt(placementData?.higherStudiesStudentsLygM2) +
        parseInt(placementData?.entrepreneurshipStudentsLygM2);

      result.push(
        <>
          <td className={styles["input-col"]}>{ap1}</td>
          <td className={styles["input-col"]}>{ap2}</td>
          <td className={styles["input-col"]}>{ap3}</td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderPi = () => {
    let result = [];

    try {
      const ap1 =
        ((parseInt(placementData?.placedStudentsLyg) +
          parseInt(placementData?.higherStudiesStudentsLyg) +
          parseInt(placementData?.entrepreneurshipStudentsLyg)) /
          parseInt(placementData?.finalYearStudentsLyg)) *
        100;

      const ap2 =
        ((parseInt(placementData?.placedStudentsLygM1) +
          parseInt(placementData?.higherStudiesStudentsLygM1) +
          parseInt(placementData?.entrepreneurshipStudentsLygM1)) /
          parseInt(placementData?.finalYearStudentsLygM1)) *
        100;

      const ap3 =
        ((parseInt(placementData?.placedStudentsLygM2) +
          parseInt(placementData?.higherStudiesStudentsLygM2) +
          parseInt(placementData?.entrepreneurshipStudentsLygM2)) /
          parseInt(placementData?.finalYearStudentsLygM2)) *
        100;

      result.push(
        <>
          <td className={styles["input-col"]}>{ap1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{ap2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{ap3.toFixed(2)}</td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderAvg = () => {
    let result = "";

    try {
      const ap1 =
        ((parseInt(placementData?.placedStudentsLyg) +
          parseInt(placementData?.higherStudiesStudentsLyg) +
          parseInt(placementData?.entrepreneurshipStudentsLyg)) /
          parseInt(placementData?.finalYearStudentsLyg)) *
        100;

      const ap2 =
        ((parseInt(placementData?.placedStudentsLygM1) +
          parseInt(placementData?.higherStudiesStudentsLygM1) +
          parseInt(placementData?.entrepreneurshipStudentsLygM1)) /
          parseInt(placementData?.finalYearStudentsLygM1)) *
        100;

      const ap3 =
        ((parseInt(placementData?.placedStudentsLygM2) +
          parseInt(placementData?.higherStudiesStudentsLygM2) +
          parseInt(placementData?.entrepreneurshipStudentsLygM2)) /
          parseInt(placementData?.finalYearStudentsLygM2)) *
        100;

      result = ((ap1 + ap2 + ap3) / 3).toFixed(2);
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    const data = {
      finalYearStudentsLyg:
        metricInfo?.[template]?.[0]?.["final_year_students_lyg"] ?? "",
      finalYearStudentsLygM1:
        metricInfo?.[template]?.[0]?.["final_year_students_lygm1"] ?? "",
      finalYearStudentsLygM2:
        metricInfo?.[template]?.[0]?.["final_year_students_lygm2"] ?? "",
      placedStudentsLyg:
        metricInfo?.[template]?.[0]?.["placed_students_lyg"] ?? "",
      placedStudentsLygM1:
        metricInfo?.[template]?.[0]?.["placed_students_lygm1"] ?? "",
      placedStudentsLygM2:
        metricInfo?.[template]?.[0]?.["placed_students_lygm2"] ?? "",
      higherStudiesStudentsLyg:
        metricInfo?.[template]?.[0]?.["higher_studies_students_lyg"] ?? "",
      higherStudiesStudentsLygM1:
        metricInfo?.[template]?.[0]?.["higher_studies_students_lygm1"] ?? "",
      higherStudiesStudentsLygM2:
        metricInfo?.[template]?.[0]?.["higher_studies_students_lygm2"] ?? "",
      entrepreneurshipStudentsLyg:
        metricInfo?.[template]?.[0]?.["entrepreneurship_students_lyg"] ?? "",
      entrepreneurshipStudentsLygM1:
        metricInfo?.[template]?.[0]?.["entrepreneurship_students_lygm1"] ?? "",
      entrepreneurshipStudentsLygM2:
        metricInfo?.[template]?.[0]?.["entrepreneurship_students_lygm2"] ?? "",
    };

    setPlacementData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["place-enter-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>LYG</th>
            <th>LYGm1</th>
            <th>LYGm2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FS*= Total no. of final year students</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.finalYearStudentsLyg}
                onChange={updatePlacementData}
                name="finalYearStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.finalYearStudentsLygM1}
                onChange={updatePlacementData}
                name="finalYearStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.finalYearStudentsLygM2}
                onChange={updatePlacementData}
                name="finalYearStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>X= No. of students placed</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.placedStudentsLyg}
                onChange={updatePlacementData}
                name="placedStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.placedStudentsLygM1}
                onChange={updatePlacementData}
                name="placedStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.placedStudentsLygM2}
                onChange={updatePlacementData}
                name="placedStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Y= No. of students admitted to higher studies</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.higherStudiesStudentsLyg}
                onChange={updatePlacementData}
                name="higherStudiesStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.higherStudiesStudentsLygM1}
                onChange={updatePlacementData}
                name="higherStudiesStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.higherStudiesStudentsLygM2}
                onChange={updatePlacementData}
                name="higherStudiesStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Z= No. of students taking up entrepreneurship</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.entrepreneurshipStudentsLyg}
                onChange={updatePlacementData}
                name="entrepreneurshipStudentsLyg"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.entrepreneurshipStudentsLygM1}
                onChange={updatePlacementData}
                name="entrepreneurshipStudentsLygM1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={placementData.entrepreneurshipStudentsLygM2}
                onChange={updatePlacementData}
                name="entrepreneurshipStudentsLygM2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>X + Y + Z =</td>
            {renderTotal()}
          </tr>

          <tr>
            <td>Placement Index (P) = (((X + Y + Z)/FS) * 100)</td>
            {renderPi()}
          </tr>

          <tr>
            <td>Average placement index = (P_1 + P_2 + P_3)/3</td>
            <td className={styles["input-col"]} colSpan={3}>
              {renderAvg()}
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default PlacementEntrepreneurshipTable;

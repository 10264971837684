import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyTrainingCollaborationDetails = (props) => {
  const [rowsCount, setRowsCount] = useState(1);

  const [filtData, setFiltData] = useState({});

  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const increaseCount = () => {
    setRowsCount(rowsCount + 1);
  };

  const decreaseCount = () => {
    rowsCount > 0 && setRowsCount(rowsCount - 1);
  };

  const submitData = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reqBody = [];

    try {
      for (let count = 1; count <= rowsCount; count++) {
        reqBody.push({
          facultyTrainingCollaborationDetailsId: document.getElementById(
            `${count}-faculty-name`
          ).name,
          facultyName: document.getElementById(`${count}-faculty-name`).value,
          trainingName: document.getElementById(`${count}-training-name`).value,
          companyName: document.getElementById(`${count}-company-name`).value,
          companyPlace: document.getElementById(`${count}-company-place`).value,
          duration: document.getElementById(`${count}-duration`).value,
          outcomes: document.getElementById(`${count}-outcomes`).value,
        });
      }

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const formatData = () => {
    try {
      const updatedData = metricInfo?.[template];
      const m1Count = updatedData?.length > 0 ? updatedData.length : 1;
      setFiltData(updatedData);
      setRowsCount(m1Count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    formatData();
  }, []);

  const renderStructure = (rowsCount) => {
    let result = [];
    for (let count = 1; count <= rowsCount; count++) {
      result.push(
        <tr key={`${count}-tr`}>
          <td>{count}</td>
          <td className={styles["input-col"]}>
            <input
              name={
                filtData?.[count - 1]?.[
                  "faculty_training_collaboration_details_id"
                ] ?? ""
              }
              defaultValue={filtData?.[count - 1]?.["faculty_name"] ?? ""}
              id={`${count}-faculty-name`}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${count}-training-name`}
              defaultValue={filtData?.[count - 1]?.["training_name"] ?? ""}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${count}-company-name`}
              defaultValue={filtData?.[count - 1]?.["company_name"] ?? ""}
              required
            />
          </td>
          <td className={styles["input-col"]}>
            <input
              id={`${count}-company-place`}
              defaultValue={filtData?.[count - 1]?.["company_place"] ?? ""}
              required
            />
          </td>

          <td className={styles["input-col"]}>
            <input
              type="number"
              id={`${count}-duration`}
              defaultValue={filtData?.[count - 1]?.["duration"] ?? ""}
              required
            />
          </td>

          <td className={styles["input-col"]}>
            <input
              id={`${count}-outcomes`}
              defaultValue={filtData?.[count - 1]?.["outcomes"] ?? ""}
              required
            />
          </td>
        </tr>
      );
    }
    return result;
  };

  return (
    <form onSubmit={submitData} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["faculty-collaboration-table"]}>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Name of the Faculty</th>
            <th>Name of the Internship/ Training/ Collaboration</th>
            <th>Name of the Company</th>
            <th>Name of the Company Place</th>
            <th>Duration</th>
            <th>Outcomes of Internship/ Training/ Collaboration</th>
          </tr>
        </thead>

        <tbody>{renderStructure(rowsCount)}</tbody>
      </table>

      <span className="flex justify-end self-end ">
        <button
          onClick={decreaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          -
        </button>

        <button
          onClick={increaseCount}
          type="button"
          className={styles["plus-btn"]}
        >
          +
        </button>
      </span>

      {rowsCount > 0 && (
        <button className={styles["save-btn"]} type="submit">
          Save
        </button>
      )}
    </form>
  );
};

export default FacultyTrainingCollaborationDetails;

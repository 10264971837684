import styles from "./index.module.css";
import { useState, useEffect } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const categories = [
  { name: "Laboratory equipment", label: "laboratory" },
  { name: "Software", label: "software" },
  { name: "SDGs", label: "sdg" },
  { name: "Support for faculty development", label: "facDevelop" },
  { name: "R & D", label: "rd" },
  {
    name: "Industrial Training, Industry expert, Internship",
    label: "training",
  },
  { name: "Miscellaneous expenses *", label: "miscellaneous" },
];

const fieldGroups = {
  laboratory: [
    "labBudgetProgramLvlCfy",
    "labExpensesProgramLvlCfy",
    "labBudgetProgramLvlCfyM1",
    "labExpensesProgramLvlCfyM1",
    "labBudgetProgramLvlCfyM2",
    "labExpensesProgramLvlCfyM2",
    "labBudgetProgramLvlCfyM3",
    "labExpensesProgramLvlCfyM3",
  ],
  software: [
    "softwareBudgetCfy",
    "softwareExpensesCfy",
    "softwareBudgetCfyM1",
    "softwareExpensesCfyM1",
    "softwareBudgetCfyM2",
    "softwareExpensesCfyM2",
    "softwareBudgetCfyM3",
    "softwareExpensesCfyM3",
  ],
  sdg: [
    "sdgBudgetProgramLvlCfy",
    "sdgExpensesProgramLvlCfy",
    "sdgBudgetProgramLvlCfyM1",
    "sdgExpensesProgramLvlCfyM1",
    "sdgBudgetProgramLvlCfyM2",
    "sdgExpensesProgramLvlCfyM2",
    "sdgBudgetProgramLvlCfyM3",
    "sdgExpensesProgramLvlCfyM3",
  ],
  facDevelop: [
    "facDevelopBudgetCfy",
    "facDevelopExpensesCfy",
    "facDevelopBudgetCfyM1",
    "facDevelopExpensesCfyM1",
    "facDevelopBudgetCfyM2",
    "facDevelopExpensesCfyM2",
    "facDevelopBudgetCfyM3",
    "facDevelopExpensesCfyM3",
  ],
  rd: [
    "rdBudgetProgramLvlCfy",
    "rdExpensesProgramLvlCfy",
    "rdBudgetProgramLvlCfyM1",
    "rdExpensesProgramLvlCfyM1",
    "rdBudgetProgramLvlCfyM2",
    "rdExpensesProgramLvlCfyM2",
    "rdBudgetProgramLvlCfyM3",
    "rdExpensesProgramLvlCfyM3",
  ],
  training: [
    "trainingBudgetCfy",
    "trainingExpensesCfy",
    "trainingBudgetCfyM1",
    "trainingExpensesCfyM1",
    "trainingBudgetCfyM2",
    "trainingExpensesCfyM2",
    "trainingBudgetCfyM3",
    "trainingExpensesCfyM3",
  ],
  miscellaneous: [
    "miscellaneousBudgetCfy",
    "miscellaneousExpensesCfy",
    "miscellaneousBudgetCfyM1",
    "miscellaneousExpensesCfyM1",
    "miscellaneousBudgetCfyM2",
    "miscellaneousExpensesCfyM2",
    "miscellaneousBudgetCfyM3",
    "miscellaneousExpensesCfyM3",
  ],
};

const BudgetExpenditureItemsProgramLvl = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [budgetExpenditure, setBudgetExpenditure] = useState({
    labBudgetProgramLvlCfy: "",
    labBudgetProgramLvlCfyM1: "",
    labBudgetProgramLvlCfyM2: "",
    labBudgetProgramLvlCfyM3: "",
    labExpensesProgramLvlCfy: "",
    labExpensesProgramLvlCfyM1: "",
    labExpensesProgramLvlCfyM2: "",
    labExpensesProgramLvlCfyM3: "",
    softwareBudgetCfy: "",
    softwareBudgetCfyM1: "",
    softwareBudgetCfyM2: "",
    softwareBudgetCfyM3: "",
    softwareExpensesCfy: "",
    softwareExpensesCfyM1: "",
    softwareExpensesCfyM2: "",
    softwareExpensesCfyM3: "",
    sdgBudgetProgramLvlCfy: "",
    sdgBudgetProgramLvlCfyM1: "",
    sdgBudgetProgramLvlCfyM2: "",
    sdgBudgetProgramLvlCfyM3: "",
    sdgExpensesProgramLvlCfy: "",
    sdgExpensesProgramLvlCfyM1: "",
    sdgExpensesProgramLvlCfyM2: "",
    sdgExpensesProgramLvlCfyM3: "",
    facDevelopBudgetCfy: "",
    facDevelopBudgetCfyM1: "",
    facDevelopBudgetCfyM2: "",
    facDevelopBudgetCfyM3: "",
    facDevelopExpensesCfy: "",
    facDevelopExpensesCfyM1: "",
    facDevelopExpensesCfyM2: "",
    facDevelopExpensesCfyM3: "",
    rdBudgetProgramLvlCfy: "",
    rdBudgetProgramLvlCfyM1: "",
    rdBudgetProgramLvlCfyM2: "",
    rdBudgetProgramLvlCfyM3: "",
    rdExpensesProgramLvlCfy: "",
    rdExpensesProgramLvlCfyM1: "",
    rdExpensesProgramLvlCfyM2: "",
    rdExpensesProgramLvlCfyM3: "",
    trainingBudgetCfy: "",
    trainingBudgetCfyM1: "",
    trainingBudgetCfyM2: "",
    trainingBudgetCfyM3: "",
    trainingExpensesCfy: "",
    trainingExpensesCfyM1: "",
    trainingExpensesCfyM2: "",
    trainingExpensesCfyM3: "",
    miscellaneousBudgetCfy: "",
    miscellaneousBudgetCfyM1: "",
    miscellaneousBudgetCfyM2: "",
    miscellaneousBudgetCfyM3: "",
    miscellaneousExpensesCfy: "",
    miscellaneousExpensesCfyM1: "",
    miscellaneousExpensesCfyM2: "",
    miscellaneousExpensesCfyM3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const reqBody = {
        budgetExpenditureItemsProgramLvlId:
          metricInfo?.[template]?.[0]?.[
            "budget_expenditure_items_program_lvl_id"
          ] ?? "",
        ...budgetExpenditure,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateBudgetExpenditureData = (event) => {
    setBudgetExpenditure({
      ...budgetExpenditure,
      [event.target.name]: event.target.value,
    });
  };

  const totalAmount = () => {
    const columnTotals = Object.keys(fieldGroups).reduce((totals, group) => {
      fieldGroups[group].forEach((field, index) => {
        totals[index] =
          (totals[index] || 0) + (Number(budgetExpenditure[field]) || 0);
      });
      return totals;
    }, []);

    return (
      <tr>
        <td>Total</td>
        {columnTotals.map((total, index) => (
          <td key={index} className={styles["total-col"]}>
            {total.toFixed(2)}
          </td>
        ))}
      </tr>
    );
  };

  const renderStructure = () => {
    return categories.map(({ name, label }) => (
      <tr>
        <td>{name}</td>
        {fieldGroups[label].map((fields) => (
          <td className={styles["input-col"]}>
            <input
              value={budgetExpenditure[fields]}
              onChange={updateBudgetExpenditureData}
              name={fields}
              min={0}
              step={0.01}
              type="number"
              required
            />
          </td>
        ))}
      </tr>
    ));
  };

  useEffect(() => {
    const data = {
      labBudgetProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["lab_budget_cfy"] ?? "",
      labBudgetProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["lab_budget_cfym1"] ?? "",
      labBudgetProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["lab_budget_cfym2"] ?? "",
      labBudgetProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["lab_budget_cfym3"] ?? "",

      labExpensesProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfy"] ?? "",
      labExpensesProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym1"] ?? "",
      labExpensesProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym2"] ?? "",
      labExpensesProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["lab_expenses_cfym3"] ?? "",

      softwareBudgetCfy:
        metricInfo?.[template]?.[0]?.["software_budget_cfy"] ?? "",
      softwareBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["software_budget_cfym1"] ?? "",
      softwareBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["software_budget_cfym2"] ?? "",
      softwareBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["software_budget_cfym3"] ?? "",

      softwareExpensesCfy:
        metricInfo?.[template]?.[0]?.["software_expenses_cfy"] ?? "",
      softwareExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["software_expenses_cfym1"] ?? "",
      softwareExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["software_expenses_cfym2"] ?? "",
      softwareExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["software_expenses_cfym3"] ?? "",

      sdgBudgetProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["sdg_budget_cfy"] ?? "",
      sdgBudgetProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["sdg_budget_cfym1"] ?? "",
      sdgBudgetProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["sdg_budget_cfym2"] ?? "",
      sdgBudgetProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["sdg_budget_cfym3"] ?? "",

      sdgExpensesProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfy"] ?? "",
      sdgExpensesProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym1"] ?? "",
      sdgExpensesProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym2"] ?? "",
      sdgExpensesProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["sdg_expenses_cfym3"] ?? "",

      facDevelopBudgetCfy:
        metricInfo?.[template]?.[0]?.["fac_develop_budget_cfy"] ?? "",
      facDevelopBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["fac_develop_budget_cfym1"] ?? "",
      facDevelopBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["fac_develop_budget_cfym2"] ?? "",
      facDevelopBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["fac_develop_budget_cfym3"] ?? "",

      facDevelopExpensesCfy:
        metricInfo?.[template]?.[0]?.["fac_develop_expenses_cfy"] ?? "",
      facDevelopExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["fac_develop_expenses_cfym1"] ?? "",
      facDevelopExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["fac_develop_expenses_cfym2"] ?? "",
      facDevelopExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["fac_develop_expenses_cfym3"] ?? "",

      rdBudgetProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["rd_budget_cfy"] ?? "",
      rdBudgetProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["rd_budget_cfym1"] ?? "",
      rdBudgetProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["rd_budget_cfym2"] ?? "",
      rdBudgetProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["rd_budget_cfym3"] ?? "",

      rdExpensesProgramLvlCfy:
        metricInfo?.[template]?.[0]?.["rd_expenses_cfy"] ?? "",
      rdExpensesProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["rd_expenses_cfym1"] ?? "",
      rdExpensesProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["rd_expenses_cfym2"] ?? "",
      rdExpensesProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["rd_expenses_cfym3"] ?? "",

      trainingBudgetCfy:
        metricInfo?.[template]?.[0]?.["training_budget_cfy"] ?? "",
      trainingBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["training_budget_cfym1"] ?? "",
      trainingBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["training_budget_cfym2"] ?? "",
      trainingBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["training_budget_cfym3"] ?? "",

      trainingExpensesCfy:
        metricInfo?.[template]?.[0]?.["training_expenses_cfy"] ?? "",
      trainingExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["training_expenses_cfym1"] ?? "",
      trainingExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["training_expenses_cfym2"] ?? "",
      trainingExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["training_expenses_cfym3"] ?? "",

      miscellaneousBudgetCfy:
        metricInfo?.[template]?.[0]?.["miscellaneous_budget_cfy"] ?? "",
      miscellaneousBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["miscellaneous_budget_cfym1"] ?? "",
      miscellaneousBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["miscellaneous_budget_cfym2"] ?? "",
      miscellaneousBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["miscellaneous_budget_cfym3"] ?? "",

      miscellaneousExpensesCfy:
        metricInfo?.[template]?.[0]?.["miscellaneous_expenses_cfy"] ?? "",
      miscellaneousExpensesCfyM1:
        metricInfo?.[template]?.[0]?.["miscellaneous_expenses_cfym1"] ?? "",
      miscellaneousExpensesCfyM2:
        metricInfo?.[template]?.[0]?.["miscellaneous_expenses_cfym2"] ?? "",
      miscellaneousExpensesCfyM3:
        metricInfo?.[template]?.[0]?.["miscellaneous_expenses_cfym3"] ?? "",
    };

    setBudgetExpenditure(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col ">
      <table className={styles["budget-expenditure-items-table"]}>
        <thead>
          <tr>
            <th>Items</th>
            <th>Budgeted in CFY</th>
            <th>Actual expenses in CFY</th>
            <th>Budgeted in CFYm1</th>
            <th>Actual expenses in CFYm1</th>
            <th>Budgeted in CFYm2</th>
            <th>Actual expenses in CFYm2</th>
            <th>Budgeted in CFYm3</th>
            <th>Actual expenses in CFYm3</th>
          </tr>
        </thead>
        <tbody>
          {renderStructure()}
          {totalAmount()}
        </tbody>
      </table>
      <button type="submit" className={styles["save-btn"]}>
        Save
      </button>
    </form>
  );
};

export default BudgetExpenditureItemsProgramLvl;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const BudgetExpenditureSummaryInstituteLvl = (props) => {
  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const [budgetExpenditure, setBudgetExpenditure] = useState({
    feeCfyM1: "",
    feeCfyM2: "",
    feeCfyM3: "",
    govtCfyM1: "",
    govtCfyM2: "",
    govtCfyM3: "",
    grantsCfyM1: "",
    grantsCfyM2: "",
    grantsCfyM3: "",
    otherSourcesCfyM1: "",
    otherSourcesCfyM2: "",
    otherSourcesCfyM3: "",
    actualExpenditureCfyM1: "",
    actualExpenditureCfyM2: "",
    actualExpenditureCfyM3: "",
    totalStudentsCfyM1: "",
    totalStudentsCfyM2: "",
    totalStudentsCfyM3: "",
    expenditureStudentCfyM1: "",
    expenditureStudentCfyM2: "",
    expenditureStudentCfyM3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const reqBody = {
        budgetExpenditureSummaryInstituteLvlId:
          metricInfo?.[template]?.[0]?.[
            "budget_expenditure_summary_institute_lvl_id"
          ] ?? "",
        ...budgetExpenditure,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateBudgetExpenditureData = (event) => {
    setBudgetExpenditure({
      ...budgetExpenditure,
      [event.target.name]: event.target.value,
    });
  };

  const renderStructure = (year) => (
    <tr>
      <td>
        <input
          name={`fee${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`fee${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`govt${year}`}
          value={budgetExpenditure[`govt${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`grants${year}`}
          value={budgetExpenditure[`grants${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`otherSources${year}`}
          value={budgetExpenditure[`otherSources${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>
      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`actualExpenditure${year}`}
          value={budgetExpenditure[`actualExpenditure${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`totalStudents${year}`}
          value={budgetExpenditure[`totalStudents${year}`]}
          type="number"
          min={0}
          required
        />
      </td>
      <td>
        <input
          onChange={updateBudgetExpenditureData}
          name={`expenditureStudent${year}`}
          value={budgetExpenditure[`expenditureStudent${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>
    </tr>
  );

  useEffect(() => {
    const data = {
      feeCfyM1: metricInfo?.[template]?.[0]?.["fee_cfym1"] ?? "",

      feeCfyM2: metricInfo?.[template]?.[0]?.["fee_cfym2"] ?? "",

      feeCfyM3: metricInfo?.[template]?.[0]?.["fee_cfym3"] ?? "",

      govtCfyM1: metricInfo?.[template]?.[0]?.["govt_cfym1"] ?? "",

      govtCfyM2: metricInfo?.[template]?.[0]?.["govt_cfym2"] ?? "",

      govtCfyM3: metricInfo?.[template]?.[0]?.["govt_cfym3"] ?? "",

      grantsCfyM1: metricInfo?.[template]?.[0]?.["grants_cfym1"] ?? "",

      grantsCfyM2: metricInfo?.[template]?.[0]?.["grants_cfym2"] ?? "",

      grantsCfyM3: metricInfo?.[template]?.[0]?.["grants_cfym3"] ?? "",

      otherSourcesCfyM1:
        metricInfo?.[template]?.[0]?.["other_sources_cfym1"] ?? "",

      otherSourcesCfyM2:
        metricInfo?.[template]?.[0]?.["other_sources_cfym2"] ?? "",

      otherSourcesCfyM3:
        metricInfo?.[template]?.[0]?.["other_sources_cfym3"] ?? "",

      actualExpenditureCfyM1:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym1"] ?? "",

      actualExpenditureCfyM2:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym2"] ?? "",

      actualExpenditureCfyM3:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym3"] ?? "",

      totalStudentsCfyM1:
        metricInfo?.[template]?.[0]?.["total_students_cfym1"] ?? "",

      totalStudentsCfyM2:
        metricInfo?.[template]?.[0]?.["total_students_cfym2"] ?? "",

      totalStudentsCfyM3:
        metricInfo?.[template]?.[0]?.["total_students_cfym3"] ?? "",

      expenditureStudentCfyM1:
        metricInfo?.[template]?.[0]?.["expenditure_student_cfym1"] ?? "",

      expenditureStudentCfyM2:
        metricInfo?.[template]?.[0]?.["expenditure_student_cfym2"] ?? "",

      expenditureStudentCfyM3:
        metricInfo?.[template]?.[0]?.["expenditure_student_cfym3"] ?? "",
    };

    setBudgetExpenditure(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["budget-expenditure-table"]}>
        <thead>
          <tr>
            <th colSpan={4}> Total Income in the CFYm1</th>
            <th rowSpan={2}>Actual expenditure in the CFYm1</th>
            <th rowSpan={2}>Total Students in the institute</th>
            <th rowSpan={2}>Expenditure per student in CFYm1:</th>
          </tr>

          <tr>
            <th>Fee</th>
            <th>Govt.</th>
            <th>Grant(s)</th>
            <th>Other Sources(specify)</th>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM1")}</tbody>
      </table>

      <table className={styles["budget-expenditure-table"]}>
        <thead>
          <tr>
            <th colSpan={4}> Total Income in the CFYm2</th>
            <th rowSpan={2}>Actual expenditure in the CFYm2</th>
            <th rowSpan={2}>Total Students in the institute</th>
            <th rowSpan={2}>Expenditure per student in CFYm2:</th>
          </tr>

          <tr>
            <th>Fee</th>
            <th>Govt.</th>
            <th>Grant(s)</th>
            <th>Other Sources(specify)</th>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM2")}</tbody>
      </table>

      <table className={styles["budget-expenditure-table"]}>
        <thead>
          <tr>
            <th colSpan={4}> Total Income in the CFYm3</th>
            <th rowSpan={2}>Actual expenditure in the CFYm3</th>
            <th rowSpan={2}>Total Students in the institute</th>
            <th rowSpan={2}>Expenditure per student in CFYm3:</th>
          </tr>

          <tr>
            <th>Fee</th>
            <th>Govt.</th>
            <th>Grant(s)</th>
            <th>Other Sources(specify)</th>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM3")}</tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default BudgetExpenditureSummaryInstituteLvl;

import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

const MetricsList = (props) => {
  const { item, projectId } = props;
  const {
    metricsId,
    metricNo,
    metricStatement,
    authors,
    approvers,
    startDate,
    endDate,
    progress,
  } = item;

  const progressStyle = {
    width: `${progress}%`,
  };

  const metricDataRoute = () => {
    const { history } = props;

    history.push(`/connect/dashboard/metrics-data/${projectId}/${metricsId}`);
  };

  return (
    <tr onClick={metricDataRoute} className="cursor-pointer">
      <td>{metricNo}</td>
      <td className={styles["metric-table-desc"]} colSpan={2}>
        {metricStatement}
      </td>
      <td className={styles["metric-table-name"]}>
        <div className={styles["metric-bar-container"]}>
          <span className={`absolute left-1/3 text-[#000]`}>{progress}%</span>
          <div className={styles["metric-bar"]} style={progressStyle}></div>
        </div>
      </td>
      <td className={styles["metric-table-name"]}>{authors}</td>
      <td className={styles["metric-table-name"]}>{approvers}</td>
      <td className={styles["metric-table-date"]}>
        {startDate ? startDate : ""}
      </td>
      <td className={styles["metric-table-date"]}>{endDate ? endDate : ""}</td>
    </tr>
  );
};

export default withRouter(MetricsList);

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyPublicationDetails = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [facultyPublicationData, setFacultyPublicationData] = useState({
    journalsCayM1: "",
    journalsCayM2: "",
    journalsCayM3: "",
    conferenceCayM1: "",
    conferenceCayM2: "",
    conferenceCayM3: "",
    chaptersCayM1: "",
    chaptersCayM2: "",
    chaptersCayM3: "",
  });

  const updateFacultyPublicationData = (event) => {
    setFacultyPublicationData({
      ...facultyPublicationData,
      [event.target.name]: event.target.value,
    });
  };

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        journalsCayM1,
        journalsCayM2,
        journalsCayM3,
        conferenceCayM1,
        conferenceCayM2,
        conferenceCayM3,
        chaptersCayM1,
        chaptersCayM2,
        chaptersCayM3,
      } = facultyPublicationData;

      const reqBody = {
        facultyPublicationDetailsId:
          metricInfo?.[template]?.[0]?.["faculty_publication_details_id"] ?? "",
        journalsCaym1: journalsCayM1,
        journalsCaym2: journalsCayM2,
        journalsCaym3: journalsCayM3,
        conferenceCaym1: conferenceCayM1,
        conferenceCaym2: conferenceCayM2,
        conferenceCaym3: conferenceCayM3,
        chaptersCaym1: chaptersCayM1,
        chaptersCaym2: chaptersCayM2,
        chaptersCaym3: chaptersCayM3,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  useEffect(() => {
    const data = {
      journalsCayM1: metricInfo?.[template]?.[0]?.["journals_caym1"] ?? "",
      journalsCayM2: metricInfo?.[template]?.[0]?.["journals_caym2"] ?? "",
      journalsCayM3: metricInfo?.[template]?.[0]?.["journals_caym3"] ?? "",
      conferenceCayM1: metricInfo?.[template]?.[0]?.["conference_caym1"] ?? "",
      conferenceCayM2: metricInfo?.[template]?.[0]?.["conference_caym2"] ?? "",
      conferenceCayM3: metricInfo?.[template]?.[0]?.["conference_caym3"] ?? "",
      chaptersCayM1: metricInfo?.[template]?.[0]?.["chapters_caym1"] ?? "",
      chaptersCayM2: metricInfo?.[template]?.[0]?.["chapters_caym2"] ?? "",
      chaptersCayM3: metricInfo?.[template]?.[0]?.["chapters_caym3"] ?? "",
    };

    setFacultyPublicationData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["student-enroll-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAY</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No. of peer reviewed journal papers published</td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.journalsCayM1}
                onChange={updateFacultyPublicationData}
                name="journalsCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.journalsCayM2}
                onChange={updateFacultyPublicationData}
                name="journalsCayM2"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.journalsCayM3}
                onChange={updateFacultyPublicationData}
                name="journalsCayM3"
              />
            </td>
          </tr>

          <tr>
            <td>No. of peer reviewed conference papers published</td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.conferenceCayM1}
                onChange={updateFacultyPublicationData}
                name="conferenceCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.conferenceCayM2}
                onChange={updateFacultyPublicationData}
                name="conferenceCayM2"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.conferenceCayM3}
                onChange={updateFacultyPublicationData}
                name="conferenceCayM3"
              />
            </td>
          </tr>

          <tr>
            <td>No. of books/book chapters published</td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.chaptersCayM1}
                onChange={updateFacultyPublicationData}
                name="chaptersCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.chaptersCayM2}
                onChange={updateFacultyPublicationData}
                name="chaptersCayM2"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={facultyPublicationData?.chaptersCayM3}
                onChange={updateFacultyPublicationData}
                name="chaptersCayM3"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default FacultyPublicationDetails;

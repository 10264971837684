const BASE_API_URL = "https://backend.fatcat.net.in:8080/api";
const BASE_API_URL_NODE = "https://backend.fatcat.net.in:3001/api";

// const BASE_API_URL_NODE = "http://localhost:3001/api";

export const LOGIN_API_URL = BASE_API_URL + "/fatcat/login";
export const SIGN_IN_URL = BASE_API_URL + "/fatcat/sign-in";
export const OPTIONS_API = BASE_API_URL + "/fatcat/option";

export const DASHBOARD_OVERALL_STATISSTICS_API_URL =
  BASE_API_URL + "/home/dashboard/overallstatistics";
export const STUDENT_ATTENDANCE_GRAPH =
  BASE_API_URL + "/home/dashboard/studentattendancegraph";
export const STUDENT_ANALYTICS_GRAPH =
  BASE_API_URL + "/home/dashboard/studentanalyticsgraph";
export const ANALYTICS_AVG_SEM_ATD =
  BASE_API_URL + "/home/analytics/avergeBrachSemAttd";
export const INFO_DOWNLOAD = BASE_API_URL + "/home/info/download";

export const DASHBOARD_PROGRAM_ART_MATRIX =
  BASE_API_URL + "/obe/get-program-articulation-matrix";

// OBE DASHBOARD API'S

//STUDENTS CO ATTAINMENT DASHBOARD

export const OBE_STUDENTS_STUDENTS_EXCEL_TEMPLATE =
  BASE_API_URL_NODE + "/obe-students/students/excel-template";

export const OBE_STUDENTS_STUDENTS_BULK =
  BASE_API_URL_NODE + "/obe-students/students/bulk";

export const OBE_STUDENTS_STUDENTS =
  BASE_API_URL_NODE + "/obe-students/students";

export const OBE_STUDENTS_STUDENTS_CO_ATTAINMENTS =
  BASE_API_URL_NODE + "/obe-students/students/co-attainments";

export const OBE_STUDENTS_STUDENTS_COURSES_CO_ATTAINMENTS =
  BASE_API_URL_NODE + "/obe-students/students/courses-co-attainments";

export const OBE_STUDENTS_STUDENTS_STD_ATTAINMENTS_GROWTH_GRAPH =
  BASE_API_URL_NODE + "/obe-students/students/std-attainment-growth-graph";

export const OBE_STUDENTS_STUDENTS_STD_FAC_RATIO =
  BASE_API_URL_NODE + "/obe-students/students/std-fac-ratio";

export const OBE_STUDENTS_NEW_STUDENT =
  BASE_API_URL_NODE + "/obe-students/students/add-student";

export const OBE_STUDENTS_UPDATE_STUDENT =
  BASE_API_URL_NODE + "/obe-students/students/update-student";

export const OBE_STUDENTS_DELETE_STUDENT =
  BASE_API_URL_NODE + "/obe-students/students/delete-student";

//INDIVIDUAL STUDENT DATA

export const OBE_STUDENTS_STUDENTS_STD_INFO =
  BASE_API_URL_NODE + "/obe-students/students/std-info";

export const OBE_STUDENTS_STUDENTS_STD_COURSES =
  BASE_API_URL_NODE + "/obe-students/students/std-courses";

export const OBE_STUDENTS_STUDENTS_STD_COURSES_CO_ATTAINMENTS =
  BASE_API_URL_NODE + "/obe-students/students/std-course-co-attainments";

// OBE COURSES MANAGE COURSES API'S

export const OBE_FACULTY_MANAGE_COURSES_LIST =
  BASE_API_URL + "/obe/faculty/dashboard/manage-courses";

export const OBE_FACULTY_MANAGE_COURSES_PROGRESS =
  BASE_API_URL + "/obe/admin/courses/manage-courses/progress";

export const OBE_COURSES_MANAGE_COURSES_CREATE_COURSE =
  BASE_API_URL + "/obe/admin/courses/new-course";

export const COURSE_STRUCTURES =
  BASE_API_URL + "/obe/admin/courses/course-structures";

export const OBE_COURSES_NEW_COURSE_STRUCTURE =
  BASE_API_URL + "/obe/admin/courses/new-course-struct";

export const OBE_ADMIN_COURSES_MODIFY_WEIGHTAGES =
  BASE_API_URL + "/obe/admin/courses/manage-courses/modify-weightages";

//OBE COURSES ANALYSIS API'S

//FACULTY PAGE FACULTY COURSE MAPPING PAGE

export const FACULTY_FOR_COURSE_MAPPING =
  BASE_API_URL + "/obe/get-faculty-for-course-mapping";

//

export const FACULTY_DETAILS =
  BASE_API_URL + "/obe/admin/faculty/manage-faculty";

export const COURSE_ANALYSIS_CODE_OPTIONS =
  BASE_API_URL +
  "/obe/admin/courses/analysis/course-analysis/course-code-options";

export const COURSE_ANALYSIS = BASE_API_URL + "/obe/course-attainment-details";
// '/obe/admin/courses/analysis/course-analysis/course-attainment';

export const COURSE_ANALYSIS_CO_ATT =
  BASE_API_URL + "/obe/co-flow-final-att-data";

export const OBE_FACULTY_CREATE_FACULTY =
  BASE_API_URL + "/obe/admin/faculty/create-faculty/create-faculty";

export const OBE_ADMIN_COURSES_ANALYSIS_AVG_ATTAINMENT_TABLE =
  BASE_API_URL + "/obe/admin/courses/analysis/avg-branch-attainments-table";

export const OBE_FACULTY_COURSE_MAPPING =
  BASE_API_URL + "/obe/admin/faculty/create-faculty/faculty-course";

export const OBE_FACULTY_FEEDBACK =
  BASE_API_URL +
  "/obe/faculty/dashboard/manage-courses/feedback/store-feedback";

export const OBE_FACULTY_CREATE_CO_OUTCOME =
  BASE_API_URL + "/obe/faculty/dashboard/courses/store-cos";

export const OBE_FACULTY_UPLOAD_TEMPLATE =
  BASE_API_URL + "/obe/faculty/dashboard/courses/upload-templates";

export const OBE_COURSES_UPDATE_COURSE_STRUCTURE =
  BASE_API_URL + "/obe/admin/courses/update-course-structure";

export const OBE_FACULTY_COURSES_DOWNLOAD_TEMPLATE =
  BASE_API_URL + "/obe/faculty/dashboard/courses/download-templates";

export const PO_PSO_ATTAINMENT =
  BASE_API_URL + "/obe/admin/courses/analysis/course-analysis/po-pso-data";

export const OBE_ADMIN_COURSES_BATCH_WEIGHTAGES =
  BASE_API_URL + "/obe/admin/courses/manage-courses/batch-weightage";

export const OBE_COURSES_ANALYSIS_DOWNLOAD_REPORT =
  BASE_API_URL + "/obe/admin/courses/analysis/get-report-details";

export const FACULTY_CHECK_STATUS_POPUP =
  BASE_API_URL + "/obe/admin/courses/manage-courses/check-status";

export const FACULTY_FEEDBACK_CO_DESCRIPTION =
  BASE_API_URL +
  "/obe/faculty/dashboard/manage-courses/feedback/get-co-description";

export const FACULTY_ART_MATRIX =
  BASE_API_URL + "/obe/faculty/dashboard/courses/get-cos";

export const FACULTY_FB_VALUES =
  BASE_API_URL + "/obe/faculty/dashboard/manage-courses/feedback/get-feedback";

export const OBE_ADMIN_COURSES_ANALYSIS_AVG_ATTAINMENT_GRAPH =
  BASE_API_URL + "/obe/admin/courses/analysis/avg-branch-attainments-graph";

export const OBE_ADMIN_COURSES_ANALYSIS_ATT_BREAKDOWN_GRAPH =
  BASE_API_URL +
  "/obe/admin/courses/analysis/course-attainment-breakdown-graph";

export const FACULTY_MANAGE_COURSES_LIST = BASE_API_URL + "/obe/faculty";

export const OBE_COURSE_STRUCT_DELETE =
  BASE_API_URL + "/obe/admin/courses/delete-course-structure";

export const DELETE_FACULTY =
  BASE_API_URL + "/obe/admin/faculty/delete-faculty";

export const CAMPUS_SETUP_DEPT_INFO = BASE_API_URL + "/my-account/dept-info";

export const CAMPUS_SETUP_REG_BATCH_INFO =
  BASE_API_URL + "/my-account/regulation-info";

export const CAMPUS_SETUP_STORE_REG_INFO =
  BASE_API_URL + "/my-account/store-regulation-info";

export const CAMPUS_SETUP_STORE_BATCH_INFO =
  BASE_API_URL + "/my-account/store-batch-info";

export const CAMPUS_SETUP_STORE_DEPT_INFO =
  BASE_API_URL + "/my-account/store-dept-info";

export const MY_ACCOUNT_INFO = BASE_API_URL + "/my-account/info";

export const MY_ACCOUNT_DELETE_DP =
  BASE_API_URL + "/my-account/delete-profile-photo";

export const MY_ACCOUNT_UPLOAD_DP =
  BASE_API_URL + "/my-account/upload-profile-photo";

export const MY_ACCOUNT_STORE_ACCOUNT_INFO =
  BASE_API_URL + "/my-account/store-account-info";

export const CHECK_USERNAME =
  BASE_API_URL + "/obe/admin/faculty/create-faculty/check-username";

export const UPDATE_FACULTY = BASE_API_URL + "/obe/admin/faculty/update";

export const MANAGE_CAMPUS_STORE_PSO = BASE_API_URL + "/my-account/store-psos";

export const MANAGE_CAMPUS_DELETE_PSO = BASE_API_URL + "/my-account/delete-pso";

export const MANAGE_CAMPUS_PSO = BASE_API_URL + "/my-account/get-psos";

export const OBE_ADMIN_COURSES_ANALYSIS_COURSE_ATT_BREAKDOWN_TABLE =
  BASE_API_URL +
  "/obe/admin/courses/analysis/course-attainment-breakdown-table";

export const PDF_DATA = BASE_API_URL + "/obe/po-pso-for-report";

export const DEFAULT_RUBRICS = BASE_API_URL + "/obe/default-rubrics";

export const INSERT_BULK_FACULTY = BASE_API_URL + "/obe/bulk-insert-faculty";

export const INSERT_BULK_COURSES = BASE_API_URL + "/obe/bulk-insert-courses";

export const OBE_COURSES_ITEM_ANALYSIS = BASE_API_URL + "/obe/item-analysis";

export const OBE_CATEGORIZATION_QSTNS_BLOOM_LVL =
  BASE_API_URL + "/obe/bloom-lvl-question-analysis";

export const OBE_FACULTY_ANALYSIS_HEAT_MAP =
  BASE_API_URL + "/obe/faculty-heat-map";

export const OBE_DELETE_COURSE =
  BASE_API_URL + "/obe/admin/courses/delete-course";

export const OBE_UPDATE_COURSE =
  BASE_API_URL + "/obe/admin/courses/update-course";

export const EXCEl_FILES = BASE_API_URL + "/obe/download-sample-excel-file";

export const PO_DATA = BASE_API_URL + "/my-account/get-pos";

export const OBE_DASHBOARD_PO_ATTAINMENT_DETAILS =
  BASE_API_URL + "/obe/po-attainment-details";

export const OBE_DASHBOARD_PO_DA_ATTAINMENT_AY =
  BASE_API_URL + "/obe/po-da-attainment-details-ay";

export const OBE_COURSES_IDA_PO_ASSESSMENT =
  BASE_API_URL + "/obe/store-ida-po-assmnt";

export const GET_OTP = BASE_API_URL + "/my-account/get-otp";

export const RESET_PASSWORD = BASE_API_URL + "/my-account/reset-password";

export const FACULTY_ANALYSE_QUESTION_PAPER =
  BASE_API_URL + "/obe/analyse-question-paper";

export const FEEDBACK_AND_SUPPORT = BASE_API_URL + "/support/raise-support";

export const GAP_ANALYSIS = BASE_API_URL + "/obe/po-spider-web";

export const GAP_ANALYSIS_AY = BASE_API_URL + "/obe/po-spider-web-ay";

export const FACULTY_JUSTIFICATION = BASE_API_URL + "/obe/match-pis";

export const FACULTY_JUSTIFICATION_UPDATE =
  BASE_API_URL + "/obe/update-matched-pis";

export const OBE_MANAGE_COURSES_CROSS_BRANCH =
  BASE_API_URL + "/obe/hod/faculty/cross-dept-courses";

export const OBE_UPDATE_ARTICULATION_MATRIX_ONLY =
  BASE_API_URL + "/obe/update-articulation-matrix";

export const OBE_UPDATE_COURSE_OUTCOMES =
  BASE_API_URL + "/obe/update-course-outcomes";

export const OBE_GET_WEIGHTS_TEMPLATE =
  BASE_API_URL + "/obe/fetch-template-weightages";

export const OBE_UPDATE_WEIGHTS_TEMPLATE =
  BASE_API_URL + "/obe/store-template-weightages";

export const GET_SECTIONS = BASE_API_URL + "/my-account/get-sections";

export const STORE_SECTIONS = BASE_API_URL + "/my-account/store-sections-info";

export const UPDATE_CO_WEIGHTAGES = BASE_API_URL + "/obe/update-co-weightages";

export const DELETE_COURSE_MAPPING =
  BASE_API_URL + "/obe/delete-faculty-course-mapping";

export const DELETE_COS = BASE_API_URL + "/obe/delete-course-outcomes";

export const BULK_MIGRATION = BASE_API_URL + "/obe/bulk-migrate-courses";

export const COURSE_WEIGHTAGES =
  BASE_API_URL + "/obe/fetch-co-specific-template-weightages";

export const STORE_COURSE_WEIGHTAGES =
  BASE_API_URL + "/obe/store-co-specific-template-weightages";

export const ARE_PIS_MATCHED = BASE_API_URL + "/obe/are-pis-matched";

export const OBE_UPDATE_RUBRICS = BASE_API_URL + "/obe/update-rubrics";

export const OBE_EXAM_PAPER_FETCH =
  BASE_API_URL + "/question-paper/fetch-exam-paper-analysis";

export const OBE_EXAM_PAPER_UPDATE =
  BASE_API_URL + "/question-paper/update-exam-paper-analysis";

export const OBE_EXAM_PAPER_UPLOAD =
  BASE_API_URL + "/question-paper/upload-exam-paper";

export const OBE_QUESTION_PAPER_ANALYSIS =
  BASE_API_URL_NODE + "/obe/question-paper/analysis";

export const OBE_QUESTION_PAPER_UPLOAD_STATUS =
  BASE_API_URL_NODE + "/obe/question-paper/upload-status";

export const OBE_ATTAINMENTS_TEMPLATE_MARKS =
  BASE_API_URL + "/obe/template-course-attainment-details";

export const OBE_ATTAINMENTS_CO_SPECIFIC_TEMPLATE_WEIGHTAGE_UPDATE =
  BASE_API_URL + "/obe/store-co-specific-template-weightages";

export const OBE_ATTAINMENTS_CO_SPECIFIC_TARGET_WEIGHTAGE_UPDATE =
  BASE_API_URL + "/obe/store-co-specific-target-weightages";

export const OBE_ATTAINMENTS_STORE_ATT_OBSERVATION =
  BASE_API_URL + "/obe/store-course-att-observations";

export const OBE_ATTAINMENTS_GET_ATT_OBSERVATION =
  BASE_API_URL + "/obe/get-course-att-observations";

export const OBE_ATTAINMENTS_PO_PSO_ATT_CALCULATIONS =
  BASE_API_URL + "/obe/get-po-pso-att-calculations";

export const OBE_ATTAINMENTS_COURSE_ASS_MARKS_DIST =
  BASE_API_URL + "/obe/get-co-pct-marks-distribution";

export const OBE_ATTAINMENTS_INTERNAL_COURSE_ATT_DETAILS =
  BASE_API_URL + "/obe/internal-course-attainment-details";

export const OBE_ATTAINMENTS_CO_SPECIFIC_TH_VALUE =
  BASE_API_URL + "/obe/fetch-co-specific-th-values";

export const OBE_ATTAINMENTS_STORE_CO_SPECIFIC_TH_VALUE =
  BASE_API_URL + "/obe/store-co-specific-threshold-values";

export const OBE_ATTAINMENTS_CO_TEMPLATE_MARKS =
  BASE_API_URL + "/obe/co-course-attainment-details";

// ----------------- CONNECT MODULE ---------------------

// Dashboard Page

export const CONNECT_DASHBOARD_PROJECTS =
  BASE_API_URL_NODE + "/connect/projects";
export const CONNECT_DASHBOARD_CRITERIA =
  BASE_API_URL_NODE + "/connect/criteria";
export const CONNECT_DASHBOARD_CRITERIA_ALL =
  BASE_API_URL_NODE + "/connect/criteria/all";

export const CONNECT_DASHBOARD_METRIC = BASE_API_URL_NODE + "/connect/metrics";

export const CONNECT_DASHBOARD_METRIC_UNASSIGNED =
  BASE_API_URL_NODE + "/connect/metrics/unassigned";

export const CONNECT_DASHBOARD_METRIC_DATA =
  BASE_API_URL_NODE + "/connect/metrics-data";

export const CONNECT_DASHBOARD_METRIC_DATA_IMG_UPLOAD =
  BASE_API_URL_NODE + "/connect/metrics-data/upload";

export const CONNECT_DASHBOARD_METRIC_ASSIGNED =
  BASE_API_URL_NODE + "/connect/metrics/assigned";

export const CONNECT_DASHBOARD_METRIC_DATA_STORE_CORE_COURSES =
  BASE_API_URL_NODE + "/connect/metrics-data/store-core-courses";

export const CONNECT_DASHBOARD_METRIC_DATA_STATUS_UPDATE =
  BASE_API_URL_NODE + "/connect/metrics-data/update-status";

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyCadreProportion = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [facultyCadreData, setFacultyCadreData] = useState({
    requiredProfessorsCay: "",
    requiredProfessorsCaym1: "",
    requiredProfessorsCaym2: "",
    availableProfessorsCay: "",
    availableProfessorsCaym1: "",
    availableProfessorsCaym2: "",
    requiredAssociateProfessorsCay: "",
    requiredAssociateProfessorsCaym1: "",
    requiredAssociateProfessorsCaym2: "",
    availableAssociateProfessorsCay: "",
    availableAssociateProfessorsCaym1: "",
    availableAssociateProfessorsCaym2: "",
    requiredAssistantProfessorsCay: "",
    requiredAssistantProfessorsCaym1: "",
    requiredAssistantProfessorsCaym2: "",
    availableAssistantProfessorsCay: "",
    availableAssistantProfessorsCaym1: "",
    availableAssistantProfessorsCaym2: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        requiredProfessorsCay,
        requiredProfessorsCaym1,
        requiredProfessorsCaym2,
        availableProfessorsCay,
        availableProfessorsCaym1,
        availableProfessorsCaym2,
        requiredAssociateProfessorsCay,
        requiredAssociateProfessorsCaym1,
        requiredAssociateProfessorsCaym2,
        availableAssociateProfessorsCay,
        availableAssociateProfessorsCaym1,
        availableAssociateProfessorsCaym2,
        requiredAssistantProfessorsCay,
        requiredAssistantProfessorsCaym1,
        requiredAssistantProfessorsCaym2,
        availableAssistantProfessorsCay,
        availableAssistantProfessorsCaym1,
        availableAssistantProfessorsCaym2,
      } = facultyCadreData;

      const reqBody = {
        facultyCadrePropId:
          metricInfo?.[template]?.[0]?.["fac_cadre_prop_id"] ?? "",
        requiredProfessorsCay,
        requiredProfessorsCaym1,
        requiredProfessorsCaym2,
        availableProfessorsCay,
        availableProfessorsCaym1,
        availableProfessorsCaym2,
        requiredAssociateProfessorsCay,
        requiredAssociateProfessorsCaym1,
        requiredAssociateProfessorsCaym2,
        availableAssociateProfessorsCay,
        availableAssociateProfessorsCaym1,
        availableAssociateProfessorsCaym2,
        requiredAssistantProfessorsCay,
        requiredAssistantProfessorsCaym1,
        requiredAssistantProfessorsCaym2,
        availableAssistantProfessorsCay,
        availableAssistantProfessorsCaym1,
        availableAssistantProfessorsCaym2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );
      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateFacultyCadreData = (event) => {
    setFacultyCadreData({
      ...facultyCadreData,
      [event.target.name]: event.target.value,
    });
  };

  const renderAvgNumbers = () => {
    try {
      const rfProfessor =
        (parseInt(facultyCadreData?.requiredProfessorsCay || 0) +
          parseInt(facultyCadreData?.requiredProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.requiredProfessorsCaym2 || 0)) /
        3;

      const rfProfessorAvailable =
        (parseInt(facultyCadreData?.availableProfessorsCay || 0) +
          parseInt(facultyCadreData?.availableProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.availableProfessorsCaym2 || 0)) /
        3;
      const rfProfessor1 =
        (parseInt(facultyCadreData?.requiredAssociateProfessorsCay || 0) +
          parseInt(facultyCadreData?.requiredAssociateProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.requiredAssociateProfessorsCaym2 || 0)) /
        3;

      const rfProfessorAvailable1 =
        (parseInt(facultyCadreData?.availableAssociateProfessorsCay || 0) +
          parseInt(facultyCadreData?.availableAssociateProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.availableAssociateProfessorsCaym2 || 0)) /
        3;

      const rfProfessor2 =
        (parseInt(facultyCadreData?.requiredAssistantProfessorsCay || 0) +
          parseInt(facultyCadreData?.requiredAssistantProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.requiredAssistantProfessorsCaym2 || 0)) /
        3;

      const rfProfessorAvailable2 =
        (parseInt(facultyCadreData?.availableAssistantProfessorsCay || 0) +
          parseInt(facultyCadreData?.availableAssistantProfessorsCaym1 || 0) +
          parseInt(facultyCadreData?.availableAssistantProfessorsCaym2 || 0)) /
        3;

      return (
        <>
          <td className={styles["input-col"]}>{rfProfessor.toFixed(2)}</td>
          <td className={styles["input-col"]}>
            {rfProfessorAvailable.toFixed(2)}
          </td>
          <td className={styles["input-col"]}>{rfProfessor1.toFixed(2)}</td>
          <td className={styles["input-col"]}>
            {rfProfessorAvailable1.toFixed(2)}
          </td>
          <td className={styles["input-col"]}>{rfProfessor2.toFixed(2)}</td>
          <td className={styles["input-col"]}>
            {rfProfessorAvailable2.toFixed(2)}
          </td>
        </>
      );
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    const data = {
      requiredProfessorsCay:
        metricInfo?.[template]?.[0]?.["required_professors_cay"] ?? "",

      requiredProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["required_professors_caym1"] ?? "",

      requiredProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["required_professors_caym2"] ?? "",

      availableProfessorsCay:
        metricInfo?.[template]?.[0]?.["available_professors_cay"] ?? "",

      availableProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["available_professors_caym1"] ?? "",

      availableProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["available_professors_caym2"] ?? "",

      requiredAssistantProfessorsCay:
        metricInfo?.[template]?.[0]?.["required_assistant_professors_cay"] ??
        "",

      requiredAssistantProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["required_assistant_professors_caym1"] ??
        "",

      requiredAssistantProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["required_assistant_professors_caym2"] ??
        "",

      availableAssistantProfessorsCay:
        metricInfo?.[template]?.[0]?.["available_assistant_professors_cay"] ??
        "",

      availableAssistantProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["available_assistant_professors_caym1"] ??
        "",

      availableAssistantProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["available_assistant_professors_caym2"] ??
        "",

      requiredAssociateProfessorsCay:
        metricInfo?.[template]?.[0]?.["required_associate_professors_cay"] ??
        "",

      requiredAssociateProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["required_associate_professors_caym1"] ??
        "",

      requiredAssociateProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["required_associate_professors_caym2"] ??
        "",

      availableAssociateProfessorsCay:
        metricInfo?.[template]?.[0]?.["available_associate_professors_cay"] ??
        "",

      availableAssociateProfessorsCaym1:
        metricInfo?.[template]?.[0]?.["available_associate_professors_caym1"] ??
        "",

      availableAssociateProfessorsCaym2:
        metricInfo?.[template]?.[0]?.["available_associate_professors_caym2"] ??
        "",
    };

    setFacultyCadreData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["faculty-cadre-table"]}>
        <thead>
          <tr>
            <th rowSpan={2}>Year</th>
            <th colSpan={2}>Professors</th>
            <th colSpan={2}>Associate Professors</th>
            <th colSpan={2}>Assistant Professors</th>
          </tr>

          <tr>
            <td>Required Faculty(RF1)</td>
            <td>Available Faculty(AF1)</td>
            <td>Required Faculty(RF1)</td>
            <td>Available Faculty(AF1)</td>
            <td>Required Faculty(RF1)</td>
            <td>Available Faculty(AF1)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CAY</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredProfessorsCay}
                onChange={updateFacultyCadreData}
                name="requiredProfessorsCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableProfessorsCay}
                onChange={updateFacultyCadreData}
                name="availableProfessorsCay"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssociateProfessorsCay}
                onChange={updateFacultyCadreData}
                name="requiredAssociateProfessorsCay"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssociateProfessorsCay}
                onChange={updateFacultyCadreData}
                name="availableAssociateProfessorsCay"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssistantProfessorsCay}
                onChange={updateFacultyCadreData}
                name="requiredAssistantProfessorsCay"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssistantProfessorsCay}
                onChange={updateFacultyCadreData}
                name="availableAssistantProfessorsCay"
                required
              />
            </td>
          </tr>

          <tr>
            <td>CAYm1</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="requiredProfessorsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="availableProfessorsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssociateProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="requiredAssociateProfessorsCaym1"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssociateProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="availableAssociateProfessorsCaym1"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssistantProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="requiredAssistantProfessorsCaym1"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssistantProfessorsCaym1}
                onChange={updateFacultyCadreData}
                name="availableAssistantProfessorsCaym1"
                required
              />
            </td>
          </tr>

          <tr>
            <td>CAYm2</td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="requiredProfessorsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="availableProfessorsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssociateProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="requiredAssociateProfessorsCaym2"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssociateProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="availableAssociateProfessorsCaym2"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.requiredAssistantProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="requiredAssistantProfessorsCaym2"
                required
              />
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyCadreData.availableAssistantProfessorsCaym2}
                onChange={updateFacultyCadreData}
                name="availableAssistantProfessorsCaym2"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Average Numbers</td>
            {renderAvgNumbers()}
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default FacultyCadreProportion;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const BudgetExpenditureSummaryProgramLvl = (props) => {
  const { template, metricInfo, metricsId, uploadedStatus } = props;

  const [budgetExpenditure, setBudgetExpenditure] = useState({
    totalBudgetCfyM1: "",
    totalBudgetCfyM2: "",
    totalBudgetCfyM3: "",
    totalStudentsProgramLvlCfyM1: "",
    totalStudentsProgramLvlCfyM2: "",
    totalStudentsProgramLvlCfyM3: "",
    demandedBudgetCfyM1: "",
    demandedBudgetCfyM2: "",
    demandedBudgetCfyM3: "",
    allocatedBudgetCfyM1: "",
    allocatedBudgetCfyM2: "",
    allocatedBudgetCfyM3: "",
    actualExpenditureProgramLvlCfyM1: "",
    actualExpenditureProgramLvlCfyM2: "",
    actualExpenditureProgramLvlCfyM3: "",
    pctSpentCfyM1: "",
    pctSpentCfyM2: "",
    pctSpentCfyM3: "",
    studentExpenditureCfyM1: "",
    studentExpenditureCfyM2: "",
    studentExpenditureCfyM3: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const reqBody = {
        budgetExpenditureSummaryProgramLvlId:
          metricInfo?.[template]?.[0]?.[
            "budget_expenditure_summary_program_lvl_id"
          ] ?? "",
        ...budgetExpenditure,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateBudgetExpenditureData = (event) => {
    setBudgetExpenditure({
      ...budgetExpenditure,
      [event.target.name]: event.target.value,
    });
  };

  const renderStructure = (year) => (
    <tr>
      <td>
        <input
          name={`demandedBudget${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`demandedBudget${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          name={`allocatedBudget${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`allocatedBudget${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          name={`actualExpenditureProgramLvl${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`actualExpenditureProgramLvl${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          name={`pctSpent${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`pctSpent${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>

      <td>
        <input
          name={`studentExpenditure${year}`}
          onChange={updateBudgetExpenditureData}
          value={budgetExpenditure[`studentExpenditure${year}`]}
          type="number"
          min={0}
          step={0.01}
          required
        />
      </td>
    </tr>
  );

  useEffect(() => {
    const data = {
      totalBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["total_budget_cfym1"] ?? "",
      totalBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["total_budget_cfym2"] ?? "",
      totalBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["total_budget_cfym3"] ?? "",

      totalStudentsProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["total_students_cfym1"] ?? "",
      totalStudentsProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["total_students_cfym2"] ?? "",
      totalStudentsProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["total_students_cfym3"] ?? "",

      demandedBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["demanded_budget_cfym1"] ?? "",
      demandedBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["demanded_budget_cfym2"] ?? "",
      demandedBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["demanded_budget_cfym3"] ?? "",

      allocatedBudgetCfyM1:
        metricInfo?.[template]?.[0]?.["allocated_budget_cfym1"] ?? "",
      allocatedBudgetCfyM2:
        metricInfo?.[template]?.[0]?.["allocated_budget_cfym2"] ?? "",
      allocatedBudgetCfyM3:
        metricInfo?.[template]?.[0]?.["allocated_budget_cfym3"] ?? "",

      actualExpenditureProgramLvlCfyM1:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym1"] ?? "",
      actualExpenditureProgramLvlCfyM2:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym2"] ?? "",
      actualExpenditureProgramLvlCfyM3:
        metricInfo?.[template]?.[0]?.["actual_expenditure_cfym3"] ?? "",

      pctSpentCfyM1: metricInfo?.[template]?.[0]?.["pct_spent_cfym1"] ?? "",
      pctSpentCfyM2: metricInfo?.[template]?.[0]?.["pct_spent_cfym2"] ?? "",
      pctSpentCfyM3: metricInfo?.[template]?.[0]?.["pct_spent_cfym3"] ?? "",

      studentExpenditureCfyM1:
        metricInfo?.[template]?.[0]?.["student_expenditure_cfym1"] ?? "",
      studentExpenditureCfyM2:
        metricInfo?.[template]?.[0]?.["student_expenditure_cfym2"] ?? "",
      studentExpenditureCfyM3:
        metricInfo?.[template]?.[0]?.["student_expenditure_cfym3"] ?? "",
    };

    setBudgetExpenditure(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col pt-5">
      <table className={styles["budget-expenditure-summary-table"]}>
        <thead>
          <tr>
            <th colSpan={2}>
              Total Budget in the CFYm1:{" "}
              <input
                name={`totalBudgetCfyM1`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalBudgetCfyM1`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
            <th colSpan={2}>Actual expenditure in the CFYm1</th>
            <th>
              Total Students in the CFYm1:{" "}
              <input
                name={`totalStudentsProgramLvlCfyM1`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalStudentsProgramLvlCfyM1`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
          </tr>

          <tr>
            <td>Demanded</td>
            <td>Actual Allocated.</td>
            <td>Actual Expenditure</td>
            <td>% Spent</td>
            <td>Expenditure per student</td>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM1")}</tbody>
      </table>

      <table className={styles["budget-expenditure-summary-table"]}>
        <thead>
          <tr>
            <th colSpan={2}>
              Total Budget in the CFYm2:{" "}
              <input
                name={`totalBudgetCfyM2`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalBudgetCfyM2`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
            <th colSpan={2}>Actual expenditure in the CFYm2</th>
            <th>
              Total Students in the CFYm2:{" "}
              <input
                name={`totalStudentsProgramLvlCfyM2`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalStudentsProgramLvlCfyM2`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
          </tr>

          <tr>
            <td>Demanded</td>
            <td>Actual Allocated.</td>
            <td>Actual Expenditure</td>
            <td>% Spent</td>
            <td>Expenditure per student</td>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM2")}</tbody>
      </table>

      <table className={styles["budget-expenditure-summary-table"]}>
        <thead>
          <tr>
            <th colSpan={2}>
              Total Budget in the CFYm3:{" "}
              <input
                name={`totalBudgetCfyM3`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalBudgetCfyM3`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
            <th colSpan={2}>Actual expenditure in the CFYm3</th>
            <th>
              Total Students in the CFYm3:{" "}
              <input
                name={`totalStudentsProgramLvlCfyM3`}
                onChange={updateBudgetExpenditureData}
                value={budgetExpenditure[`totalStudentsProgramLvlCfyM3`]}
                type="number"
                min={0}
                step={0.01}
                required
              />
            </th>
          </tr>

          <tr>
            <td>Demanded</td>
            <td>Actual Allocated.</td>
            <td>Actual Expenditure</td>
            <td>% Spent</td>
            <td>Expenditure per student</td>
          </tr>
        </thead>

        <tbody>{renderStructure("CfyM3")}</tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default BudgetExpenditureSummaryProgramLvl;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const StudentEnrolmentRatioTable = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [enrolData, setEnrolData] = useState({
    sanctionedIntakeCay: "",
    sanctionedIntakeCayM1: "",
    sanctionedIntakeCayM2: "",
    remainingStudentsCay: "",
    remainingStudentsCayM1: "",
    remainingStudentsCayM2: "",
    superNumeraryStudentsCay: "",
    superNumeraryStudentsCayM1: "",
    superNumeraryStudentsCayM2: "",
  });

  const updateEnrolData = (event) => {
    setEnrolData({ ...enrolData, [event.target.name]: event.target.value });
  };

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        sanctionedIntakeCay,
        sanctionedIntakeCayM1,
        sanctionedIntakeCayM2,
        remainingStudentsCay,
        remainingStudentsCayM1,
        remainingStudentsCayM2,
        superNumeraryStudentsCay,
        superNumeraryStudentsCayM1,
        superNumeraryStudentsCayM2,
      } = enrolData;

      const reqBody = {
        studentEnrolmentRatioId:
          metricInfo?.[template]?.[0]?.["student_enrolment_ratio_id"] ?? "",
        sanctionedIntakeCay: sanctionedIntakeCay,
        sanctionedIntakeCayM1: sanctionedIntakeCayM1,
        sanctionedIntakeCayM2: sanctionedIntakeCayM2,
        remainingStudentsCay: remainingStudentsCay,
        remainingStudentsCayM1: remainingStudentsCayM1,
        remainingStudentsCayM2: remainingStudentsCayM2,
        superNumeraryStudentsCay: superNumeraryStudentsCay,
        superNumeraryStudentsCayM1: superNumeraryStudentsCayM1,
        superNumeraryStudentsCayM2: superNumeraryStudentsCayM2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const renderRatio = () => {
    let result = [];

    try {
      const err1 =
        (parseInt(enrolData?.remainingStudentsCay) +
          parseInt(enrolData?.superNumeraryStudentsCay)) /
        parseInt(enrolData?.sanctionedIntakeCay);

      const err2 =
        (parseInt(enrolData?.remainingStudentsCayM1) +
          parseInt(enrolData?.superNumeraryStudentsCayM1)) /
        parseInt(enrolData?.sanctionedIntakeCayM1);

      const err3 =
        (parseInt(enrolData?.remainingStudentsCayM2) +
          parseInt(enrolData?.superNumeraryStudentsCayM2)) /
        parseInt(enrolData?.sanctionedIntakeCayM2);

      result.push(
        <>
          <td className={styles["input-col"]}>{err1.toFixed(2)}</td>
          <td className={styles["input-col"]}>{err2.toFixed(2)}</td>
          <td className={styles["input-col"]}>{err3.toFixed(2)}</td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderAvg = () => {
    let result = "";
    try {
      const err1 =
        (parseInt(enrolData?.remainingStudentsCay) +
          parseInt(enrolData?.superNumeraryStudentsCay)) /
        parseInt(enrolData?.sanctionedIntakeCay);

      const err2 =
        (parseInt(enrolData?.remainingStudentsCayM1) +
          parseInt(enrolData?.superNumeraryStudentsCayM1)) /
        parseInt(enrolData?.sanctionedIntakeCayM1);

      const err3 =
        (parseInt(enrolData?.remainingStudentsCayM2) +
          parseInt(enrolData?.superNumeraryStudentsCayM2)) /
        parseInt(enrolData?.sanctionedIntakeCayM2);

      result = ((err1 + err2 + err3) / 3).toFixed(2);
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    const data = {
      sanctionedIntakeCay:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_cay"] ?? "",
      sanctionedIntakeCayM1:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_caym1"] ?? "",
      sanctionedIntakeCayM2:
        metricInfo?.[template]?.[0]?.["sanctioned_intake_caym2"] ?? "",
      remainingStudentsCay:
        metricInfo?.[template]?.[0]?.["remaining_students_cay"] ?? "",
      remainingStudentsCayM1:
        metricInfo?.[template]?.[0]?.["remaining_students_caym1"] ?? "",
      remainingStudentsCayM2:
        metricInfo?.[template]?.[0]?.["remaining_students_caym2"] ?? "",
      superNumeraryStudentsCay:
        metricInfo?.[template]?.[0]?.["supernumerary_students_cay"] ?? "",
      superNumeraryStudentsCayM1:
        metricInfo?.[template]?.[0]?.["supernumerary_students_caym1"] ?? "",
      superNumeraryStudentsCayM2:
        metricInfo?.[template]?.[0]?.["supernumerary_students_caym2"] ?? "",
    };

    setEnrolData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["student-enroll-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAY</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              N=&nbsp; Sanctioned intake of the program in the 1st year (as per
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AICTE/Competent
              authority)
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.sanctionedIntakeCay}
                onChange={updateEnrolData}
                name="sanctionedIntakeCay"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.sanctionedIntakeCayM1}
                onChange={updateEnrolData}
                name="sanctionedIntakeCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.sanctionedIntakeCayM2}
                onChange={updateEnrolData}
                name="sanctionedIntakeCayM2"
              />
            </td>
          </tr>

          <tr>
            <td>
              N1= Total no. of students admitted in the 1st year minus the{" "}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; no.
              of students, who migrated to other programs/ <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              institutions plus no. of students, who migrated to this <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              program
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.remainingStudentsCay}
                onChange={updateEnrolData}
                name="remainingStudentsCay"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.remainingStudentsCayM1}
                onChange={updateEnrolData}
                name="remainingStudentsCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.remainingStudentsCayM2}
                onChange={updateEnrolData}
                name="remainingStudentsCayM2"
              />
            </td>
          </tr>

          <tr>
            <td>
              N4= Total no. of students admitted in the 1st year via all
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              supernumerary quotas
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.superNumeraryStudentsCay}
                onChange={updateEnrolData}
                name="superNumeraryStudentsCay"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.superNumeraryStudentsCayM1}
                onChange={updateEnrolData}
                name="superNumeraryStudentsCayM1"
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                required
                type="number"
                min={0}
                value={enrolData?.superNumeraryStudentsCayM2}
                onChange={updateEnrolData}
                name="superNumeraryStudentsCayM2"
              />
            </td>
          </tr>

          <tr>
            <td>Enrolment Ratio (ER)=(N1+N4)/N</td>
            {renderRatio()}
          </tr>

          <tr>
            <td>Average ER = (ER_1+ ER_2+ ER_3)/3</td>
            <td className={styles["input-col"]} colSpan={3}>
              {renderAvg()}
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default StudentEnrolmentRatioTable;

import styles from "./index.module.css";
import { useEffect, useState } from "react";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";

const FacultyRetention = (props) => {
  const { uploadedStatus, metricsId, metricInfo, template } = props;

  const [facultyRetentionData, setFacultyRetentionData] = useState({
    requiredFacultyCaym1: "",
    requiredFacultyCaym2: "",
    requiredFacultyCaym3: "",
    availableFacultyCaym1: "",
    availableFacultyCaym2: "",
    availableFacultyCaym3: "",
    expLt1YearCaym3: "",
    expLt1YearCaym1: "",
    expLt1YearCaym2: "",
    expLt2YearsCaym3: "",
    expLt2YearsCaym1: "",
    expLt2YearsCaym2: "",
    expLt3YearsCaym3: "",
    expLt3YearsCaym1: "",
    expLt3YearsCaym2: "",
    expLt4YearsCaym3: "",
    expLt4YearsCaym1: "",
    expLt4YearsCaym2: "",
    expGt4YearsCaym3: "",
    expGt4YearsCaym1: "",
    expGt4YearsCaym2: "",
  });

  const submitted = async (event) => {
    event.preventDefault();

    const token = Cookies.get("jwt_token");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const {
        requiredFacultyCaym1,
        requiredFacultyCaym2,
        requiredFacultyCaym3,
        availableFacultyCaym1,
        availableFacultyCaym2,
        availableFacultyCaym3,
        expLt1YearCaym3,
        expLt1YearCaym1,
        expLt1YearCaym2,
        expLt2YearsCaym3,
        expLt2YearsCaym1,
        expLt2YearsCaym2,
        expLt3YearsCaym3,
        expLt3YearsCaym1,
        expLt3YearsCaym2,
        expLt4YearsCaym3,
        expLt4YearsCaym1,
        expLt4YearsCaym2,
        expGt4YearsCaym3,
        expGt4YearsCaym1,
        expGt4YearsCaym2,
      } = facultyRetentionData;

      const reqBody = {
        facultyRetentionId:
          metricInfo?.[template]?.[0]?.["fac_retention_id"] ?? "",
        requiredFacultyCaym3,
        requiredFacultyCaym1,
        requiredFacultyCaym2,
        availableFacultyCaym1,
        availableFacultyCaym2,
        availableFacultyCaym3,
        expLt1YearCaym3,
        expLt1YearCaym1,
        expLt1YearCaym2,
        expLt2YearsCaym3,
        expLt2YearsCaym1,
        expLt2YearsCaym2,
        expLt3YearsCaym3,
        expLt3YearsCaym1,
        expLt3YearsCaym2,
        expLt4YearsCaym3,
        expLt4YearsCaym1,
        expLt4YearsCaym2,
        expGt4YearsCaym3,
        expGt4YearsCaym1,
        expGt4YearsCaym2,
      };

      const options = {
        method: metricInfo[template].length === 0 ? "POST" : "PUT",
        headers,
        body: JSON.stringify({ data: reqBody }),
      };

      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      alert("Failed to upload data");
      console.log(err);
    }
  };

  const updateFacultyRetentionData = (event) => {
    setFacultyRetentionData({
      ...facultyRetentionData,
      [event.target.name]: event.target.value,
    });
  };

  const renderFR = () => {
    let result = [];

    try {
      const fr1 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym1) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym1) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym1) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym1) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym1) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym1)) *
        2.5;

      const fr2 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym2) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym2) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym2) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym2) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym2) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym2)) *
        2.5;

      const fr3 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym3) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym3) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym3) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym3) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym3) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym3)) *
        2.5;

      result.push(
        <>
          <td className={styles["input-col"]}>
            {Math.min(fr1, 10).toFixed(2)}
          </td>
          <td className={styles["input-col"]}>
            {Math.min(fr2, 10).toFixed(2)}
          </td>
          <td className={styles["input-col"]}>
            {Math.min(fr3, 10).toFixed(2)}
          </td>
        </>
      );
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  const renderAvgFR = () => {
    let result = "";

    try {
      const fr1 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym1) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym1) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym1) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym1) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym1) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym1)) *
        2.5;

      const fr2 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym2) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym2) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym2) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym2) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym2) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym2)) *
        2.5;

      const fr3 =
        ((parseInt(facultyRetentionData?.expLt1YearCaym3) * 0 +
          parseInt(facultyRetentionData?.expLt2YearsCaym3) * 1 +
          parseInt(facultyRetentionData?.expLt3YearsCaym3) * 2 +
          parseInt(facultyRetentionData?.expLt4YearsCaym3) * 3 +
          parseInt(facultyRetentionData?.expGt4YearsCaym3) * 4) /
          parseInt(facultyRetentionData?.requiredFacultyCaym3)) *
        2.5;

      const avgFR = Math.min((fr1 + fr2 + fr3) / 3, 10).toFixed(2);
      result = avgFR;
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  useEffect(() => {
    const data = {
      requiredFacultyCaym1:
        metricInfo?.[template]?.[0]?.["required_faculty_caym1"] ?? "",
      requiredFacultyCaym2:
        metricInfo?.[template]?.[0]?.["required_faculty_caym2"] ?? "",
      requiredFacultyCaym3:
        metricInfo?.[template]?.[0]?.["required_faculty_caym3"] ?? "",
      availableFacultyCaym1:
        metricInfo?.[template]?.[0]?.["available_faculty_caym1"] ?? "",
      availableFacultyCaym2:
        metricInfo?.[template]?.[0]?.["available_faculty_caym2"] ?? "",
      availableFacultyCaym3:
        metricInfo?.[template]?.[0]?.["available_faculty_caym3"] ?? "",
      expLt1YearCaym1:
        metricInfo?.[template]?.[0]?.["exp_lt_1_year_caym1"] ?? "",
      expLt1YearCaym2:
        metricInfo?.[template]?.[0]?.["exp_lt_1_year_caym2"] ?? "",
      expLt1YearCaym3:
        metricInfo?.[template]?.[0]?.["exp_lt_1_year_caym3"] ?? "",
      expLt2YearsCaym1:
        metricInfo?.[template]?.[0]?.["exp_lt_2_years_caym1"] ?? "",
      expLt2YearsCaym2:
        metricInfo?.[template]?.[0]?.["exp_lt_2_years_caym2"] ?? "",
      expLt2YearsCaym3:
        metricInfo?.[template]?.[0]?.["exp_lt_2_years_caym3"] ?? "",
      expLt3YearsCaym1:
        metricInfo?.[template]?.[0]?.["exp_lt_3_years_caym1"] ?? "",
      expLt3YearsCaym2:
        metricInfo?.[template]?.[0]?.["exp_lt_3_years_caym2"] ?? "",
      expLt3YearsCaym3:
        metricInfo?.[template]?.[0]?.["exp_lt_3_years_caym3"] ?? "",
      expLt4YearsCaym1:
        metricInfo?.[template]?.[0]?.["exp_lt_4_years_caym1"] ?? "",
      expLt4YearsCaym2:
        metricInfo?.[template]?.[0]?.["exp_lt_4_years_caym2"] ?? "",
      expLt4YearsCaym3:
        metricInfo?.[template]?.[0]?.["exp_lt_4_years_caym3"] ?? "",
      expGt4YearsCaym1:
        metricInfo?.[template]?.[0]?.["exp_gt_4_years_caym1"] ?? "",
      expGt4YearsCaym2:
        metricInfo?.[template]?.[0]?.["exp_gt_4_years_caym2"] ?? "",
      expGt4YearsCaym3:
        metricInfo?.[template]?.[0]?.["exp_gt_4_years_caym3"] ?? "",
    };

    setFacultyRetentionData(data);
  }, []);

  return (
    <form onSubmit={submitted} className="p-2 pr-0 flex flex-col">
      <table className={styles["faculty-retention-table"]}>
        <thead>
          <tr>
            <th>Item</th>
            <th>CAYm1</th>
            <th>CAYm2</th>
            <th>CAYm3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              RF= No. of required faculty in the Department including <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; allied
              Departments to adhere to the 20:1 Student- <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Faculty ratio,
              with calculations based on both
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; student
              numbers and faculty requirements as per <br />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; section 5.1 of
              SAR; (RF=S/20).
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.requiredFacultyCaym1}
                onChange={updateFacultyRetentionData}
                name="requiredFacultyCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.requiredFacultyCaym2}
                onChange={updateFacultyRetentionData}
                name="requiredFacultyCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.requiredFacultyCaym3}
                onChange={updateFacultyRetentionData}
                name="requiredFacultyCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              AF= The no. of available faculty members in the <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Department
              including allied Departments
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.availableFacultyCaym1}
                onChange={updateFacultyRetentionData}
                name="availableFacultyCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.availableFacultyCaym2}
                onChange={updateFacultyRetentionData}
                name="availableFacultyCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.availableFacultyCaym3}
                onChange={updateFacultyRetentionData}
                name="availableFacultyCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              A= The no. of faculty members at the current institute <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with less than 1
              year of experience (A in AF)
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt1YearCaym1}
                onChange={updateFacultyRetentionData}
                name="expLt1YearCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt1YearCaym2}
                onChange={updateFacultyRetentionData}
                name="expLt1YearCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt1YearCaym3}
                onChange={updateFacultyRetentionData}
                name="expLt1YearCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              B= The no. of faculty members at the current institute <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with more than 1
              years and less than 2 years of <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; experience (B in
              AF)
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt2YearsCaym1}
                onChange={updateFacultyRetentionData}
                name="expLt2YearsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt2YearsCaym2}
                onChange={updateFacultyRetentionData}
                name="expLt2YearsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt2YearsCaym3}
                onChange={updateFacultyRetentionData}
                name="expLt2YearsCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              C= The no. of faculty members at the current institute <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with more than 2
              years and less than 3 years of <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; experience (C in
              AF)
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt3YearsCaym1}
                onChange={updateFacultyRetentionData}
                name="expLt3YearsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt3YearsCaym2}
                onChange={updateFacultyRetentionData}
                name="expLt3YearsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt3YearsCaym3}
                onChange={updateFacultyRetentionData}
                name="expLt3YearsCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              D= The no. of faculty members at the current institute <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with more than 3
              years and less than 4 years of <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; experience (D in
              AF)
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt4YearsCaym1}
                onChange={updateFacultyRetentionData}
                name="expLt4YearsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt4YearsCaym2}
                onChange={updateFacultyRetentionData}
                name="expLt4YearsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expLt4YearsCaym3}
                onChange={updateFacultyRetentionData}
                name="expLt4YearsCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              E= The no. of faculty members at the current institute <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with more than 4
              years of experience (E in AF)
            </td>

            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expGt4YearsCaym1}
                onChange={updateFacultyRetentionData}
                name="expGt4YearsCaym1"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expGt4YearsCaym2}
                onChange={updateFacultyRetentionData}
                name="expGt4YearsCaym2"
                required
              />
            </td>
            <td className={styles["input-col"]}>
              <input
                type="number"
                min={0}
                value={facultyRetentionData.expGt4YearsCaym3}
                onChange={updateFacultyRetentionData}
                name="expGt4YearsCaym3"
                required
              />
            </td>
          </tr>

          <tr>
            <td>
              FR=(((A*0) +(B*1)+(C*2)+(D*3)+(E*4))/RF) *2.50 <br />
              (points limited to 10)
            </td>
            {renderFR()}
          </tr>

          <tr>
            <td>
              Average FR= ((FR_1+ FR_2+ FR_3)/3) <br />
              (marks limited to 10)
            </td>
            <td className={styles["input-col"]} colSpan={3}>
              {renderAvgFR()}
            </td>
          </tr>
        </tbody>
      </table>

      <button className={styles["save-btn"]} type="submit">
        Save
      </button>
    </form>
  );
};

export default FacultyRetention;
